// @ts-nocheck
import { useState, createContext } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useEffectOnCookieImport } from '../cookie'
import {
  getMobileAppInfo,
  isAuthenticated as checkAuth,
  authenticatedUserFragment,
} from 'utils/index'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...userFragment
    }
  }
  ${authenticatedUserFragment}
`

const defaultState: SessionInfo = {
  mobileApp: false,
  mobileWeb: false,
  version: 0,
  platform: 'web',
  isAuthenticated: false,
  user: null,
  setUser: (_user: any) => {},
  logIn: (_user: any) => {},
  logOut: () => {},
  mobileAppFromV: (_minVersion: number) => true,
}

export const SessionContext = createContext<Partial<SessionInfo>>(defaultState)

export const SessionProvider = (props: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuth())
  const [user, setUser] = useState(null)
  const info = getMobileAppInfo()
  const mobileAppFromV = (minVersion: number): boolean => {
    if (info.mobileApp) return info.version >= (minVersion || 1)
    return false
  }

  useEffectOnCookieImport(() => {
    // re-check auth status after cookies have been imported
    setIsAuthenticated(checkAuth())
  })

  // fetch the current user: include in context state if authenticated, discard if not
  const { data } = useQuery(CURRENT_USER)
  if (data && !user) setUser(data.currentUser)

  return (
    <SessionContext.Provider
      value={{
        ...info,
        ...{ isAuthenticated, user },
        logIn: user => {
          setUser(user)
          setIsAuthenticated(true)
        },
        logOut: () => {
          setIsAuthenticated(false)
          setUser(null)
        },
        setUser,
        mobileAppFromV,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  )
}

interface Props {
  children: any
}
