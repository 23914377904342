import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { getQuery } from 'hooks/getQuery'
import config from 'config'

import brandmark from 'static/img/logo/backer-brandmark.svg'
import arrowLeftIcon from 'static/img/arrow/arrow-left.svg'
import closeIcon from 'static/img/close-icon.svg'
import questionMark from 'static/img/help-icon.svg'
import { SessionContext } from 'context'

import styles from './HeaderNew.module.scss'
import { isMobileAppInAppBrowser } from 'utils/helpers'
import { buildPath } from 'routes'

export const HeaderNew = ({
  referrerFallback
}: {
  referrerFallback?: string
}) => {
  const context = useContext(SessionContext)
  const query = getQuery()
  const referrer = query.get('referrer') || referrerFallback
  const forceGoBack = query.get('forceGoBack')
  const history = useHistory()

  if (context.mobileAppFromV(2) || isMobileAppInAppBrowser()) return <></>

  const brandLink = (
    <Link
      to={buildPath('dashboard')}
      className={styles.brandmarkLink}
    >
      <img src={brandmark} className={styles.brandmark} alt="dashboard" />
    </Link>
  )

  const backArrow = (
    <div className={styles.arrowContainer}>
      <button onClick={() => {
        if (forceGoBack) {
          history.goBack()
        } else if (referrer) {
          history.replace(referrer)
        } else {
          history.goBack()
        }
      }}>
        <img src={arrowLeftIcon} alt="back" />
      </button>
    </div>)

  const closeOrInfo = referrer ? (
    <Link
      to={referrer}
      className={styles.close}
    >
      <img src={closeIcon} alt="close" />
    </Link>
  ) : (
    <a
      className={styles.close}
      href={config.HELP_URL}
      target="_blank"
      rel="noreferrer"
    >
      <img src={questionMark} alt="back" />
    </a>
  )

  return (
    <div className={styles.container}>
      {brandLink}
      {backArrow}
      {closeOrInfo}
    </div >
  )
}
