import { WarnMesssage } from '@collegebacker/backer-ui/ui'
import { NumberFormat } from 'utils/index'
import { isNumber } from 'lodash'

export const InsufficientFundsWarning = ({ bankAccount } : { bankAccount: any }) => {
  const { accountLabel, latestBalance } = bankAccount
  return (
    <WarnMesssage type="warning" text={
      <>
        <span>Insufficient funds in account</span>
        <br />
        <span>{accountLabel} | Available Balance: {NumberFormat.currencyFromCents(latestBalance, true)}</span>
      </>}
    />
  )
}

export const areFundsEnough = ({
  bankAccount,
  amount
}: {
  bankAccount: any
  amount: number
}) => {
  if (!bankAccount) return true
  const { latestBalance } = bankAccount
  if (!isNumber(latestBalance)) return true
  return amount <= latestBalance
}
