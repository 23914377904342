import { Spinner } from '@collegebacker/backer-ui/ui'
import styles from './Loading.module.scss'

export const Loading = ({
  className,
  size = 'large',
  style,
  color
}: Props) => {
  return (
    <div className={styles.container}>
      <Spinner
        className={className}
        size={size}
        style={style}
        color={color}
      />
    </div>
  )

}
interface Props {
  className?: string
  style?: object
  size?: 'small' | 'medium-small' | 'medium' | 'large'
  color?: string
}
