import { useState } from 'react'
import { get } from 'lodash'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/react-hooks'

import { Loading } from 'components'
import { WarnMesssage, Button, Modal, Stack } from '@collegebacker/backer-ui/ui'
import PaymentFailureModal from 'modules/contribution/components/PaymentFailureModal'
import { NumberFormat } from 'utils'
import fragments, { GET_PAYMENT_METHODS } from '../graphqlFragments'
import { usePlaid } from 'utils/plaid'
import { areFundsEnough } from './InSufficientFundsWarning'
import {
  amountValue,
  bankAccountValue,
  creditCardValue,
  frequencyValue
} from '../LocalState'
import { setPaymentMethod } from '../helpers'
import { graphQLErrorMessage } from 'utils/validation'

import bankImage from 'static/img/bank.svg'
import styles from './LinkBankAccount.module.scss'

const LINK_BANK_ACCOUNT = gql`
  mutation LinkBankAccount($input: LinkBankAccountInputType!) {
    linkBankAccount(input: $input) {
      ...BankAccountFragment
    }
  }
  ${fragments.bankAccount}
`

export const LinkBankAccount = ({ handleSuccess, ctaText }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const frequency = frequencyValue()
  const amount = amountValue()

  const [plaidError, setPlaidError] = useState('')

  const [paymentFailureMessage, setPaymentFailureMessage] = useState('')
  const [disclosuresModalVisible, setDisclosuresModalVisible] = useState(false)
  const [linkBankAccount, { loading }] = useMutation(LINK_BANK_ACCOUNT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PAYMENT_METHODS }]
  })

  const onError = (error: any) => {
    setPaymentFailureMessage(
      error.display_message || `${error.error_code}: ${error.error_message}`
    )
    setIsOpen(true)
  }

  const onGraphQLError = async (error: ApolloError) => {
    setPaymentFailureMessage(graphQLErrorMessage(error))
    setIsOpen(true)
  }

  const openPlaid = usePlaid(onGraphQLError)

  const handlePlaidSuccess = async (publicToken: string, metadata: any) => {
    const accountMetadata = get(metadata, 'accounts[0]', {})
    linkBankAccount({
      variables: {
        input: {
          accountId: accountMetadata.id,
          verificationStatus: accountMetadata.verification_status,
          bankName: metadata.institution.name || 'Bank Account',
          publicToken
        }
      }
    })
      .then(({ data }) => {
        const bankAccount = data.linkBankAccount

        setPaymentMethod({
          bankAccount,
          bankAccountValue,
          creditCardValue: creditCardValue
        })
        if (!areFundsEnough({ bankAccount, amount })) {
          setPlaidError(
            `Insufficient funds in account - ${
              bankAccount.accountLabel
            } Available Balance: ${NumberFormat.currencyFromCents(
              bankAccount.latestBalance,
              true
            )}`
          )
          return
        }

        handleSuccess()
      })
      .catch(onGraphQLError)
  }

  const displayPlaidPopup = (onSuccess: any, onError: any) => {
    openPlaid({
      webClientSettings: {
        selectAccount: true,
        onSuccess,
        onExit: (error: any, metadata: any) => {
          console.log({ error, metadata })
          if (error) {
            onError(error)
          }
        }
      }
    })
  }

  if (loading) return <Loading />

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <WarnMesssage className="mb-40" text={plaidError} type="warning" />
        <img src={bankImage} alt="" className={styles.bankImage} />
        <p className={styles.text}>
          Securely link your bank account for free with
          <br /> Plaid, a trusted 3rd party service.
        </p>
        <p className={styles.text}>
          By linking your bank account,{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => setDisclosuresModalVisible(true)}
          >
            you authorize us
          </span>
          <br />
          to debit your account per your instructions.
        </p>
      </div>
      <Button
        label={ctaText || 'Add bank account'}
        mode="outline"
        maxWidth={434}
        minWidth={335}
        style={{ width: '100%' }}
        disabled={loading}
        onClick={() => {
          displayPlaidPopup(handlePlaidSuccess, onError)
        }}
      />
      <PaymentFailureModal
        isOpen={isOpen}
        errorMessage={paymentFailureMessage}
        onClose={() => {
          setPaymentFailureMessage('')
          setIsOpen(false)
        }}
      />
      <Modal
        isOpen={disclosuresModalVisible}
        onCloseClick={() => setDisclosuresModalVisible(false)}
        isBottomSheet
      >
        <Stack spacing={25}>
          {frequency === 'onetime' ? (
            <>
              <p className="typo-app-body-paragraph">
                I authorize Backer to debit the bank account indicated in this
                web form for the noted amount on today's date.
              </p>
              <p className="typo-app-body-paragraph">
                I understand that because this is an electronic transaction,
                these funds may be withdrawn from my account as soon as the
                above noted transaction date.
              </p>
              <p className="typo-app-body-paragraph">
                I acknowledge that the origination of ACH transactions to my
                account must comply with the provisions of U.S. law.
              </p>
              <p className="typo-app-body-paragraph">
                I will not dispute Backer debiting my checking/savings account,
                so long as the transaction corresponds to the terms indicated in
                this web form.
              </p>
            </>
          ) : (
            <>
              <p className="typo-app-body-paragraph">
                I authorize Backer to debit the bank account indicated in this
                web form, for the noted amount on the schedule indicated.
              </p>
              <p className="typo-app-body-paragraph">
                I understand that this authorization will remain in effect until
                the schedule end date, or until I cancel it via the Backer
                website or mobile app interface, whichever comes first, and I
                agree to notify the business in writing of any changes in my
                account information or termination of this authorization at
                least 15 days prior to the next payment date. If the above noted
                payment date falls on a weekend or holiday, I understand that
                the payment may be executed on the next business day.
              </p>
              <p className="typo-app-body-paragraph">
                I understand that because this is an electronic transaction,
                these funds may be withdrawn from my account each period as soon
                as the above noted transaction date.
              </p>
              <p className="typo-app-body-paragraph">
                I acknowledge that the origination of ACH transactions to my
                account must comply with the provisions of U.S. law
              </p>
              <p className="typo-app-body-paragraph">
                I will not dispute Backer my checking/savings account in
                accordance with the indicated schedule so long as the
                transaction corresponds to the terms indicated in this
                agreement.
              </p>
            </>
          )}
        </Stack>
      </Modal>
    </div>
  )
}

interface Props {
  handleSuccess: () => void
  ctaText?: string
}
