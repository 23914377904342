import React from 'react'
import { generateClasses } from 'utils/index'
import './ScreenHeader.scss'

export const ScreenHeader: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  className,
  id,
  smallSubtitle,
  smallTitle
}) => {
  return (
    <div className={generateClasses('screen-header', className)}>
      <h1
        className={`${generateClasses('screen__title', className)} ${
          !subtitle ? 'screen__title--no-subtitle' : ''
        } ${smallTitle ? 'screen__title--small' : ''}`}
        id={id}
      >
        {title}
      </h1>
      {subtitle && (
        <span className={`${generateClasses('screen__subtitle', className)} ${smallSubtitle ? 'small' : ''}`}>
          {subtitle}
        </span>
      )}
    </div>
  )
}

interface Props {
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  className?: string
  id?: string
  smallSubtitle?: boolean
  smallTitle?: boolean
}
