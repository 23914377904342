import { useEffect, useRef, useState } from 'react'
import { get, isEmpty } from 'lodash'
import { Toast } from '@collegebacker/backer-ui/ui'

import { formatConfirmation, isMobileApp } from 'utils'
import ContributionSuccessModal from './ContributionSuccessModal'
import { Confetti } from 'components/Confetti'
import { LocalStorage } from 'LocalStorage'

export const ConfirmationAlert = ({
  confirmation,
  setConfirmation
}: {
  confirmation: any
  setConfirmation: (confirmation: any) => void
}) => {
  const toastRef = useRef<ToastRef>(null)
  const message: any = formatConfirmation(confirmation)
  const [fireConfetti, setFireConfetti] = useState(false)
  const confirmationType = get(confirmation, 'type')
  const customOrCancellationAlert =
    confirmationType === 'custom' || confirmationType === 'cancellation'
  const customConfirmationParams = get(confirmation, 'params')

  const defaultParams = {
    type: 'warning',
    closeOnClick: true,
    timeout: 5000,
    onClose: () => LocalStorage.removeItem('confirmation')
  }

  const params = {
    ...defaultParams,
    ...customConfirmationParams
  } as ToastParamsProps

  useEffect(() => {
    if (!isEmpty(message) && customOrCancellationAlert) {
      toastRef?.current?.showToast(message.alertMessage, params)
    }
  }, [isEmpty(message), customOrCancellationAlert])

  if (isMobileApp()) return null

  return (
    <>
      {!isEmpty(confirmation) &&
        (!customOrCancellationAlert ? (
          <ContributionSuccessModal
            showConfirmationModal={true}
            contribution={confirmation}
            setShowConfirmationModal={setConfirmation}
            onClose={() => {
              LocalStorage.removeItem('confirmation')
              setFireConfetti(true)
            }}
          />
        ) : (
          <Toast ref={toastRef} />
        ))}
      <Confetti fireConfetti={fireConfetti} />
    </>
  )
}
