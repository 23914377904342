import { MoneyValueObject } from 'modules/global/value-objects/money.value-object'
const AGE_AT_COLLEGE = 18
const CAPITAL_GAINS_TAX: number = 0.15
// flat interest for CB and Stock Market FV
const INTEREST_RATE: number = 0.08
const SAVINGS_ACCOUNT_INTEREST: number = 0.005

// variable interest depending on age
export const PLAN_INTEREST_RATES: number[] = [
  0.0939, // 0
  0.0939, // 1
  0.0939, // 2
  0.0939, // 3
  0.0939, // 4
  0.086, // 5
  0.086, // 6
  0.086, // 7
  0.086, // 8
  0.0777, // 9
  0.0777, // 10
  0.0683, // 11
  0.0683, // 12
  0.06, // 13
  0.06, // 14
  0.0504, // 15
  0.0444, // 16
  0.038, // 17
  0.031, // 18
]

/**
 * ====================================================
 * HELPERS
 * ====================================================
 */

export function yearsUntilCollegeFromAge(age: number): number {
  return Math.max(18, age) - age
}

export function monthsUntilCollegeFromAge(age: number): number {
  return 12 * (Math.max(18, age) - age)
}

export function fromYearsLeftToAge(yearsLeft: number): number {
  return Math.max(0, yearsUntilCollegeFromAge(0) - yearsLeft)
}

export function interestFromYear(yearsLeft: number): number {
  const age = fromYearsLeftToAge(yearsLeft)
  return PLAN_INTEREST_RATES[age]
}

export function removeTaxesFromForecast(
  totalContribution: number,
  fvWithInterest: number,
): number {
  const totalInterest = fvWithInterest - totalContribution
  const taxAmount = totalInterest * CAPITAL_GAINS_TAX
  return fvWithInterest - taxAmount
}

export function calculateExternalContribution(
  contributorsCount: number,
  externalContribution: number,
): number {
  return contributorsCount * externalContribution
}

/**
 * ====================================================
 * FUTURE VALUE FORECASTS
 * ====================================================
 */

export class Forecast {
  static calculateContributionGrowth({
    frequency,
    yearsUntilCollege,
    currentBalance,
    contributionAmount,
    collegeEntryDate,
    contributionStartDate,
    interest,
    totalNumberOfPaymentsOverride
  } : {
    frequency: ContributionFrequency | string,
    yearsUntilCollege: number,
    currentBalance: number,
    contributionAmount: number,
    collegeEntryDate?: Date,
    contributionStartDate?: Date,
    interest?: number,
    totalNumberOfPaymentsOverride?: number | undefined
  }): MoneyValueObject {
    if (yearsUntilCollege <= 0) return new MoneyValueObject({ value: 0 })

    let futureValue
    const rate = interest || INTEREST_RATE
    const numberOfYears =
      contributionStartDate && collegeEntryDate
        ? this.computeYearsUntilCollege(contributionStartDate, collegeEntryDate)
        : yearsUntilCollege
    if (frequency === 'onetime') {
      futureValue =
        (contributionAmount + currentBalance) *
        Math.pow(1 + rate, numberOfYears)
    } else {
      const freq = this.computePaymentFrequency(frequency)
      const ratePerPeriod = rate / freq
      const totalNumberOfPayments = totalNumberOfPaymentsOverride || yearsUntilCollege * freq

      futureValue = this.calculateFutureValue(
        totalNumberOfPayments,
        contributionAmount,
        currentBalance,
        ratePerPeriod,
      ).value
    }
    return new MoneyValueObject({ value: futureValue })
  }

  /*
  Calculate FV.
  Exact same Excel FV function
  "rate" is the interest rate per period.
  "numberOfPayments" is the total number of payment periods in an annuity.
  "payment" is the payment made each period; it cannot change over the life of the annuity.
  "presentValue" is the present value, or the lump-sum amount that a series of future payments is worth right now.
  */
  static calculateFutureValue(
    numberOfPayments: number,
    payment: number,
    presentValue: number = 0,
    rate?: number,
  ): MoneyValueObject {
    const pow = Math.pow(1 + (rate || 0), numberOfPayments)
    let futureValue
    if (rate) {
      const fvPV = presentValue * pow
      const fvPMT = (payment * (pow - 1)) / rate
      futureValue = parseInt(String(fvPV + fvPMT), 0)
    } else {
      futureValue = -1 * (-presentValue - payment * numberOfPayments)
    }
    return new MoneyValueObject({ value: futureValue })
  }

  static computePaymentFrequency(frequency: ContributionFrequency | string) {
    switch (frequency) {
      case 'onetime':
        return 0
      case 'annually':
        return 1
      case 'monthly':
        return 12
      case 'semimonthly':
        return 24
      default:
        return 0
    }
  }

  static computeYearsUntilCollege(
    contributionStartDate: Date,
    collegeEntryDate: Date,
  ) {
    const moment = require('moment')
    let yearsUntilCollege: number = moment(collegeEntryDate)
      .diff(moment(contributionStartDate), 'years', true)
      .toFixed(2)
    if (typeof yearsUntilCollege === 'string') {
      yearsUntilCollege = Number(yearsUntilCollege)
    }
    return yearsUntilCollege > 0 ? yearsUntilCollege : AGE_AT_COLLEGE
  }

  static calculateCBReturn(
    childAge: number,
    monthlyContribution: number,
    contributorsCount: number,
    externalContribution: number,
    cashback: number,
  ): number {
    const totalGiftsAmount = calculateExternalContribution(
      contributorsCount,
      externalContribution,
    )
    const totalMonthlyContribution =
      monthlyContribution + totalGiftsAmount + cashback

    return this.calculateContributionGrowth({
      frequency: 'monthly',
      yearsUntilCollege: yearsUntilCollegeFromAge(childAge),
      currentBalance: 0,
      contributionAmount: totalMonthlyContribution
    }).value
  }

  static calculateStockMarketReturn(
    childAge: number,
    monthlyContribution: number,
  ): number {
    const totalMonths = Math.max(1, monthsUntilCollegeFromAge(childAge))
    const totalContribution = this.calculateFutureValue(
      totalMonths,
      monthlyContribution,
      0,
    ).value
    const fvWithInterest = this.calculateContributionGrowth({
      frequency: 'monthly',
      yearsUntilCollege: yearsUntilCollegeFromAge(childAge),
      currentBalance: 0,
      contributionAmount: monthlyContribution
    }).value

    return removeTaxesFromForecast(totalContribution, fvWithInterest)
  }

  static calculateSavingsAccountReturn(
    childAge: number,
    monthlyContribution: number,
  ): number {
    const totalMonths = Math.max(1, monthsUntilCollegeFromAge(childAge))
    const totalContribution = this.calculateFutureValue(
      totalMonths,
      monthlyContribution,
      0,
    ).value

    const fvWithInterest = this.calculateContributionGrowth({
      frequency: 'monthly',
      yearsUntilCollege: yearsUntilCollegeFromAge(childAge),
      currentBalance: 0,
      contributionAmount: monthlyContribution,
      interest: SAVINGS_ACCOUNT_INTEREST
    }).value

    return removeTaxesFromForecast(totalContribution, fvWithInterest)
  }
}
export type ContributionFrequency =
  | 'monthly'
  | 'onetime'
  | 'annually'
  | 'semimonthly'

