import segment from 'utils/segment'
import { getMobileAppInfo, isMobile } from '../utils/helpers'
import { Cookie } from '../cookie'

export function track(eventName: string, payload: object): void {
  const { mobileApp, platform } = getMobileAppInfo()
  const info = {
    mobileApp,
    mobileWeb: isMobile() && !mobileApp,
    platform,
  }
  const cookieInfo = infoFromCookie()

  // segment?.track(eventName, { ...info, ...cookieInfo, ...payload })
}

function infoFromCookie(): { userUuid: string; email: string } | object {
  const userJson = Cookie.getVisitor()
  if (userJson) {
    return { userUuid: userJson.uuid, email: userJson.email }
  } else {
    return {}
  }
}
