import React from 'react'
import classNames from 'classnames'
import { generateClasses } from 'utils/index'

import './ScreenSubHeader.scss'

export const ScreenSubHeader: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  className,
}) => {
  return (
    <>
      <h4
        className={classNames(generateClasses('screen__section-title', className), {
        'screen__section-title--no-subtitle': !subtitle,
        })}
      >
        {title}
      </h4>
      {subtitle && (
        <p
          className={generateClasses(
            'screen__section-subtitle', className
          )}
        >
          {subtitle}
        </p>
      )}
    </>
  )
}

interface Props {
  title: string | JSX.Element
  subtitle?: string
  className?: string
}
