import { Component, KeyboardEvent } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import config from './config'
import { Cookie } from './cookie'
import { isMobileApp, setIsMobileAppInAppBrowserFromQuery } from 'utils/helpers'
import { testingSkeleton } from 'hooks/useSkeleton'
import routes, { RenderRoutes } from 'routes'

import { setupFullStory } from './fullStory'
import { removeHasProKey } from 'modules/enrollment/helpers'
import { isAuthenticated } from 'utils'
setupFullStory(config)

const root = window as any
const document = root.document
const isProduction = !['development', 'staging'].includes(config.env)

setIsMobileAppInAppBrowserFromQuery()

isMobileApp() && document.body.classList.add('inside-mobile-app')
testingSkeleton() && document.body.classList.add('testing-skeleton')

const visitor = Cookie.getVisitor()
if (visitor) {
  // Zen Desk JWT Token
  const webWidgetConfig = {
    authenticate: null as any,
    contactForm: {
      fields: [
        { id: 'name', prefill: { '*': visitor.name() } },
        { id: 'email', prefill: { '*': visitor.email } }
      ]
    }
  }

  if (isProduction) {
    // ZENDESK AUTH
    webWidgetConfig.authenticate = {
      chat: {
        jwtFn(callback: any) {
          if (!Cookie.getVisitor()) return callback(false)
          if (!isProduction) return callback(false)

          const url = new URL(`${config.REST_API_URL}/zendesk_token`)
          const authToken = Cookie.get('authorization')!
          url.searchParams.append('authorization', authToken)

          fetch(url.toString())
            .then((response) => response.text())
            .then(callback)
            .catch(console.error)
        }
      }
    }
  }

  const win = window as any
  win.zESettings = { webWidget: webWidgetConfig }
}

// SENTRY INIT/ERROR CAPTURE
Sentry.init({
  environment: config.env,
  dsn: config.SENTRY_DSN,
  integrations: [new BrowserTracing()]
})

class App extends Component {
  async componentDidMount() {
    if (!isAuthenticated()) {
      removeHasProKey()
    }

    const userJson = Cookie.getVisitor()

    if (root.dataLayer) {
      root.dataLayer.push({
        app: isMobileApp() ? 'mobile-app' : 'not-mobile-app'
      })
    }

    // BOOT SEGMENT CALL
    // bootSegment()

    // Identify user in FullStory
    if (config.env === 'production' && userJson) {
      const { firstName, lastName, email, uuid } = userJson
      const fullStory = (window as any).FS

      if (fullStory) {
        fullStory.identify(uuid, {
          displayName: `${firstName} ${lastName}`,
          appType: isMobileApp() ? 'mobile' : 'web',
          email
        })
      }
    }

    document.addEventListener('keydown', this.handleFirstTab)
  }

  componentDidCatch(error: any, errorInfo: any): void {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    if (super.componentDidCatch) super.componentDidCatch(error, errorInfo)
  }

  componentWillUnmount(): void {
    removeHasProKey()
  }
  // loadZenDesk = () => {
  //   return
  //   if (typeof window !== "undefined" && !(window as any).zE) {
  //     const script = document.createElement("script")
  //     script.async = true
  //     script.id = "ze-snippet"
  //     script.src =
  //       "https://static.zdassets.com/ekr/snippet.js?key=61bcace6-e137-40ba-9036-4af591bb93cf"
  //     document.head.appendChild(script)
  //   }
  // }

  handleFirstTab = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      document.body.classList.add('user-is-tabbing')

      document.removeEventListener('keydown', this.handleFirstTab)
      document.addEventListener('mousedown', this.handleMouseDownOnce)
    }
  }

  handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing')

    document.removeEventListener('mousedown', this.handleMouseDownOnce)
    document.addEventListener('keydown', this.handleFirstTab)
  }

  render() {
    return <RenderRoutes routes={routes} />
  }
}

export default App
