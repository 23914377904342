// @ts-nocheck
import { Length, IsOptional } from 'class-validator'
import { BaseValueObject } from './base.value-object'

export class MoneyValueObject extends BaseValueObject {
  @IsOptional()
  @Length(3, 3)
  readonly currency!: 'USD'

  @IsOptional()
  readonly symbol!: '$'

  @Length(2, 60)
  readonly value!: number

  constructor(money?: Partial<MoneyValueObject>) {
    super()
    if (!money) {
      return this
    }
    this.currency = money.currency || 'USD'
    this.symbol = money.symbol || '$'
    this.value = money.value
  }

  toString(
    decimals: boolean = true,
    onlySuppressZeroDecimals?: boolean,
  ): string {
    let value = (this.value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

    if (!decimals) {
      if (onlySuppressZeroDecimals === true) {
        if (value.substr(-3) === '.00') {
          value = value.substring(0, value.length - 3)
        }
      } else {
        value = value.substring(0, value.length - 3)
      }
    }

    return value
  }
}
