import moment from 'moment'
import { makeVar } from '@apollo/client'
import { BankAccount, CreditCard, CurrentFlow, Frequency } from './types'

const today = moment()
export const activationDateValue = makeVar<Date>(today.toDate())
export const amountValue = makeVar<number>(0)
export const bankAccountValue = makeVar<BankAccount | undefined | null>(null)
export const beneficiaryNameValue = makeVar<string>('')
export const contributionIdValue = makeVar<number | undefined>(undefined)
export const contributionInProgressValue = makeVar<boolean>(false)
export const creditCardValue = makeVar<CreditCard | undefined | null>(null)
export const currentFlowValue = makeVar<CurrentFlow>('create-contribution')
export const referrerValue = makeVar<'giftingPage' | 'dashboard'>('dashboard')
export const firstDayValue = makeVar<number>(today.date())
export const frequencyValue = makeVar<Frequency>('monthly')
export const gifterEmailValue = makeVar<string>('')
export const gifterNameValue = makeVar<string>('')
export const guestAcceptedTerms = makeVar<boolean>(false)
export const messageValue = makeVar<string>('')
export const parentEmailValue = makeVar<string>('')
export const parentNameValue = makeVar<string>('')
export const secondDayValue = makeVar<number>(today.add(14, 'd').date())
export const ccStreet = makeVar<string>('')
export const ccStreet2 = makeVar<string>('')
export const ccCity = makeVar<string>('')
export const ccState = makeVar<string>('')
export const ccZip = makeVar<string>('')
export const userNameExistsValue = makeVar<boolean | null>(null)

// NOTE: don't reset `userNameExistsValue()` as it is used to know when
//       we do skip or not the collect-name step and want to stay consistent
//       for the user experience.
export function resetReactiveVariables() {
  const today = moment()
  activationDateValue(today.toDate())
  amountValue(0)
  bankAccountValue(null)
  beneficiaryNameValue('')
  contributionIdValue(undefined)
  contributionInProgressValue(false)
  creditCardValue(null)
  currentFlowValue('create-contribution')
  firstDayValue(today.date())
  frequencyValue('monthly')
  gifterEmailValue('')
  gifterNameValue('')
  messageValue('')
  parentEmailValue('')
  parentNameValue('')
  secondDayValue(today.add(14, 'd').date())
  ccStreet('')
  ccStreet2('')
  ccCity('')
  ccState('')
  ccZip('')
}

export function logReactiveVariables() {
  console.table({
    amountValue: amountValue(),
    frequencyValue: frequencyValue(),
    activationDateValue: activationDateValue(),
    firstDayValue: firstDayValue(),
    secondDayValue: secondDayValue(),
    bankAccountValue: bankAccountValue(),
    creditCardValue: creditCardValue(),
    contributionInProgressValue: contributionInProgressValue()
  })
}
