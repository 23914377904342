import { Helmet } from 'react-helmet-async'
import config from '../config'

export const Head = ({
  title,
  description,
  twitterCardType,
  twitterHandle,
  twitterImage,
  ogUrl,
  ogType,
  ogTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogLocale,
  ogSiteName,
  customMeta
}: Props) => {
  return (
    <Helmet>
      <title key="title">{title || config.DEFAULT_SEO.title}</title>
      <meta
        key="description"
        name="description"
        content={description || config.DEFAULT_SEO.description}
      />
      <meta
        key="twitter:card"
        name="twitter:card"
        content={twitterCardType || config.DEFAULT_SEO.twitter.cardType}
      />
      <meta
        key="twitter:site"
        name="twitter:site"
        content={twitterHandle || config.DEFAULT_SEO.twitter.handle}
      />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={twitterImage || config.DEFAULT_SEO.twitter.image}
      />
      <meta
        key="og:url"
        property="og:url"
        content={ogUrl || config.DEFAULT_SEO.openGraph.url}
      />
      <meta
        key="og:type"
        property="og:type"
        content={ogType || config.DEFAULT_SEO.openGraph.type}
      />
      <meta
        key="og:title"
        property="og:title"
        content={ogTitle || config.DEFAULT_SEO.openGraph.title || title}
      />
      <meta
        key="og:description"
        property="og:description"
        content={
          ogDescription ||
          description ||
          config.DEFAULT_SEO.openGraph.description
        }
      />
      <meta
        key="og:image"
        property="og:image"
        content={ogImage || config.DEFAULT_SEO.openGraph.image}
      />
      <meta
        key="og:image:width"
        property="og:image:width"
        content={ogImageWidth || config.DEFAULT_SEO.openGraph.imageWidth}
      />
      <meta
        key="og:image:height"
        property="og:image:height"
        content={ogImageHeight || config.DEFAULT_SEO.openGraph.imageHeight}
      />
      <meta
        key="og:locale"
        property="og:locale"
        content={ogLocale || config.DEFAULT_SEO.openGraph.locale}
      />
      <meta
        key="og:site_name"
        property="og:site_name"
        content={ogSiteName || config.DEFAULT_SEO.openGraph.site_name}
      />
      <meta property="fb:app_id" content={config.FACEBOOK.APP_ID} />
      {customMeta}
    </Helmet>
  )
}

interface Props {
  title?: string
  description?: string
  twitterCardType?: string
  twitterHandle?: string
  twitterImage?: string
  ogUrl?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
  ogImageWidth?: string
  ogImageHeight?: string
  ogLocale?: string
  ogSiteName?: string
  customMeta?: JSX.Element
}