import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { get } from 'lodash'
import { Toast } from '@collegebacker/backer-ui/ui'

import { createPlaidLinkToken, loadPlaidLink, openPlaid } from 'utils/plaid'
import { backToDashboard, isMobileApp } from 'utils/helpers'
import { linkOutOfAppForCurrentPage } from 'utils/mobile-app-publisher'
import { SessionContext } from 'context/SessionProvider'
import { ScreenHeader } from 'components/index'

const LINK_BANK_ACCOUNT = gql`
  mutation LinkBankAccount($input: LinkBankAccountInputType!) {
    linkBankAccount(input: $input) {
      id
      accountNumber
      bankLabel
      accountLabel
      removable
      removableReason
    }
  }
`

const FAIL_BANK_ACCOUNT_VERIFICATION = gql`
  mutation FailBankAccountVerification(
    $input: FailBankAccountVerificationInputType!
  ) {
    failBankAccountVerification(input: $input) {
      success
    }
  }
`

export const VerifyBankAccount = () => {
  const { bankAccountUuid }: { bankAccountUuid: string } = useParams()
  const context = useContext(SessionContext)
  const [alertMessage, setAlertMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const toastRef = useRef<ToastRef>(null)
  const [linkBankAccount] = useMutation(LINK_BANK_ACCOUNT)
  const [failBankAccountVerification] = useMutation(FAIL_BANK_ACCOUNT_VERIFICATION)

  const displayPlaidPopup = async () => {
    const { linkToken } = await createPlaidLinkToken({
      products: [],
      bankAccountUuid
    })

    const onVerificationFailed = () => {
      failBankAccountVerification({
        variables: { input: { bankAccountUuid } }
      })
        .then(() =>
          backToDashboard(
            context,
            'There was a problem verifying your bank details.'
          )
        )
        .catch((error) => setAlertMessage(JSON.stringify(error, null, 4)))
    }

    const onVerificationSuccess = (publicToken: string, metadata: any) => {
      const accountMetadata = get(metadata, 'accounts[0]', {})
      linkBankAccount({
        variables: {
          input: {
            bankAccountUuid,
            accountId: accountMetadata.id,
            verificationStatus: accountMetadata.verification_status,
            bankName: get(metadata, 'institution.name') || 'Bank Account',
            publicToken
          }
        }
      })
        .then(() => {
          setSuccess(true)
        })
        .catch((error) => setAlertMessage(JSON.stringify(error, null, 4)))
    }

    openPlaid({
      webClientSettings: {
        token: linkToken,
        onSuccess: onVerificationSuccess,
        onEvent: (eventName: string, metadata: any) => {
          if (
            eventName === 'EXIT' &&
            metadata.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'
          ) {
            onVerificationFailed()
          }
        }
      }
    })
  }

  useEffect(() => loadPlaidLink(), [])

  useEffect(() => {
    if (alertMessage !== '' && toastRef.current) {
      toastRef.current.showToast(alertMessage, {
        type: 'error',
        closeOnClick: false,
        showCloseIcon: true,
        onClose: () => setAlertMessage('')
      })
    }
  }, [alertMessage])

  if (success)
    return (
      <div className="verify-bank-account">
        <ScreenHeader title="Bank account verification" />
        <p>Account verified successfully 🎉</p>
      </div>
    )

  return (
    <div className="verify-bank-account">
      <Toast ref={toastRef} />
      <ScreenHeader title="Bank account verification" />
      {isMobileApp() ? (
        <p>
          This feature is not available yet on our mobile app but you can{' '}
          <a
            href={linkOutOfAppForCurrentPage()}
            style={{ textDecoration: 'underline' }}
          >
            click here to open this page in a browser
          </a>
          .
        </p>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <button onClick={displayPlaidPopup}>Verify bank account</button>
        </div>
      )}
    </div>
  )
}
