import { FetchResult } from '@apollo/client'
import classNames from 'classnames'
import {
  Button,
  LabelTag,
  Stack,
  WarnMesssage
} from '@collegebacker/backer-ui/ui'
import { PricingPlan, SubscriptionFrequency, UserSubscriptionDates } from '../userInterfaces'
import { DateFormat } from 'utils'
import config from 'config'
import { isProAnnual } from 'utils/user'

import styles from './SelectSubscription.module.scss'

export const SelectSubscription = ({
  subscriptionDueOn,
  updateUserPricingPlan,
  loading,
  onSuccess,
  setError,
  pricingPlan,
  countEstablishedFunds,
  subscriptionFrequency,
  selectedPlan,
  setSelectedPlan,
  enrollment,
  onCancel
}: {
  subscriptionDueOn: Date
  updateUserPricingPlan: (
    input: object
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  loading: boolean
  onSuccess: (subscriptionDates: UserSubscriptionDates) => void
  setError: (arg0: string) => void
  countEstablishedFunds: number
  pricingPlan: PricingPlan
  subscriptionFrequency: SubscriptionFrequency
  selectedPlan?: TSelectedPlan
  setSelectedPlan: (arg0: TSelectedPlan) => void
  enrollment?: boolean
  onCancel: () => void
}) => {
  const currentlyAnnualProTier = isProAnnual({
    pricingPlan,
    subscriptionFrequency
  })

  const selectedPlanIsFree = selectedPlan === 'free'
  const selectedPlanIsMonthlyPro = selectedPlan === 'monthlyPro'
  const selectedPlanIsAnnualPro = selectedPlan === 'annualPro'

  const notAllowedToDowngrade =
    ((enrollment && countEstablishedFunds === 1) ||
      countEstablishedFunds > 1) &&
    selectedPlanIsFree

  const notAllowedToSwitchFrequency =
    currentlyAnnualProTier && selectedPlanIsMonthlyPro

  const subscriptionFrequencyToLabel = {
    monthly: 'monthly',
    annually: 'annual'
  }
  const selectedPlanToLabel = {
    free: 'free',
    monthlyPro: 'monthly',
    annualPro: 'annual'
  }

  return (
    <Stack spacing={40}>
      <div>
        <h2 className="typo-app-title-medium-adaptive">
          Change your membership
        </h2>
        <Stack spacing={20}>
          <p className="typo-app-body-paragraph-adaptive">
            Your membership is set to renew on{' '}
            {DateFormat.short(subscriptionDueOn)}
          </p>
          <div className={styles.cardList}>
            <button
              className={classNames(styles.card, {
                [styles.selected]: selectedPlanIsFree
              })}
              onClick={() => setSelectedPlan('free')}
            >
              <div className={styles.top}>
                <div className={styles.header}>
                  <p className="typo-app-body-large-adaptive">Free</p>
                </div>
                <p className="typo-app-body-large-adaptive">$0</p>
              </div>
            </button>
            <button
              onClick={() => setSelectedPlan('monthlyPro')}
              className={classNames(styles.card, {
                [styles.selected]: selectedPlanIsMonthlyPro
              })}
            >
              <div className={styles.top}>
                <div className={styles.header}>
                  <p className="typo-app-body-large-adaptive">Pro - Monthly</p>
                </div>
                <p className="typo-app-body-large-adaptive">
                  $6 / <span className="typo-app-body-caption">per month</span>
                </p>
              </div>
            </button>
            <button
              onClick={() => setSelectedPlan('annualPro')}
              className={classNames(styles.card, {
                [styles.selected]: selectedPlanIsAnnualPro
              })}
            >
              <div className={styles.top}>
                <div className={styles.header}>
                  <p className="typo-app-body-large-adaptive">Pro - Annual</p>
                </div>
                <p
                  className={classNames(
                    'typo-app-body-large-adaptive',
                    styles.gap
                  )}
                >
                  $48 <span className="typo-app-body-caption">/ per year</span>
                </p>
              </div>
              <div>
                <div
                  className={classNames(
                    'typo-app-body-large-adaptive',
                    styles.gap
                  )}
                >
                  <span className={styles.gap}>
                    <span className={styles.strikethrough}>$6</span>
                    $4{' '}
                    <span className="typo-app-body-caption">/ per month</span>
                  </span>
                  <LabelTag
                    label="Save 32%"
                    className={styles.labelTag}
                    color="success"
                    size="small"
                  />
                </div>
              </div>
            </button>
          </div>
        </Stack>
      </div>
      <div className={styles.ctaContainer}>
        {notAllowedToDowngrade || notAllowedToSwitchFrequency ? (
          <Stack spacing={30}>
            <WarnMesssage
              text={
                notAllowedToDowngrade
                  ? `You're unable to downgrade to our Free tier because you ${enrollment ? 'already have' : 'have more than'
                  } one fund with an active 529 plan. If you'd like to remove one of your accounts, please contact support.`
                  : `Please contact support to change your subscription from ${subscriptionFrequencyToLabel[subscriptionFrequency]} to ${selectedPlanToLabel[selectedPlan]}.`
              }
              className={styles.warnMessage}
              type="warning"
            />
            <Button
              label="Contact support"
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={config.HELP_URL}
              minWidth={335}
            />
            <Button
              label="I'll keep Backer Pro"
              tag="a"
              mode="ghost"
              onClick={onCancel}
              minWidth={335}
            />
          </Stack>
        ) : (
          <Stack>
            <Button
              disabled={loading || !selectedPlan}
              label="Confirm"
              minWidth={335}
              mode="outline"
              onClick={() => {
                updateUserPricingPlan({
                  variables: {
                    input: mapSelectedPlanToInput[selectedPlan as TSelectedPlan]
                  }
                })
                  .then((response) => {
                    onSuccess(response.data.updateUserPricingPlan)
                  })
                  .catch((e: { message: string }) => {
                    console.error(e)
                    setError(e.message)
                  })
              }}
            />
          </Stack>
        )}
      </div>
    </Stack>
  )
}

export type TSelectedPlan = 'free' | 'monthlyPro' | 'annualPro'

export const mapSelectedPlanToInput = {
  free: {
    pricingPlan: 'backer-free'
  },
  monthlyPro: {
    pricingPlan: 'backer-pro',
    subscriptionFrequency: 'monthly'
  },
  annualPro: {
    pricingPlan: 'backer-pro',
    subscriptionFrequency: 'annually'
  }
}
