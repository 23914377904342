import React from 'react'
import { useHistory } from 'react-router-dom'
import { Icon } from '@collegebacker/backer-ui/ui'

import { generateClasses } from 'utils/index'
import config from 'config'

import './BackLink.scss'

export const BackLink: React.FunctionComponent<Props> = ({
  callback,
  modifier,
  noIcon,
  className
}) => {
  const history = useHistory()
  if (!noIcon) {
    return (
      <button
        className={`${generateClasses('back-link', modifier)} ${className}`}
        onClick={() => {
          if (callback) callback()
          else history.goBack()
        }}
        type="button"
      >
        <Icon
          className={generateClasses(`icon__left-caret`, modifier)}
          name="arrow-left"
        />
      </button>
    )
  } else {
    return (
      <a
        className={generateClasses('back-link', modifier)}
        href={config.HELP_URL}
        target="_blank"
        rel="noreferrer"
      >
        <Icon
          className={generateClasses(`icon__left-caret questionMark`, modifier)}
          name="question"
        />
      </a>
    )
  }
}

interface Props {
  callback?: () => any
  modifier?: string
  noIcon?: boolean
  className?: string
}
