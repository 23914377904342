// @ts-nocheck
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import { uniqueId } from 'lodash'
import { useState, useEffect } from 'react'
import config from './config'

const cookieImportListeners = {}
export class Cookie {
  /*
   * Specialized methods
   */
  static getAuthorization(): string | undefined {
    /*
     * The `authorization` cookie was set by `axios` during Signin and Signup.
     * Axios receives the header `Set-Cookie: authorization=<token>`.
     */
    return Cookie.get('authorization')
  }

  static getVisitor(): Visitor | undefined {
    const authorization = this.getAuthorization()
    let visitor = authorization && jwtDecode(authorization)

    if (!visitor) {
      return undefined
    }

    visitor = {
      ...visitor,
      name: () => {
        return `${visitor.firstName || ''} ${visitor.lastName || ''}`.trim()
      },
    }
    return visitor
  }

  static removeAuthorization(): void {
    Cookie.delete('authorization')
    Cookie.delete('guest')
  }

  /**
   * Cross Domain Set
   * Cookies set like this will work across domains
   */
  static cdSet(name, value, extraOptions = {}) {
    if (typeof document === 'undefined') return undefined
    if (typeof value === 'undefined') return undefined
    if (value === 'undefined') return undefined

    const options = {
      expires: 365,
      path: '/',
      domain: config.COOKIE_DOMAIN || window.location.hostname,
    } as any

    if (window.location.protocol === 'https:') {
      // avoid using these settings on dev/test
      options.secure = true
      options.sameSite = 'None'
    }

    Cookies.set(name, value, {
      ...options,
      ...extraOptions,
    })
  }

  /**
   * Triggered after a cookie import from another domain
   */
  static notifyCookiesImported() {
    for (const listener of Object.keys(cookieImportListeners)) {
      try {
        cookieImportListeners[listener]()
      } catch (e) {}
    }
  }

  /*
   * Generic methods
   */
  static set(name: string, value: string, days: number = 365) {
    if (
      typeof document === 'undefined' ||
      value === null ||
      value === undefined
    ) {
      return undefined
    }
    const data = [
      name + '=' + (value || ''),
      `domain=${config.COOKIE_DOMAIN}`,
      'path=/',
    ]
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      let expires = date.toUTCString()
      // Some cookies must be session based
      if (name === 'gift') {
        expires = 0 as any
      }
      data.push('expires=' + expires)
    }
    document.cookie = data.join('; ')
  }

  static get(name) {
    return Cookies.get(name)
  }

  static clear(cookies) {
    cookies.forEach(name => {
      Cookie.delete(name)
    })
  }

  static delete(name) {
    return Cookies.remove(name, {
      path: '/',
      domain: config.COOKIE_DOMAIN || window.location.hostname,
    })
  }

  static deleteAllCookies() {
    if (typeof document === 'undefined') {
      return undefined
    }

    const cookies = document.cookie.split('; ')
    for (const cookie of cookies) {
      const d = window.location.hostname.split('.')
      while (d.length > 0) {
        // tslint:disable-next-line
        const cookieBase =
          encodeURIComponent(cookie.split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path='
        const p = window.location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  }
}

export type CookieNames =
  | 'authorization'
  | 'guest'
  | 'untrackable'
  | 'referralCode'
  | 'inviteUUID'
  | 'utmSource'
  | 'utmMedium'
  | 'utmCampaign'
  | 'origin'
  | 'promotion'
  | 'pixelAnalyticsTID'
  | 'gift'
  | 'redirectTo'
  | 'voucher'
  | 'prebirth'
  | 'tracking'
  | 'referrerFirstName'
  | 'referralAmount'
  | 'referralUUID'
  | 'visitedOffersSite'
  | 'externalId'
  | 'partnerToken'
  | 'reminderBarMessage'
  | 'signupMessage'
  | 'pricingMessage'
  | 'authDeviceId'

export const AUTH_DEVICE_ID_COOKIE_NAME = 'authDeviceId'

export interface Visitor {
  uuid: string
  email: string
  firstName: string
  lastName: string
  photoUrl: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
  isAdmin: boolean
  name: () => string
}

export function onCookiesImport(listener) {
  const id = uniqueId()
  cookieImportListeners[id] = listener
}

export function offCookiesImport(id) {
  delete cookieImportListeners[id]
}

export function useEffectOnCookieImport(listener) {
  const id = onCookiesImport(listener)
  useEffect(() => {
    return () => {
      offCookiesImport(id)
    }
  })
}

export function useCookieImport() {
  const [cookiesImported, setCookiesImported] = useState(false)
  const listener = onCookiesImport(() => {
    setCookiesImported(true)
  })

  useEffect(() => {
    return () => {
      offCookiesImport(listener)
    }
  })

  return cookiesImported
}

export function prepareRegistrationParams(input: any) {
  const values = [
      ['inviteUuid', Cookies.get('inviteUUID')],
      ['sharedReferralCode', Cookies.get('referralCode')],
      ['referralUuid', Cookies.get('referralUUID')],
      ['origin', Cookies.get('origin')],
      ['utmSource', Cookies.get('utmSource')],
      ['utmMedium', Cookies.get('utmMedium')],
      ['utmCampaign', Cookies.get('utmCampaign')],
      ['tracking', Cookies.get('tracking')],
      ['partnerToken', Cookies.get('partnerToken')],
      ['externalId', Cookies.get('externalId')],
  ]
  for (const [name, value] of values) {
    if (value && name) input[name] = value
  }
  return input
}
