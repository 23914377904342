//@ts-nocheck
import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { loadScript } from 'utils/index'
import config from '../config'
import { getClient } from 'utils/apollo-client'
import { track } from 'utils/analytics-tracker'

let plaidHandler = null
let onPlaidReady = () => { }

const CREATE_PLAID_LINK_TOKEN = gql`
  mutation CreatePlaidLinkToken($input: CreatePlaidLinkTokenInputType!) {
    createPlaidLinkToken(input: $input) {
      linkToken
    }
  }
`
export async function createPlaidLinkToken(options) {
  const response = await getClient().mutate({
    mutation: CREATE_PLAID_LINK_TOKEN,
    variables: { input: options },
  })
  return response.data.createPlaidLinkToken
}

// preload Plaid Link with a Plaid token
// when the component is mounted
export function usePlaid(onError: (error) => void, products = ['auth'], linkCustomizationName: any = null) {
  useEffect(() => loadPlaidLink(), [])
  const [plaidLinkToken, setPlaidLinkToken] = useState(null)

  useEffect(() => {
    createPlaidLinkToken({ products, linkCustomizationName })
      .then(({ linkToken }) => setPlaidLinkToken(linkToken))
      .catch(error => {
        onError(error)
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (plaidLinkToken) {
      onPlaidReady(plaidLinkToken)
    }
  }, [plaidLinkToken])

  const whenReady = fn => {
    if (plaidLinkToken) return fn(plaidLinkToken)
    onPlaidReady = fn
  }

  // this becomes the public openPlaid function
  return ({ webClientSettings = {} } = {}) => {
    whenReady(plaidLinkToken => {
      webClientSettings.token = webClientSettings.token || plaidLinkToken
      openPlaid({
        webClientSettings,
      })
    })
  }
}

// display plaid popup
export function openPlaid({
  webClientSettings = {},
} = {}) {
  loadPlaidLink(() => {
    plaidHandler = createPlaidHandler(webClientSettings)
    plaidHandler.open(webClientSettings.institutionId)
  })
}

export function loadPlaidLink(then = null) {
  if (!then) then = () => { }
  if ((window as any).Plaid) return then()
  loadScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js', then)
}

export function createPlaidHandler(settings = {}) {
  return (window as any).Plaid.create({
    ...{
      apiVersion: 'v2',
      clientName: 'Backer',
      env: config.PLAID_ENV,
      key: config.PLAID_PUBLIC_KEY,
      countryCodes: ['US'],
    },
    ...settings,
    onEvent: (eventName: string, metadata: any) => {
      if (settings.onEvent) {
        settings.onEvent(eventName, metadata)
      }

      handlePlaidEvent(eventName, metadata)
    },
  })
}

const handlePlaidEvent = async (eventName: string, { error_code, view_name }: any) => {
  track('PlaidEvent', { eventName, errorCode: error_code, viewName: view_name })
};

export function getPlaidHandler() {
  return plaidHandler
}

export function forcePlaidExit() {
  plaidHandler.exit({ force: true })
}
