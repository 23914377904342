import { ReactNode, forwardRef } from 'react'
import Tippy from '@tippyjs/react'
import { Icon } from '@collegebacker/backer-ui/ui'

import { generateClasses } from 'utils/index'

import 'tippy.js/dist/tippy.css'
import './InfoBubble.scss'

export const InfoBubble = forwardRef(
  (
    {
      content,
      modifier,
      imgSrc,
      btnClassName,
      imgClassName,
      theme,
      maxWidth,
      interactive,
      iconName,
      placement
    }: Props,
    ref
  ) => {
    return (
      <Tippy
        // @ts-ignore
        ref={ref}
        content={content}
        theme={theme || 'black'}
        maxWidth={maxWidth || 360}
        interactive={interactive}
        placement={placement || 'top'}
      >
        {/* Here, we use div role="button" instead of a button because we might have a button as an ascendant and we cannot have nested buttons */}
        <div
          className={`${generateClasses('info-bubble-new__button', modifier)} ${
            btnClassName || ''
          }`}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Icon name={(iconName as IconTypes) || 'alert'} />
        </div>
      </Tippy>
    )
  }
)

interface Props {
  content: string | ReactNode
  modifier?: string
  imgSrc?: string
  btnClassName?: string
  imgClassName?: string
  theme?: string
  maxWidth?: number
  interactive?: boolean
  iconName?: string
  placement?: 'top' | 'bottom' | 'auto' | 'bottom-start'
}
