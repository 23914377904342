import { CopyLinkButton } from 'components/index'
import { Input } from '@collegebacker/backer-ui/ui'

import styles from './CopyInviteLink.module.scss'

export const CopyInviteLink = ({ link, linkText, label, callback }: Props) => {
  return (
    <div className={styles.copyInvite} onClick={(e) => e.stopPropagation()}>
      <Input
        label={label}
        readOnly
        className={styles.copyInvite__url}
        name="invite-link"
        value={linkText}
      />
      <CopyLinkButton
        label="Copy"
        link={link}
        copied={callback}
        modifier="copy-invite-link"
      />
    </div>
  )
}

interface Props {
  link: string
  linkText: string
  label: string
  callback?: () => void
}
