import { useRef, useEffect } from 'react'
import gsap from 'gsap'
import styles from './TabPage.module.scss'

export const TabPage = ({
  children,
  index,
  activeTab,
  tabName,
  className = '',
  ...props
}: {
  children: any
  index?: number
  activeTab: number | string
  tabName?: string
  className?: string
  [key: string]: any
}) => {
  const ref = useRef<any>(null)
  const cardShift = -10
  const transitionTime = 0.3
  const activeTabIsANumber = typeof activeTab === 'number'

  const matches = (activeTabIsANumber && activeTab === index) || (!activeTabIsANumber && activeTab === tabName)

  // Set the initial position of the tab page
  useEffect(() => {
    if (matches) {
      gsap.set(ref.current, {
        display: 'none',
        opacity: 1,
        x: 0,
      })
    } else {
      gsap.set(ref.current, {
        display: 'none',
        opacity: 0,
        x: cardShift,
      })
    }
  }, [])

  // Animate the tab page in
  useEffect(() => {
    if (matches) {
      gsap.fromTo(
        ref.current,
        {
          display: 'none',
          opacity: 0,
          x: -cardShift,
        },
        {
          display: 'flex',
          duration: transitionTime,
          opacity: 1,
          x: 0,
          delay: transitionTime,
          ease: 'power1.inOut',
        },
      )
    } else {
      gsap.to(ref.current, {
        display: 'none',
        duration: transitionTime,
        opacity: 0,
        x: cardShift,
        ease: 'power1.inOut',
      })
    }
  }, [activeTab, index, tabName])

  return (
    <div className={`${styles.page} ${className}`} ref={ref} {...props}>
      {children}
    </div>
  )
}
