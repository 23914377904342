import moment from 'moment'
import { get } from 'lodash'
import { ReactiveVar } from '@apollo/client'
import {
  activationDateValue,
  amountValue,
  beneficiaryNameValue,
  contributionIdValue,
  currentFlowValue,
  firstDayValue,
  frequencyValue,
  messageValue,
  parentEmailValue,
  parentNameValue,
  referrerValue,
  resetReactiveVariables,
  secondDayValue
} from './LocalState'
import { BankAccount, CreditCard, CurrentFlow, Frequency, Step } from './types'
import { historyRef } from 'routes'
import { chargeCreditCard, track } from 'utils'
import { NO_FUND_HANDLE } from 'utils/helpers'
import { isScheduled, isEditing } from 'utils/contributionDraft'

import { buildPath } from 'routes'
import { isCreditCardAmountTooHigh } from './components/CreditCardLimitWarning'
import { areFundsEnough } from './components/InSufficientFundsWarning'
import bankImage from 'static/img/bank.svg'
import creditCardImage from 'static/img/credit-card-thin.svg'
import styles from './helpers.module.scss'
import { getQuery } from 'hooks'

export function paymentMethodInfo(
  paymentMethod: {
    bankAccount: BankAccount | null | undefined
    creditCard: CreditCard | null | undefined
  } | null
): {
  firstLine?: string
  secondLine?: string
  editable?: boolean
  imageSrc?: string
  imageCls?: string
  type?: 'bank-account' | 'credit-card'
} {
  const { bankAccount, creditCard } = paymentMethod || {}
  const { accountLabel, bankLabel } = bankAccount || creditCard || {}

  if (bankAccount) {
    return {
      firstLine: `${bankLabel} ${bankAccount.name}`,
      secondLine: accountLabel,
      editable: true,
      imageSrc: bankImage,
      imageCls: styles.bankImage,
      type: 'bank-account'
    }
  } else if (creditCard) {
    return {
      firstLine: `${bankLabel} ${accountLabel}`,
      secondLine: creditCard?.expiration ? creditCard?.expiration : '',
      editable: true,
      imageSrc: creditCardImage,
      imageCls: styles.creditCardImage,
      type: 'credit-card'
    }
  } else {
    return {}
  }
}

export function hasPendingBankAccount(bankAccount: BankAccount | null | undefined): boolean {
  return (
    get(bankAccount, 'plaidVerificationStatus') || ''
  ).includes('pending')
}

export function isPendingManualVerification(bankAccount: BankAccount | null | undefined): boolean {
  return (
    get(bankAccount, 'plaidVerificationStatus') || ''
  ).includes('manual')
}

export const finishRoute = (
  referrer: string,
  contributionDraft: any,
  fund?: {
    uuid: string
  },
  extraParams: any = {}
) => {
  // one of ['fund', 'dashboard', 'settings']
  const redirection = referrer

  const draftId = contributionDraft.id
  const intentId = extraParams['paymentIntentId'] || 'none'
  const methodId = extraParams['paymentMethodId'] || 'none'
  const usesCreditCard = contributionDraft.creditCard ? 'true' : 'false'
  const uuid = fund?.uuid || 'no-uuid'
  const pendingPaymentMethod = hasPendingBankAccount(contributionDraft.bankAccount)
    ? 'true'
    : 'false'

  return buildPath('finishContributionOperation', {
    draftId,
    redirection,
    usesCreditCard,
    intentId,
    methodId,
    fundUuid: uuid,
    pendingPaymentMethod
  })
}

/**
  Examples:
 
  startContributionUrl({
    flow: 'create-starter-gift',
  })

  startContributionUrl({
    handle
    flow: 'create-contribution',
    search: `?referrer=${encodeURIComponent(location.pathname)}`
  })

  startContributionUrl({
    handle,
    flow: 'update-contribution',
    contributionId: id,
    search: `?referrer=${location.pathname}`
  })
*/

export function startContributionUrl({
  flow = 'create-contribution',
  handle = NO_FUND_HANDLE,
  contributionId,
  search,
  step = 'amount'
}: {
  flow?: CurrentFlow
  handle?: string
  contributionId?: number
  search?: string
  step?: Step
}) {
  const params: {
    flow: CurrentFlow
    handle: string
    step: Step
    contributionId?: number
  } = {
    handle,
    step,
    flow
  }

  if (isUpdateFlow(flow) && contributionId) {
    params.step = 'update'
    params.contributionId = contributionId
  }

  const pathname = buildPath('contributionFlow', params)
  return `${pathname}${search || ''}`
}

export function getPaymentMethod(
  user: {
    bankAccounts: BankAccount[]
    creditCards: CreditCard[]
    defaultPaymentMethodId: string
    defaultPaymentMethodType: string
  },
  bankAccountValue: ReactiveVar<BankAccount | undefined | null>,
  creditCardValue: ReactiveVar<CreditCard | undefined | null>,
  updatingPaymentMethod?: boolean
) {
  const {
    bankAccounts,
    creditCards,
    defaultPaymentMethodId,
    defaultPaymentMethodType
  } = user || {}

  let bankAccount: BankAccount | undefined | null = bankAccountValue()
  let creditCard: CreditCard | undefined | null = creditCardValue()

  if (!bankAccount && !creditCard) {
    if (updatingPaymentMethod)
      return {
        bankAccount: null,
        creditCard: null
      }
    if (defaultPaymentMethodType === 'ba') {
      bankAccount = bankAccounts.find((ba) => ba.id === defaultPaymentMethodId)
    } else if (defaultPaymentMethodType === 'cc') {
      creditCard = creditCards.find(
        (card) => card.id === defaultPaymentMethodId
      )
    }
  }

  return {
    bankAccount,
    creditCard
  }
}

export function setPaymentMethod({
  bankAccount,
  creditCard,
  bankAccountValue,
  creditCardValue
}: {
  bankAccount?: any
  creditCard?: any
  bankAccountValue: ReactiveVar<BankAccount | undefined | null>
  creditCardValue: ReactiveVar<CreditCard | undefined | null>
}) {
  if (bankAccount) {
    bankAccountValue(bankAccount)
    creditCardValue(null)
  } else if (creditCard) {
    bankAccountValue(null)
    creditCardValue(creditCard)
  }
}

export function computeProcessingFee({
  paymentMethod,
  amount
}: {
  paymentMethod?: {
    bankAccount: any
    creditCard: any
  }
  amount: number
}) {
  if (!paymentMethod) return 0

  const { bankAccount } = paymentMethod
  if (bankAccount) return 0

  // if credit card set processing fee
  return Math.ceil(0.03 * amount)
}

export function buildData() {
  const data = [
    { value: 25_00, label: '$25' },
    { value: 50_00, label: '$50' },
    { value: 100_00, label: '$100' },
    { value: 150_00, label: '$150' },
    { value: 250_00, label: '$250' },
    { value: 500_00, label: '$500' }
  ]

  return data
}

const submitCreditCardPayment = async ({
  draft,
  processingFee,
  subscriptionFee
}: {
  draft: {
    amount: number
    user: {
      uuid: string
    }
  }
  processingFee: number
  subscriptionFee: number
}) => {
  // @ts-ignore
  const { paymentIntent, error } = await chargeCreditCard({
    userUuid: draft.user.uuid,
    amount: draft.amount + subscriptionFee + processingFee,
    contributionDraft: draft
  })

  if (error) {
    console.error(error)
    throw error
  }

  return paymentIntent
}

export function getContributionAttributes({
  currentStep,
  fund,
  paymentMethod,
  user
}: IGetContributionAttributes) {
  const amount = amountValue()
  const frequency = frequencyValue()
  const firstDay = firstDayValue()
  const secondDay = secondDayValue()
  const activationDate = activationDateValue()
  const parentName = fund?.fundOwner.fullName || parentNameValue()
  const parentEmail = parentEmailValue()
  const message = messageValue()
  const gifterName = user.fullName
  const gifterEmail = user.email
  const beneficiaryName = fund?.fundName || beneficiaryNameValue()
  const contributionId = contributionIdValue()
  const currentFlow = currentFlowValue()

  let bankAccountId = null
  let creditCardId = null

  // for digital wallet, we do not pass paymentMethod in
  if (paymentMethod) {
    const { bankAccount, creditCard } = paymentMethod
    if (bankAccount) {
      bankAccountId = Number(bankAccount.id)
      creditCardId = null
    } else if (creditCard) {
      bankAccountId = null
      creditCardId = Number(creditCard.id)
    }
  }

  let attributes: {
    fundUuid?: string
    bankAccountId?: number | null
    beneficiaryName?: string
    contributionId?: number
    creditCardId?: number | null
    frequency: Frequency
    amount: number
    firstDay?: number
    secondDay?: number
    activationDate?: string | null
    currentFlow: string
    currentStep: string
    giftMessage?: string
    gifterName?: string
    gifterEmail?: string
    parentName?: string
    parentEmail?: string
  } = {
    bankAccountId,
    creditCardId,
    frequency,
    amount,
    currentFlow,
    currentStep
  }

  if (frequency === 'onetime' || frequency === 'annually') {
    attributes.activationDate = moment(activationDate).format('YYYY-MM-DD')
  } else {
    attributes.firstDay = firstDay
    attributes.secondDay = secondDay
  }

  if (!isStarterGiftFlow(currentFlow) && fund) {
    attributes.fundUuid = fund.uuid
  }

  if (isGiftFlow(currentFlow)) {
    attributes.giftMessage = message
    attributes.parentName = parentName
    attributes.parentEmail = parentEmail
    attributes.gifterName = gifterName
    attributes.gifterEmail = gifterEmail
    attributes.beneficiaryName = beneficiaryName
  }

  if (isUpdateFlow(currentFlow)) {
    attributes.contributionId = contributionId
  }

  return attributes
}

export function handleSubmitContribution({
  currentStep,
  disabled,
  fullAmount,
  fund,
  paymentMethod,
  setDisabled,
  user,
  createFullContributionDraft
}: IHandleSubmitContribution) {
  const amount = amountValue()
  const frequency = frequencyValue()

  const { bankAccount } = paymentMethod || {}
  const creditCardAmountTooHigh = isCreditCardAmountTooHigh({
    paymentMethod,
    amount,
    frequency
  })

  const insufficientFunds = !areFundsEnough({
    bankAccount,
    amount: fullAmount
  })

  setDisabled(true)

  if (disabled || insufficientFunds || creditCardAmountTooHigh) {
    return
  }

  const attributes = getContributionAttributes({
    currentStep,
    fund,
    paymentMethod,
    user
  })

  createFullContributionDraft(attributes)
}

function getReferrer(currentFlow: CurrentFlow) {
  if (isGiftFlow(currentFlow)) return 'gift'
  else if (currentFlow === 'enrollment-contribution') return 'enrollment'
  else {
    return referrerValue()
  }
}

export async function handleContributionSuccess({
  currentStep,
  draft,
  paymentMethod,
  setDisabled,
  processingFee,
  subscriptionFee,
  event = 'ContributionConfirmation'
}: IHandleContributionSuccess) {
  const history = historyRef.current
  const query = getQuery()
  const { creditCard, bankAccount } = paymentMethod || {}
  const { currentFlow } = draft

  const referrer = query.get('referrer') || getReferrer(currentFlow)
  const paymentMethodId = creditCard
    ? get(creditCard, 'id')
    : get(bankAccount, 'id')

  if (
    !isScheduled(draft) &&
    !isEditing(draft) &&
    creditCard &&
    creditCard.stripePaymentMethod
  ) {
    track(event, { step: currentStep, currentFlow, chargeCreditCard: true })
    const paymentIntent: any = await submitCreditCardPayment({
      draft,
      processingFee,
      subscriptionFee
    })
    const url = finishRoute(referrer, draft, draft.fund, {
      paymentIntentId: paymentIntent.id,
      paymentMethodId: paymentMethodId
    })
    setDisabled(false)
    history.replace(url)
    resetReactiveVariables()
  } else {
    track(event, { step: currentStep, currentFlow })
    history.replace(finishRoute(referrer, draft, draft.fund))
    resetReactiveVariables()
  }
}

export function isGiftFlow(currentFlow: CurrentFlow) {
  return (
    currentFlow === 'create-gift' ||
    currentFlow === 'create-starter-gift' ||
    currentFlow === 'update-gift'
  )
}

export function isStarterGiftFlow(currentFlow: CurrentFlow) {
  return currentFlow === 'create-starter-gift'
}

export function isContributionFlow(currentFlow: CurrentFlow) {
  return (
    currentFlow === 'create-contribution' ||
    'update-contribution' ||
    'enrollment-contribution'
  )
}

export function isFundCreated(handle: string) {
  return handle !== NO_FUND_HANDLE
}

export function isUpdateFlow(currentFlow: CurrentFlow) {
  return currentFlow === 'update-contribution' || currentFlow === 'update-gift'
}

interface IGetContributionAttributes {
  currentStep: Step
  fund?: {
    fundName: string
    fundOwner: {
      fullName: string
    }
    uuid: string
  }
  paymentMethod?: {
    bankAccount?: BankAccount | null
    creditCard?: CreditCard | null
  } | null
  user: {
    fullName: string
    email: string
  }
}

interface IHandleSubmitContribution extends IGetContributionAttributes {
  disabled: boolean
  fullAmount: number
  paymentMethod: {
    bankAccount?: BankAccount | null
    creditCard?: CreditCard | null
  } | null
  setDisabled: (arg0: boolean) => void
  createFullContributionDraft: (input: object) => Promise<void>
}

interface IHandleContributionSuccess {
  currentStep: Step
  draft: {
    amount: number
    currentFlow: CurrentFlow
    fund: {
      uuid: string
    }
    user: {
      uuid: string
    }
  }
  paymentMethod: {
    bankAccount?: BankAccount | null
    creditCard?: CreditCard | null
  } | null
  setDisabled: (arg0: boolean) => void
  processingFee: number
  subscriptionFee: number
  event?: string
}
