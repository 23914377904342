import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { get } from 'lodash'

const FETCH_VOUCHER = gql`
  query($code: String!) {
    voucher(code: $code) {
      amount
      code
      presentation
    }
  }
`

export function useVoucherDetails(code: string) {
  const response = useQuery(FETCH_VOUCHER, { variables: { code } })

  return {
    loading: response.loading,
    error: response.error,
    voucher: mapVoucher(get(response, 'data.voucher')),
  }
}

function mapVoucher(voucher: any) {
  if (!voucher) return null

  return {
    amount: voucher.amount,
    code: voucher.code,
    presentation: JSON.parse(voucher.presentation),
  }
}
