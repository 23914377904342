import React, { createRef, lazy, Suspense } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  generatePath
} from 'react-router-dom'
import { isAuthenticated } from 'utils/index'
import { getGiftSafely } from 'modules/auth/signinHelpers'
import { filter, includes } from 'lodash'
import { ensure, NO_FUND_HANDLE } from 'utils/helpers'
import { pathToRegexp } from 'path-to-regexp'
import Cookies from "js-cookie"

import MobileRedirect from 'modules/contribution/components/MobileRedirect'
import config from 'config'
import FundRecentActivityPage from 'modules/fund/components/FundRecentActivityPage'
import { EditPaymentMethods } from 'modules/profile/components/EditPaymentMethods'
import { AddPaymentMethod } from 'modules/profile/components/AddPaymentMethod'
import { ManualBankAccount } from 'modules/profile/components/ManualBankAccount'
import { VerifyBankAccount } from 'modules/profile/components/VerifyBankAccount'
import { ManageSubscription } from 'modules/profile/components/ManageSubscription'

const load = (Component: any) => (props: any) =>
(
  <Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
    <Component {...props} />
  </Suspense>
)

const CreateFamilyUpdateFlow = load(
  lazy(
    () =>
      import('modules/create-family-update/components/CreateFamilyUpdateFlow')
  )
)
const FamilyUpdateEditorFlow = load(
  lazy(
    () =>
      import('modules/family-update-editor/components/FamilyUpdateEditorFlow')
  )
)
const FamilyUpdateExample = load(
  lazy(
    () => import('modules/family-update-editor/components/FamilyUpdateExample')
  )
)
const ForgotPassword = load(
  lazy(() => import('./modules/auth/components/ForgotPassword'))
)
const ResetPassword = load(
  lazy(() => import('./modules/auth/components/ResetPassword'))
)
const DashboardContainer = load(
  lazy(() => import('modules/dashboard/containers/DashboardContainer'))
)
const ProfileSettingsContainer = load(
  lazy(() => import('modules/profile/containers/ProfileSettingsContainer'))
)
const ResumeLinkingFundContainer = load(
  lazy(() => import('modules/auth/containers/ResumeLinkingFundContainer'))
)
const MyContributionsContainer = load(
  lazy(() => import('modules/profile/containers/MyContributionsContainer'))
)

const ContributionFlow = load(
  lazy(() => import('modules/contribution/components/ContributionFlow'))
)
const FinishContributionOperation = load(
  lazy(
    () => import('modules/contribution/components/FinishContributionOperation')
  )
)
const EditProfileContainer = load(
  lazy(() => import('modules/profile/containers/EditProfileContainer'))
)
const EditPassword = load(
  lazy(() => import('modules/profile/components/EditPassword'))
)

const Referrals = load(
  lazy(() => import('modules/referral/components/Referrals'))
)
const BeginSession = load(
  lazy(() => import('modules/auth/components/BeginSession'))
)
const EnrollmentContainer = load(
  lazy(() => import('modules/enrollment/components/EnrollmentContainer'))
)
const PostEnrollmentContainer = load(
  lazy(
    () => import('modules/post-enrollment/components/PostEnrollmentContainer')
  )
)
const InitializeOnboarding = load(
  lazy(() => import('modules/create-fund/components/InitializeOnboarding'))
)

const Authenticate = load(
  lazy(() => import('modules/auth/components/Authenticate'))
)
const MobileAuth = load(
  lazy(() => import('modules/auth/components/MobileAuth'))
)

const CreateFundRouter = load(
  lazy(() => import('modules/create-fund/components/CreateFundRouter'))
)

const CollectName = load(lazy(() => import('modules/manager/CollectName')))

const InitiateWithdrawal = load(
  lazy(() => import('modules/withdrawal/components/InitiateWithdrawal'))
)
const FundsGiftRedemption = load(
  lazy(() => import('modules/fund/components/FundsGiftRedemption'))
)
const FundContainer = load(
  lazy(() => import('modules/fund/container/FundContainer'))
)
const EditGiftingPageContainer = load(
  lazy(() => import('modules/fund/container/EditGiftingPageContainer'))
)
const GiftingPageContainer = load(
  lazy(() => import('modules/gift/containers/GiftingPageContainer'))
)
const AccountInfo = load(
  lazy(() => import('modules/fund/components/AccountInfo'))
)
const FundBackersAndFamilyUpdatesPage = load(
  lazy(() => import('modules/fund/components/FundBackersAndFamilyUpdatesPage'))
)
const InviteManager = load(
  lazy(() => import('modules/fund/components/InviteManager'))
)
const TransactionHistoryContainer = load(
  lazy(() => import('modules/fund/container/TransactionHistoryContainer'))
)
const Relink529Page = load(
  lazy(() => import('modules/fund/components/Relink529Page'))
)
const UserTransactionHistory = load(
  lazy(
    () =>
      import('modules/transaction-history/components/UserTransactionHistory')
  )
)
const GiftPayments = load(
  lazy(() => import('modules/gift/components/GiftPayments'))
)
const NotFound = load(lazy(() => import('components/NotFound')))
const Maintenance = load(lazy(() => import('components/Maintenance')))
const FollowerContainer = load(
  lazy(() => import('modules/follower/container/FollowerContainer'))
)
const GetStarted = load(
  lazy(() => import('modules/auth/containers/GetStartedContainer'))
)
const VerifyEmail = load(
  lazy(() => import('modules/auth/containers/VerifyEmailContainer'))
)
const ValidatePasscode = load(
  lazy(() => import('modules/auth/components/ValidatePasscode'))
)
const CreatePassword = load(
  lazy(() => import('modules/auth/containers/CreatePasswordContainer'))
)

const LoginEmail = load(
  lazy(() => import('modules/auth/containers/LoginEmailContainer'))
)
const LoginPassword = load(
  lazy(() => import('modules/auth/containers/LoginPasswordContainer'))
)
const AuthLinkSent = load(
  lazy(() => import('modules/auth/components/AuthLinkSent'))
)

const MobileAppCloseInAppBrowser = load(
  lazy(() => import('modules/mobile-app/components/MobileAppCloseInAppBrowser'))
)

const CollegeInvestmentPlans = load(
  lazy(() => import('modules/chat-bot/components/CollegeInvestmentPlans'))
)

const protectedRoutes = [
  {
    path: '/',
    key: 'beginSession',
    exact: true,
    component: BeginSession
  },
  {
    path: '/initialize-onboarding',
    key: 'initializeOnboarding',
    exact: true,
    component: InitializeOnboarding
  },
  {
    path: '/create-fund/:step(collect-name|fund-name)?',
    key: 'createFund',
    exact: true,
    component: CreateFundRouter
  },
  {
    path: '/collect-name',
    key: 'collectName',
    exact: true,
    component: CollectName
  },
  {
    path: '/enrollment/:fundUuid/:step(start|owner-address|beneficiary-dob|plan-recommendation|investment-options|overview|confirm-beneficiary-dob|beneficiary-details|owner-details|contact-info|employment|driver-license|successor-details|signature|edit-application|subscription|confirmation|identity-verification|re-verify|verification-failure|application-success|contribution-success|submit-fund|activation|activation-success|my529-maintenance|all-plans|manually-link-plan|manually-link-my529-instructions|manually-link-nc529-instructions|link-beneficiary-details|verify-account-number|confirm-account-number|manual-link-cyp-confirmation|link-success)?',
    key: 'enrollment',
    exact: true,
    component: EnrollmentContainer
  },
  {
    path: '/post-enrollment/:fundUuid/:step(start|verify-account-number|confirm-account-number|link-success)?',
    key: 'post-enrollment',
    exact: true,
    component: PostEnrollmentContainer
  },
  {
    path: '/funds/gift-redemption',
    key: 'fundsGiftRedemption',
    exact: true,
    component: FundsGiftRedemption
  },
  {
    path: '/dashboard',
    key: 'dashboard',
    exact: true,
    component: () => {
      const gift = getGiftSafely()
      if (gift) {
        return <Redirect to={buildPath('fundsGiftRedemption')} />
      } else {
        return <DashboardContainer />
      }
    }
  },
  {
    path: '/resume-link-fund',
    key: 'resumeLinkFund',
    exact: true,
    component: ResumeLinkingFundContainer
  },
  {
    path: '/funds-gifts',
    key: 'fundsGifts',
    exact: true,
    component: () => <Redirect to={buildPath('dashboard')} />
  },
  {
    path: '/profile',
    key: 'profile',
    exact: true,
    component: ProfileSettingsContainer
  },
  {
    path: '/subscription',
    key: 'subscription',
    exact: true,
    component: ManageSubscription
  },
  {
    path: '/fund/:handle/edit-gifting-page',
    key: 'editGiftingPage',
    exact: true,
    component: EditGiftingPageContainer
  },
  {
    path: '/:handle/create-family-update/:step(intro|examples|add-photos)',
    key: 'createFamilyUpdate',
    exact: true,
    component: CreateFamilyUpdateFlow
  },
  {
    path: '/:handle/family-update-editor/:step(edit-slides|share)',
    key: 'familyUpdateEditor',
    exact: true,
    component: FamilyUpdateEditorFlow
  },
  {
    path: '/u/:uuid',
    key: 'familyUpdateExample',
    exact: true,
    component: FamilyUpdateExample
  },
  {
    path: '/fund/:uuidOrHandle',
    key: 'fund',
    exact: true,
    component: FundContainer
  },
  {
    path: '/fund/:uuidOrHandle/account-info',
    key: 'accountInfo',
    exact: true,
    component: AccountInfo
  },
  {
    path: '/fund/:uuidOrHandle/backers-and-family-updates',
    key: 'backersAndFamilyUpdates',
    exact: true,
    component: FundBackersAndFamilyUpdatesPage
  },
  {
    path: '/fund/:uuidOrHandle/recent-activity',
    key: 'recentActivity',
    exact: true,
    component: FundRecentActivityPage
  },
  {
    path: '/fund/:handle/invite-manager',
    key: 'inviteManager',
    exact: true,
    component: InviteManager
  },
  {
    path: '/fund/:uuidOrHandle/transaction-history',
    key: 'fundTransactionHistory',
    exact: true,
    component: TransactionHistoryContainer
  },
  {
    path: '/fund/:uuidOrHandle/backers/:backerID',
    key: 'backerTransactionHistory',
    exact: true,
    component: TransactionHistoryContainer
  },
  {
    path: '/fund/:uuidOrHandle/relink-529',
    key: 'relink529Page',
    exact: true,
    component: Relink529Page
  },
  {
    path: '/mygifts/contribution/:contributionUUID',
    key: 'giftOfContribution',
    exact: true,
    component: GiftPayments
  },
  {
    path: '/profile/edit',
    key: 'editProfile',
    exact: true,
    component: EditProfileContainer
  },
  {
    path: '/profile/password',
    key: 'editPassword',
    exact: true,
    component: EditPassword
  },
  {
    path: '/profile/edit-payment-methods/:bankAccountUuid/verify',
    key: 'verifyBankAccount',
    exact: true,
    component: VerifyBankAccount
  },
  {
    path: '/profile/edit-payment-methods',
    key: 'editPaymentMethods',
    exact: true,
    component: EditPaymentMethods
  },
  {
    path: '/profile/add-payment-method',
    key: 'addPaymentMethod',
    exact: true,
    component: AddPaymentMethod
  },
  {
    path: '/profile/add-manual-bank-account',
    key: 'addManualBankAccount',
    exact: true,
    component: ManualBankAccount
  },
  {
    path: '/payment-methods',
    key: 'editPaymentMethodsRedirect',
    component: () => <Redirect to={buildPath('editPaymentMethods')} />
  },
  {
    path: '/profile/my-contributions',
    key: 'myContributions',
    exact: true,
    component: MyContributionsContainer
  },
  {
    path: '/myreferrals/',
    key: 'referrals',
    exact: true,
    component: Referrals
  },
  {
    path: '/withdrawals/:uuidOrHandle',
    key: 'initiateWithdrawal',
    exact: true,
    component: InitiateWithdrawal
  },
  {
    path: '/transaction-history',
    key: 'transactionHistory',
    exact: true,
    component: UserTransactionHistory
  },
  {
    path: '/onboarding/new/:step(collect-name|fund-name)?',
    key: 'onboardingRouterNewRedirect',
    component: () => {
      return (
        <Redirect
          to={{
            pathname: buildPath('initializeOnboarding'),
            state: {
              currentFlow: 'safe'
            }
          }}
        />
      )
    }
  },
  {
    path: '/onboarding/newest/:step(collect-name|fund-name)?',
    key: 'onboardingRouterNewestRedirect',
    component: () => {
      return (
        <Redirect
          to={{
            pathname: buildPath('initializeOnboarding'),
            state: {
              currentFlow: 'safe'
            }
          }}
        />
      )
    }
  },
  {
    path: '/onboarding/:step(recommendation|existingPlanName)?',
    key: 'enrollmentOrCypRedirect',
    component: (props: {
      match: { params: { step?: 'recommendation' | 'existingPlanName' } }
    }) => {
      const { step } = props.match.params

      const currentFlow = step === 'recommendation' || !step ? 'safeup' : 'cyp'

      return (
        <Redirect
          to={{
            pathname: buildPath('initializeOnboarding'),
            state: {
              currentFlow
            }
          }}
        />
      )
    }
  },
  {
    path: '/:handle/contribution/amount',
    key: 'oldFirstDepositMobileRedirect',
    component: (props: any) => {
      const { handle } = props.match.params
      return (
        <Redirect
          to={buildPath('contributionFlow', {
            handle,
            flow: 'create-contribution',
            step: 'amount'
          })}
        />
      )
    }
  },
  {
    path: '/contributions/create-starter-gift/none/fund/monthly',
    key: 'createStarterGiftMobileRedirect',
    component: () => {
      // NOTE: the handle value `in-progress` (NO_FUND_HANDLE) is used when
      //       the fund doesn't exist (starter gift).
      return (
        <Redirect
          to={buildPath('contributionFlow', {
            handle: NO_FUND_HANDLE,
            flow: 'create-starter-gift',
            step: 'amount'
          })}
        />
      )
    }
  },
  {
    path: '/fund/:uuidOrHandle',
    key: 'fundPageRedirect',
    exact: true,
    component: (props: any) => {
      const { uuidOrHandle } = props.match.params
      return (
        <Redirect to={buildPath('giftingPage', { handle: uuidOrHandle })} />
      )
    }
  },
  {
    path: '/:handle',
    key: 'giftingPage',
    exact: true,
    component: GiftingPageContainer
  }
  // WARNING - /:handle needs to be last route
] as const
// "as const" lets you create fully readonly objects, known as const assertion, we're forcing the type to the array of objects itself
// https://steveholgado.com/typescript-types-from-arrays/#const-assertions

const routes = [
  {
    path: '/maintenance/',
    key: 'maintenance',
    exact: true,
    component: Maintenance
  },
  {
    path: '/not-found',
    key: 'notFound',
    exact: true,
    component: NotFound
  },
  {
    path: '/register/email/',
    key: 'getStarted',
    exact: true,
    component: GetStarted
  },
  {
    path: '/signup/',
    key: 'signup',
    exact: true,
    component: GetStarted
  },
  {
    path: '/verify-email/:email',
    key: 'verifyEmail',
    exact: true,
    component: VerifyEmail
  },
  {
    path: '/register/passcode/',
    key: 'validatePasscode',
    exact: true,
    component: ValidatePasscode
  },
  {
    path: '/register/password/',
    key: 'createPassword',
    exact: true,
    component: CreatePassword
  },
  {
    path: '/login/email/',
    key: 'loginEmail',
    exact: true,
    component: LoginEmail
  },
  {
    path: '/signin/',
    key: 'signin',
    exact: true,
    component: LoginEmail
  },
  {
    path: '/login/password/',
    key: 'loginPassword',
    exact: true,
    component: LoginPassword
  },
  {
    path: '/login/auth-link-sent/',
    key: 'authLinkSent',
    exact: true,
    component: AuthLinkSent
  },
  {
    path: '/forgot-password',
    key: 'forgotPassword',
    exact: true,
    component: ForgotPassword
  },
  {
    path: '/reset-password/mobile',
    key: 'resetPasswordMobile',
    exact: true,
    component: ResetPassword
  },
  {
    path: '/reset-password',
    key: 'resetPassword',
    exact: true,
    component: ResetPassword
  },
  {
    path: '/authenticate/mobile/:token',
    key: 'authenticateMobile',
    exact: true,
    component: Authenticate
  },
  {
    path: '/authenticate/:token',
    key: 'authenticate',
    exact: true,
    component: Authenticate
  },
  {
    path: '/mobile-auth',
    key: 'mobileAuth',
    exact: true,
    component: MobileAuth
  },
  {
    path: '/contributions/update-contribution/:contributionId/fund/monthly',
    key: 'updateContributionMobileRedirect',
    component: MobileRedirect
  },
  {
    path: '/contributions/create-contribution/:fundUuid/fund/monthly',
    key: 'createContributionMobileRedirect',
    component: MobileRedirect
  },
  {
    path: '/finish-contribution-operation/:draftId/:redirection/:usesCreditCard/:intentId/:methodId/:fundUuid/:pendingPaymentMethod',
    key: 'finishContributionOperation',
    exact: true,
    component: FinishContributionOperation
  },
  {
    path: '/:handle/:flow(create-contribution|update-contribution|create-starter-gift|create-gift|update-gift|enrollment-contribution)/:step(amount|beneficiary|collect-name|select-fund|signup|returning-guest|message|add-payment-method|select-payment-method|confirmation|update)/:contributionId?',
    key: 'contributionFlow',
    exact: true,
    component: ContributionFlow
  },
  {
    path: '/follow/not-found',
    key: 'followerNotFound',
    exact: true,
    component: NotFound
  },
  {
    path: '/follow/:handle/',
    key: 'followerRouter',
    exact: true,
    component: FollowerContainer
  },
  {
    path: '/follow/:handle/:step(ask-email|name|photo)',
    key: 'follower',
    exact: true,
    component: FollowerContainer
  },
  {
    path: '/faq',
    key: 'faq',
    exact: true,
    component: () => {
      window.location.href = 'https://backer.com/faq'
      return null
    }
  },
  {
    path: '/gifts',
    key: 'marketingGifts',
    exact: true,
    component: () => {
      window.location.href = `${config.BACKEND_ROOT_URL}/gifts`
      return null
    }
  },
  {
    path: '/close-in-app-browser',
    key: 'mobileAppCloseInAppBrowser',
    exact: true,
    component: MobileAppCloseInAppBrowser
  },
  {
    path: '/',
    key: 'APP',
    component: (props: any) => {
      if (!isAuthenticated()) {
        const { routes, location } = props

        const match = routes.find((route: any) => {
          const regexp = pathToRegexp(route.path)
          return regexp.test(location.pathname)
        })

        if (match && match.key !== 'giftingPage') {
          Cookies.set('redirectTo', location.pathname)
          return <Redirect to={buildPath('signin')} />
        } else {
          const handleArray = location.pathname.split('/')
          const handle = handleArray[handleArray.length - 1]
          return <GiftingPageContainer handleProp={handle} />
        }
      } else {
        return <RenderRoutes {...props} />
      }
    },
    routes: protectedRoutes
  }
] as const

/**
 * Render a route with potential sub routes
 * A special wrapper for <Route/> that knows how to handle “sub”-routes
 * by passing them in a routes prop to the component it renders.
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  )
}

export const historyRef: React.MutableRefObject<any> = createRef()

/**
 * Use this component for any new section of routes (any config object that has a 'routes' property
 */
export function RenderRoutes({ routes }: any) {
  const history = useHistory()
  historyRef.current = history
  return (
    <Switch>
      {routes.map((route: any) => {
        return <RouteWithSubRoutes key={route.key} {...route} />
      })}
      <Route component={NotFound} />
    </Switch>
  )
}

const allRoutes = [...routes, ...protectedRoutes]

// we get the type of Key directly from the "key" value from the routes and protectedRoutes arrays in order
// to restrict the values that can be passed into our buildPath function
// i.e. type Key = 'maintenance' | 'notFound' | 'getStarted' ...
// https://steveholgado.com/typescript-types-from-arrays/#arrays-of-objects
type Key =
  | (typeof routes)[number]['key']
  | (typeof protectedRoutes)[number]['key']

export function buildPath(key: Key, params?: any) {
  const route = allRoutes.find((r) => r.key === key)

  return generatePath(ensure(route?.path), params)
}

function findDuplicates(arr: any[]) {
  return filter(arr, (value, i, iteratee) => includes(iteratee, value, i + 1))
}

const duplicates = findDuplicates(allRoutes.map((route) => route.key))

if (duplicates.length > 0) {
  throw new TypeError(`You cannot have duplicate route keys: ${duplicates}`)
}

export default routes
