import { Button } from '@collegebacker/backer-ui/ui'
import { Component } from 'react'
import { copyTextToClipboard, generateClasses } from 'utils/helpers'
import './CopyLinkButton.scss'
export class CopyLinkButton extends Component<Props, State> {
  private resetButton!: ReturnType<typeof setTimeout>
  state: State = { label: 'Copy Link' }

  componentDidMount = () => {
    if (this.props.label) {
      this.setState({
        label: this.props.label
      })
    }
  }
  componentWillUnmount = () => {
    if (this.resetButton) {
      clearTimeout(this.resetButton)
    }
  }

  handleClick = () => {
    const { link, copied, track } = this.props

    copyTextToClipboard(link)

    this.setState({ label: 'Copied' }, () => {
      copied && copied()
      this.resetButton = setTimeout(() => {
        this.setState({ label: this.props.label || 'Copy Link' })
      }, 5000)
    })
    track && track()
  }

  render() {
    const { modifier } = this.props
    return (
      <Button
        onClick={this.handleClick}
        label={this.state.label}
        className={generateClasses('copy-link-button', modifier)}
        mode="outline"
        size="small"
        icon="link-large"
        hasMinWidth={false}
      />
    )
  }
}

interface Props {
  link: string
  modifier?: string
  label?: string
  copied?: () => void
  track?: () => void
  id?: string
}

interface State {
  label?: string
}
