export function sendToMobileApp(action: string, ...params: any[]) {
  // publish to webview_flutter
  const payload = `${action}:${params.join(':')}`
  const root = window as any
  const app = root.ReactNativeWebView || root.MobileApp
  if (app) {
    app.postMessage(payload)
  }
}

export function sendJsonToMobileApp(action: string, params: Object) {
  sendToMobileApp(action, JSON.stringify(params))
}

// append ?cb-out or &cb-out at the end of the url
// this does nothing on desktop but on mobile
// it's read by the app that will open the link
// in a new browser page outside the app
export function linkOutOfApp(url: string) {
  return addQueryParam(url, 'cb-out')
}

// append ?rt query param to ask the web page
// to redirect the user to that same page
// after the user is authenticated
export function returnAfterLogin(url: string) {
  return addQueryParam(url, 'rt')
}

// returns a specially crafted url to this same page that:
// 1) forces the mobile app to open the page in a separate browser
// 2) skips the deep link mechanism to avoid returning to the mobile app
// 3) once the user lands there, if the they are not yet authenticated
//    it will redirect back to that same page after authentication
export function linkOutOfAppForCurrentPage() {
  let mobileLinkOut = ''

  if (typeof window !== 'undefined') {
    const pathWithQuery = window.location.pathname + window.location.search
    // adding 'web.' avoids falling into deep links
    const fullPath = 'https://web.collegebacker.com' + pathWithQuery
    mobileLinkOut = returnAfterLogin(linkOutOfApp(fullPath))
  }

  return mobileLinkOut
}

// make sure the internal link points to `web.collegebacker.com`
// to avoid using deep links
export function skipDeepLink(url: string) {
  if (url.startsWith('https://collegebacker.com')) {
    return 'https://web.' + url.substr(8)
  } else if (url.startsWith('https://web.collegebacker.com')) {
    return url
  } else {
    console.error('skipDeepLink: unexpected url format', url)
    return url
  }
}

function addQueryParam(url: string, name: string) {
  const hasQueryAlready = url.indexOf('?') !== -1
  const operator = hasQueryAlready ? '&' : '?'
  return `${url}${operator}${name}`
}
