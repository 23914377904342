import gql from 'graphql-tag'

const fragments = {
  giftingPage: gql`
    fragment GiftingPageFragment on GiftingPage {
      id
      photoUrl
      handle
      description
      fundName
    }
  `,
}

export default fragments
