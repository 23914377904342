export const RELATIONSHIPS = [
  {
    label: 'Mother',
    value: 'mother'
  },
  {
    label: 'Father',
    value: 'father'
  },
  {
    label: 'Guardian',
    value: 'guardian'
  },
  {
    label: 'Grandmother',
    value: 'grandmother'
  },
  {
    label: 'Grandfather',
    value: 'grandfather'
  },
  {
    label: 'Uncle',
    value: 'uncle'
  },
  {
    label: 'Aunt',
    value: 'aunt'
  },
  {
    label: 'Parent',
    value: 'parent'
  },
  {
    label: 'Friend',
    value: 'friend'
  },
  {
    label: 'Other',
    value: 'other'
  }
] as const

export type Relationship = (typeof RELATIONSHIPS)[number]['value']
