import { useState, useEffect, useRef, FormEvent, useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  Input,
  Button,
  Toast,
  Stack,
  SwitchSelector,
  Navbar,
  WarnMesssage
} from '@collegebacker/backer-ui/ui'
import { pushConfirmation } from 'utils'
import { SessionContext } from 'context'

import { buildPath } from 'routes'
import { GET_PAYMENT_METHODS } from './EditPaymentMethods'
import { getQuery } from 'hooks'
import { TSelectedPlan, mapSelectedPlanToInput } from './SelectSubscription'
import { Loading } from 'components'
import { UserSubscriptionDates } from '../userInterfaces'
import { getSubscriptionModalText } from './ManageSubscription'
import userFragments from 'modules/profile/graphqlFragments'

import styles from './ManualBankAccount.module.scss'

const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount($input: CreateBankAccountInputType!) {
    createBankAccount(input: $input) {
      id
    }
  }
`
const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      subscriptionPaidUntil
      subscriptionDueOn
      pricingPlan
    }
  }
`
const UPDATE_USER_PRICING_PLAN = gql`
  mutation UpdateUserPricingPlan($input: UpdateUserPricingPlanInputType!) {
    updateUserPricingPlan(input: $input) {
      id
      email
      ...SubscriptionFragmentForEnrollment
    }
  }
  ${userFragments.subscriptionForEnrollment}
`

export const ManualBankAccount = () => {
  const toastRef = useRef<ToastRef>(null)
  const context = useContext(SessionContext)
  const history = useHistory()
  const queryParams = getQuery()
  const selectedPricingPlan = queryParams.get('selectedPricingPlan')

  const [createBankAccount, { loading }] = useMutation(CREATE_BANK_ACCOUNT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PAYMENT_METHODS }]
  })

  const [updateUserPricingPlan, { loading: loadingUpdateUserPricingPlan }] =
    useMutation(UPDATE_USER_PRICING_PLAN)
  const [error, setError] = useState('')
  const [accountHolder, setAccountHolder] = useState('')
  const [accountHolderError, setAccountHolderError] = useState('')
  const [accountType, setAccountType] = useState('CHECKING')
  const [accountNumber, setAccountNumber] = useState('')
  const [accountNumberError, setAccountNumberError] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [routingNumberError, setRoutingNumberError] = useState('')

  const {
    loading: userLoading,
    data: userData,
    error: fetchUserError
  } = useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (error) {
      toastRef.current?.showToast(error, {
        type: 'error',
        closeOnClick: false,
        showCloseIcon: true
      })
    }
  }, [error])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const input = normalize({
      accountHolder,
      accountType,
      accountNumber,
      routingNumber
    })
    const errors = validate(input)
    setAccountHolderError(errors['accountHolder'])
    setAccountNumberError(errors['accountNumber'])
    setRoutingNumberError(errors['routingNumber'])

    if (isEmpty(errors)) {
      createBankAccount({ variables: { input } })
        .then((res) => {
          if (selectedPricingPlan) {
            updateUserPricingPlan({
              variables: {
                input:
                  mapSelectedPlanToInput[selectedPricingPlan as TSelectedPlan]
              }
            })
              .then((response) => {
                const userDates: UserSubscriptionDates = response.data.updateUserPricingPlan
                history.push({
                  pathname: buildPath('dashboard'),
                  state: {
                    subscriptionModalText: getSubscriptionModalText({
                      pricingPlan,
                      selectedPlan: selectedPricingPlan as TSelectedPlan,
                      subscriptionPaidUntil: userDates.subscriptionPaidUntil,
                      subscriptionDueOn: userDates.subscriptionDueOn
                    })
                  }
                })
              })
              .catch((e) => {
                setError(e.message)
                console.error(e)
              })
            return
          } else {
            const confirmation = encodeURI(
              JSON.stringify({
                customMessage:
                  'New bank account payment method was successfully added.',
                type: 'custom',
                params: {
                  emoji: '🎉',
                  type: 'success',
                  closeOnClick: true,
                  timeout: 3000
                }
              })
            )

            pushConfirmation(context, confirmation)
            history.replace(buildPath('editPaymentMethods'))
          }
        })
        .catch((error) => setError(JSON.stringify(error, null, 4)))
    }
  }
  const currentUser = get(userData, 'currentUser')
  const { pricingPlan } = currentUser || {}

  if (userLoading) return <Loading />
  if (fetchUserError)
    return (
      <WarnMesssage
        className="mb-40"
        text={fetchUserError.message}
        type="warning"
      />
    )

  return (
    <>
      <Navbar backButtonClick={() => history.goBack()} />
      <div
        style={{
          opacity: loading ? 0.5 : 1
        }}
        className={styles.container}
      >
        <form className={styles.body} onSubmit={handleSubmit}>
          <Toast ref={toastRef} />
          <h1 className="typo-app-title-medium-adaptive">
            Manually enter your banking info
          </h1>
          <p>Account type:</p>
          <SwitchSelector
            options={[
              {
                label: 'Checking account',
                value: 'CHECKING'
              },
              {
                label: 'Savings account',
                value: 'SAVINGS'
              }
            ]}
            onChange={(option) =>
              setAccountType(option.value as 'CHECKING' | 'SAVINGS')
            }
            className={styles.switchSelector}
          />
          <Stack spacing={30}>
            <Stack spacing={15}>
              <Input
                name="accountHolder"
                label="Full name on your account"
                value={accountHolder}
                noAutocomplete={true}
                autoFocus={true}
                onChange={(event: any) =>
                  setAccountHolder(event.currentTarget.value)
                }
                errorMessage={accountHolderError}
              />
              <Input
                name="routingNumber"
                helperText="9 characters long"
                label="Routing number"
                value={routingNumber}
                pattern="[0-9]*"
                maxLength={9}
                cleaveOptions={{
                  numericOnly: true
                }}
                noAutocomplete={true}
                onChange={(event: any) =>
                  setRoutingNumber(event.currentTarget.value)
                }
                errorMessage={routingNumberError}
              />
              <Input
                name="accountNumber"
                label="Account number"
                value={accountNumber}
                pattern="[0-9]*"
                noAutocomplete={true}
                onChange={(event: any) =>
                  setAccountNumber(event.currentTarget.value)
                }
                errorMessage={accountNumberError}
                maxLength={17}
                cleaveOptions={{
                  numericOnly: true
                }}
              />
            </Stack>
            <Button
              type="submit"
              label="Add payment method"
              maxWidth={335}
              disabled={loading || loadingUpdateUserPricingPlan}
            />
          </Stack>
        </form>
      </div>
    </>
  )
}

function validate({
  accountHolder,
  accountType,
  accountNumber,
  routingNumber
}: any) {
  const errors: any = {}

  if (isEmpty(accountHolder))
    errors['accountHolder'] = 'Account full name is required'
  if (isEmpty(accountType)) errors['accountType'] = 'Account type is required'
  if (isEmpty(accountNumber))
    errors['accountNumber'] = 'Account number is required'
  if (accountNumber.length < 6)
    errors['accountNumber'] = 'Account number must be at least 6 digits'
  if (accountNumber.length > 17)
    errors['accountNumber'] = 'Account number cannot be more than 17 digits'
  if (isEmpty(routingNumber))
    errors['routingNumber'] = 'Routing number is required'
  else if (routingNumber.length !== 9)
    errors['routingNumber'] = 'Routing number must be 9 digits'
  else if (!validRoutingNumber(routingNumber))
    errors['routingNumber'] = 'Routing number is invalid'

  return errors
}

// Validate the routing number (ABA). See here for more info: http://www.brainjar.com/js/validation/
// This is adapted from nacha2 npm package
function validRoutingNumber(routing: string) {
  // Split the routing number into an array of numbers. `array` will look like this: `[2,8,1,0,8,1,4,7,9]`.
  const array = routing.split('').map(Number)

  const sum =
    3 * (array[0] + array[3] + array[6]) +
    7 * (array[1] + array[4] + array[7]) +
    1 * (array[2] + array[5] + array[8])

  // Throw an error if the the result of `sum` modulo 10 is not zero.
  // The value of `sum` must be a multiple of 10 to be a valid routing number.
  if (sum % 10 !== 0) return false

  return true
}

function normalize(data: any) {
  return {
    accountHolder: data.accountHolder ? data.accountHolder.trim() : '',
    accountType: data.accountType,
    accountNumber: data.accountNumber
      ? data.accountNumber.replace(/[^0-9]/g, '').trim()
      : '',
    routingNumber: data.routingNumber
      ? data.routingNumber.replace(/[^0-9]/g, '').trim()
      : ''
  }
}
