import * as FullStory from '@fullstory/browser'

export const setupFullStory = (config: any) => {
  if (!config.FULL_STORY_ORG_ID) return

  FullStory.init({ orgId: config.FULL_STORY_ORG_ID })
  FullStory.shutdown()

  detectUrlChange((path: string) => {
    if (/contribution/i.test(path)) {
      // https://developer.fullstory.com/restart-recording
      FullStory.restart()
    } else {
      // https://developer.fullstory.com/stop-recording
      FullStory.shutdown()
    }
  })
}

const detectUrlChange = (then: Function) => {
  const observer = new MutationObserver(() => {
    if (window.location.href !== localStorage.previousUrl) {
      localStorage.previousUrl = window.location.href
      then(window.location.pathname)
    }
  })

  observer.observe(window.document, { subtree: true, childList: true })
}
