import gql from 'graphql-tag'

import fdFragments from 'modules/contribution/giftingPageFragment'

const bankAccount = gql`
  fragment BankAccountFragment on BankAccount {
    id
    accountHolder
    accountLabel
    accountNumber
    accountType
    bankLabel
    bankName
    latestBalance
    name
    plaidToken
    plaidVerificationStatus
  }
`

const creditCard = gql`
  fragment CreditCardFragment on CreditCard {
    id
    last4
    cardHolder
    expiration
    bankLabel
    accountLabel
    selectable
    stripePaymentMethod
  }
`

const contributionDraft = gql`
  fragment ContributionDraftFragment on ContributionDraft {
    id
    currentFlow
    currentStep
    frequency
    amount
    activationDate
    firstDay
    secondDay
    beneficiaryName
    parentName
    parentEmail
    gifterName
    gifterEmail
    giftMessage
    gifterRelationship
    tip
    processingFee
    firstGiftToFund
    digitalWallet
    digitalWalletType
    bankAccount {
      ...BankAccountFragment
    }
    creditCard {
      ...CreditCardFragment
    }
    user {
      id
      uuid
      email
      firstName
      middleName
      streetAddress
      streetAddress2
      city
      state
      zipCode
      lastName
      variant
      needName
      needAddress
      status
      bankAccounts {
        ...BankAccountFragment
      }
      creditCards {
        ...CreditCardFragment
      }
    }
    fundDraft {
      id
      currentFlow
    }
    fund {
      id
      uuid
      first
      collegeEntryYear
      yearsUntilCollege
      fullName
      fundName
      settledBalance
      pendingBalance
      prebirth
      handle
      savingsSummary {
        id
        hasContributions
        totalProjectedInDollars
      }
      fundOwner {
        id
        fullName
        firstName
        uuid
      }
      giftingPage {
        ...GiftingPageFragment
      }
    }
    contribution {
      state
      id
    }
  }
  ${bankAccount}
  ${creditCard}
  ${fdFragments.giftingPage}
`

const user = gql`
  fragment ContributionUserFragment on User {
    id
    uuid
    firstName
    lastName
    fullName
    email
    guest
    hasPassword
    defaultPaymentMethodId
    defaultPaymentMethodType
    solicitorTerms
    streetAddress
    streetAddress2
    city
    state
    zipCode

    bankAccounts {
      ...BankAccountFragment
    }

    creditCards {
      ...CreditCardFragment
    }

    followedFunds {
      id
    }
  }
  ${bankAccount}
  ${creditCard}
`

const managedContribution = gql`
  fragment ManagedContributionFragment on Contribution {
    id
    amount
    frequency
    nextPaymentDate
    recipientName
    state
    bankAccount {
      id
      plaidVerificationStatus
    }
    user {
      id
    }

    fund {
      id
      fundName
      photo
      canUpdateFund
      handle
    }
  }
`

const fragments = { bankAccount, creditCard, contributionDraft, user, managedContribution }

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    currentUser {
      id
      countFunds
      defaultPaymentMethodType
      defaultPaymentMethodId
      canContributeWithCreditCard
      hasPaymentMethods
      status
      needName
      needAddress
      streetAddress
      city
      state
      zipCode
      bankAccounts {
        ...BankAccountFragment
      }
      creditCards {
        ...CreditCardFragment
      }
    }
  }
  ${bankAccount}
  ${creditCard}
`

export default fragments
