export const scrollTo = (target: string, offsetSelector?: string, e?: any) => {
  let scroll
  if (typeof window !== 'undefined') {
    const SmoothScroll = require('smooth-scroll/dist/smooth-scroll.polyfills.min.js')
    scroll = new SmoothScroll()
  }

  e && e.preventDefault()
  const element: HTMLDataElement | null = document.querySelector(target)
  if (!element) return
  const fixedNavbar: HTMLElement | null = offsetSelector ? 
    document.querySelector(offsetSelector) :
    null
  const yPosition =
    (element ? element.offsetTop : 0) -
    (fixedNavbar ? fixedNavbar.getBoundingClientRect().height : 0)
  scroll.animateScroll(yPosition)
}

export const jumpTo = (target: string, offsetSelector: string, e?: any) => {
  if (typeof window === 'undefined') return
  e && e.preventDefault()

  const element: HTMLDataElement | null = document.querySelector(target)
  if (!element) return

  const fixedNavbar: HTMLElement | null = document.querySelector(offsetSelector)
  if (!fixedNavbar) return

  const yPosition =
    element.offsetTop -
    (fixedNavbar ? fixedNavbar.getBoundingClientRect().height : 0)

  document.body.scrollTop = document.documentElement.scrollTop = yPosition
}

export { scrollTo as default }

export const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
}

export function removeBodyStyleAttributes() {
  // workaround to remove style attributes set by the Modal component
  // @TODO - Pavel to make more permanent fix directly in Modal component
  const body = document.body
  body.style.overflow = ''
  body.style.touchAction = ''
}
