import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { isNumber } from 'lodash'
import {
  Button,
  Icon,
  LabelTag,
  Modal,
  Stack
} from '@collegebacker/backer-ui/ui'
import { removeBodyStyleAttributes } from 'utils'
import { buildPath } from 'routes'
import { PricingPlan, SubscriptionFrequency } from '../userInterfaces'
import purpleCircle from 'static/img/purple-circle.svg'
import whiteTickmarkInPurpleCircle from 'static/img/white-tickmark-in-purple-circle.svg'
import { isPro } from 'utils/user'
import { TSelectedPlan, mapSelectedPlanToInput } from './SelectSubscription'
import userFragments from 'modules/profile/graphqlFragments'

import styles from './GetSubscription.module.scss'

const UPDATE_USER_PRICING_PLAN = gql`
  mutation UpdateUserPricingPlan($input: UpdateUserPricingPlanInputType!) {
    updateUserPricingPlan(input: $input) {
      id
      email
      ...SubscriptionFragmentForEnrollment
    }
  }
  ${userFragments.subscriptionForEnrollment}
`

const GetSubscription = ({
  onSuccess,
  onError,
  override,
  ctaLabel,
  showFreeButton,
  currentUser,
  selectedPlan,
  setSelectedPlan
}: GetSubscriptionProps) => {
  const history = useHistory()
  const { fundUuid }: { fundUuid: string } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [updateUserPricingPlan, { loading }] = useMutation(
    UPDATE_USER_PRICING_PLAN
  )
  const { pricingPlan, countEstablishedFunds, funds } = currentUser || {}

  const canStayOnFree =
    isNumber(countEstablishedFunds) && countEstablishedFunds === 0

  const monthlySelected = selectedPlan === 'monthlyPro'
  const annualSelected = selectedPlan === 'annualPro'

  const otherFundName = funds?.find((f) => f.uuid !== fundUuid)?.fundName

  return (
    <Stack spacing={30}>
      <Stack spacing={20}>
        <h1
          className="typo-app-title-medium-adaptive"
          style={{ marginBottom: 0 }}
        >
          Unlock Pro features
        </h1>

        {isNumber(countEstablishedFunds) &&
        countEstablishedFunds > 0 &&
        otherFundName ? (
          <p className="typo-app-body-paragraph">
            You already have a 529 plan connected to {otherFundName}. Upgrade to
            Backer Pro to connect multiple plans.
          </p>
        ) : (
          <p className="typo-app-body-large-adaptive">
            Get peace of mind when you save and invest in your kid's future.
          </p>
        )}
      </Stack>
      <div className={styles.content}>
        <div className={styles.cards}>
          <div className={styles.annualCardContainer}>
            <LabelTag
              label="Best value"
              color="velvet"
              className={styles.labelTag}
              size="small"
            />
            <button
              onClick={() => setSelectedPlan('annualPro')}
              className={classNames(styles.card, styles.cardAnnual, {
                [styles.cardSelected]: annualSelected
              })}
            >
              <div className={styles.cardLeft}>
                <h2 className="typo-app-body-large">Annual</h2>
                <p
                  className={classNames(
                    'typo-app-body-caption',
                    styles.highlight
                  )}
                >
                  Save 32%
                </p>
              </div>
              <div className={styles.cardRight}>
                <p
                  className={classNames(
                    'typo-app-body-large',
                    styles.amountGap
                  )}
                >
                  <span className={styles.strikethrough}>$6</span>$4
                  <span className="typo-app-body-caption">/ per month</span>
                </p>
                {annualSelected ? (
                  <img src={whiteTickmarkInPurpleCircle} alt="" />
                ) : (
                  <img src={purpleCircle} alt="" />
                )}
              </div>
            </button>
          </div>
          <button
            onClick={() => setSelectedPlan('monthlyPro')}
            className={classNames(styles.card, {
              [styles.cardSelected]: monthlySelected
            })}
          >
            <div className={styles.cardLeft}>
              <h2 className="typo-app-body-large">Monthly</h2>
              <p className="typo-app-body-caption">First 7 days free</p>
            </div>
            <div className={styles.cardRight}>
              <p
                className={classNames('typo-app-body-large', styles.amountGap)}
              >
                $6
                <span className="typo-app-body-caption">/ per month</span>
              </p>
              {monthlySelected ? (
                <img src={whiteTickmarkInPurpleCircle} alt="" />
              ) : (
                <img src={purpleCircle} alt="" />
              )}
            </div>
          </button>
        </div>
        <div className={styles.features}>
          <div className={styles.left}>
            <p
              className={classNames('typo-app-body-caption', styles.lightLabel)}
            >
              What you get
            </p>
            <ul className={styles.list}>
              <li className="typo-app-body-caption">A top-rated 529 plan</li>
              <li className="typo-app-body-caption">Gifters pay no fees</li>
              <li className="typo-app-body-caption">Up to five 529 accounts</li>
              <li className="typo-app-body-caption">
                Priority customer support
              </li>
            </ul>
          </div>
          <div className={styles.right}>
            <div className={styles.column}>
              <p className="typo-app-body-caption">Free</p>
              <ul className={styles.listIcons}>
                <li>
                  <Icon name="tickmark-in-circle" />
                </li>
                <li>
                  <Icon name="close-circle" color="#B9B9B9" />
                </li>
                <li>
                  <Icon name="close-circle" color="#B9B9B9" />
                </li>
                <li>
                  <Icon name="close-circle" color="#B9B9B9" />
                </li>
              </ul>
            </div>
            <div className={styles.column}>
              <p className="typo-app-body-caption">Pro</p>
              <ul className={styles.listIcons}>
                <li>
                  <Icon name="tickmark-in-circle" />
                </li>
                <li>
                  <Icon name="tickmark-in-circle" />
                </li>
                <li>
                  <Icon name="tickmark-in-circle" />
                </li>
                <li>
                  <Icon name="tickmark-in-circle" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <p
          className={classNames(
            'typo-app-body-paragraph',
            styles.ctaHelperText
          )}
        >
          Recurring billing. Cancel any time.
        </p>
        <div className={styles.ctaContainer}>
          <Button
            disabled={loading}
            label={ctaLabel || 'Get Backer Pro'}
            onClick={() => {
              if (override) {
                override()
              } else {
                updateUserPricingPlan({
                  variables: {
                    input: mapSelectedPlanToInput[selectedPlan]
                  }
                })
                  .then(() => {
                    onSuccess && onSuccess()
                  })
                  .catch((e) => {
                    console.log(e)
                    onError && onError(e)
                  })
              }
            }}
            minWidth={335}
          />

          {showFreeButton && (
            <>
              <Button
                label="I'll stick with free for now"
                minWidth={335}
                mode="ghost"
                className={styles.buttonGhost}
                onClick={() => {
                  canStayOnFree
                    ? setIsOpen(true)
                    : history.replace(buildPath('dashboard'))
                }}
              />
              <Modal
                isOpen={isOpen}
                onCloseClick={() => {
                  setIsOpen(false)
                }}
                isBottomSheet={true}
              >
                <Stack spacing={40}>
                  <div>
                    <h2 className="typo-app-title-small">Free tier</h2>
                    <Stack spacing={20}>
                      <p className="typo-app-body-paragraph">
                        Get all the Backer basics for free.
                      </p>
                      <div className={styles.row}>
                        <Icon name="tickmark-in-circle" />
                        <p className="typo-app-body-main">One 529 account</p>
                      </div>
                      <div className={styles.row}>
                        <Icon name="tickmark-in-circle" />
                        <p className="typo-app-body-main">
                          Gifters pay $1.99 per gift
                        </p>
                      </div>
                      <div className={styles.row}>
                        <Icon name="tickmark-in-circle" />
                        <p className="typo-app-body-main">
                          A top-rated 529 plan
                        </p>
                      </div>
                    </Stack>
                  </div>
                  <Stack spacing={20}>
                    <p className="typo-app-body-paragraph">
                      Try Backer Pro for the full experience.
                    </p>
                    <div className={styles.ctaContainer}>
                      <Button
                        label="Continue with Backer Pro"
                        onClick={() => {
                          setIsOpen(false)
                        }}
                        minWidth={335}
                      />
                      <Button
                        disabled={loading}
                        label="Downgrade to free"
                        minWidth={335}
                        mode="outline"
                        onClick={() => {
                          setIsOpen(false)
                          if (isPro({ pricingPlan })) {
                            updateUserPricingPlan({
                              variables: {
                                input: {
                                  pricingPlan: 'backer-free'
                                }
                              }
                            })
                              .then(() => {
                                console.log('Downgrade to free tier')
                                onSuccess && onSuccess()
                                removeBodyStyleAttributes()
                              })
                              .catch((e) => {
                                console.log(e)
                                onError && onError(e)
                              })
                          } else {
                            console.log('Already on free tier')
                            onSuccess && onSuccess()
                            removeBodyStyleAttributes()
                          }
                        }}
                      />
                    </div>
                  </Stack>
                </Stack>
              </Modal>
            </>
          )}
        </div>
      </div>
    </Stack>
  )
}

export default GetSubscription

interface GetSubscriptionProps {
  onSuccess?: () => void
  onError?: (err: Error) => void
  fundName?: string
  override?: () => void
  ctaLabel?: string
  showFreeButton?: boolean
  currentUser: {
    countEstablishedFunds: number
    pricingPlan: PricingPlan
    subscriptionFrequency: SubscriptionFrequency
    funds: {
      id: string
      fundName: string
      uuid: string
    }[]
  }
  selectedPlan: TSelectedPlan
  setSelectedPlan: (plan: TSelectedPlan) => void
}
