import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

import { Loading } from 'components'
import { startContributionUrl } from '../helpers'

const FETCH_CONTRIBUTION = gql`
  query Contribution($input: ContributionInputType!) {
    contribution(input: $input) {
      id
      fund {
        id
        handle
      }
    }
  }
`

const FETCH_FUND = gql`
  query Fund($input: FundInputType!) {
    fund(input: $input) {
      id
      handle
    }
  }
`

interface MobileRedirectParams {
  contributionId: string
  fundUuid: string
}

const MobileRedirect = ({ }) => {
  const params = useParams<MobileRedirectParams>()
  const input = params.fundUuid ?
    { uuid: params.fundUuid }
    :
    { contributionId: params.contributionId }
  const { data, loading, error } = useQuery(
    params.fundUuid ?
      FETCH_FUND
      :
      FETCH_CONTRIBUTION,
    { variables: { input } }
  )

  if (loading) { return <Loading /> }

  if (data) {
    if (params.fundUuid) {
      return (<Redirect
        to={startContributionUrl({
          handle: get(data, 'fund.handle'),
        })}
      />)
    } else {
      return (<Redirect
        to={startContributionUrl({
          handle: get(data, 'contribution.fund.handle'),
          flow: 'update-contribution',
          contributionId: Number(params.contributionId)
        })}
      />)
    }
  } else {
    console.error(error)
  }
}

export default MobileRedirect
