import React from 'react'
import { useHistory } from 'react-router-dom'
import { generateClasses } from 'utils/index'
import arrowLeftIcon from 'static/img/arrow/arrow-left.svg'
import './BackButtonHeader.scss'

export const BackButtonHeader: React.FunctionComponent<Props> = ({
  className,
  title
}) => {
  const history = useHistory()
  return (
    <div className={generateClasses('back-button-header', className)}>
      <button
        className={generateClasses('back-button-header__button', className)}
        onClick={() => history.goBack()}
      >
        <img className="back-button-header__img" src={arrowLeftIcon} alt="Back" />
      </button>
      <p className="back-button-header__title">{title}</p>
    </div>
  )
}

interface Props {
  className?: string
  title: string
}
