import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import {
  Button,
  Navbar,
  Toast,
  WarnMesssage
} from '@collegebacker/backer-ui/ui'

import { NewPaymentMethod } from 'modules/contribution/components/NewPaymentMethod'
import { PaymentMethodType } from 'modules/contribution/types'
import { TabPage } from 'components/TabPage'
import { LinkBankAccount } from 'modules/contribution/components/LinkBankAccount'
import { pushConfirmation } from 'utils'
import { SessionContext } from 'context'
import { buildPath } from 'routes'
import { useBackNavigation } from 'hooks/useBackNavigation'
import { getQuery } from 'hooks'
import { AddStripeCard } from 'modules/contribution/components/AddStripeCard'
import fragments from 'modules/contribution/graphqlFragments'
import { Loading } from 'components'
import { SubscriptionHeader } from './SubscriptionHeader'
import { TSelectedPlan, mapSelectedPlanToInput } from './SelectSubscription'
import { getSubscriptionModalText } from './ManageSubscription'
import { UserSubscriptionDates } from '../userInterfaces'
import userFragments from 'modules/profile/graphqlFragments'

import styles from './AddPaymentMethod.module.scss'

const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($input: UpdateCurrentUserInputType!) {
    updateCurrentUser(input: $input) {
      ...ContributionUserFragment
    }
  }
  ${fragments.user}
`
const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...ContributionUserFragment
      subscriptionPaidUntil
    }
  }
  ${fragments.user}
`

const UPDATE_USER_PRICING_PLAN = gql`
  mutation UpdateUserPricingPlan($input: UpdateUserPricingPlanInputType!) {
    updateUserPricingPlan(input: $input) {
      id
      email
      ...SubscriptionFragmentForEnrollment
    }
  }
  ${userFragments.subscriptionForEnrollment}
`



export const AddPaymentMethod = () => {
  const context = useContext(SessionContext)
  const history = useHistory()
  const queryParams = getQuery()
  const selectedPricingPlan = queryParams.get('selectedPricingPlan')

  const {
    loading,
    data: userData,
    error: fetchUserError
  } = useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only'
  })

  const [updateCurrentUser, { loading: loadingUpdateCurrentUser }] =
    useMutation(UPDATE_CURRENT_USER)

  const [updateUserPricingPlan, { loading: loadingUpdateUserPricingPlan }] =
    useMutation(UPDATE_USER_PRICING_PLAN)

  const [error, setError] = useState('')
  const [view, setView] = useState<PaymentMethodType>('bank-account')
  const [activeTab, setActiveTab] = useState<0 | 1>(0)

  const backButtonClick = useBackNavigation()

  useEffect(() => {
    if (view === 'bank-account') {
      setActiveTab(0)
    } else if (view === 'credit-card') {
      setActiveTab(1)
    }
  }, [view])

  const toastRef = useRef<ToastRef>(null)

  useEffect(() => {
    if (error !== '' && toastRef.current) {
      toastRef.current?.showToast(error, {
        type: 'error',
        closeOnClick: false,
        showCloseIcon: true,
        onClose: () => setError('')
      })
    }
  }, [error])

  const currentUser = get(userData, 'currentUser')
  const { pricingPlan } = currentUser || {}

  if (loading) return <Loading />
  if (fetchUserError)
    return (
      <WarnMesssage
        className="mb-40"
        text={fetchUserError.message}
        type="warning"
      />
    )

  const onSuccess = (paymentMethodType: 'bank account' | 'credit card') => {
    if (selectedPricingPlan) {
      updateUserPricingPlan({
        variables: {
          input: mapSelectedPlanToInput[selectedPricingPlan as TSelectedPlan]
        }
      })
        .then((response) => {
          const userDates: UserSubscriptionDates = response.data.updateUserPricingPlan
          history.push({
            pathname: buildPath('dashboard'),
            state: {
              subscriptionModalText: getSubscriptionModalText({
                pricingPlan,
                selectedPlan: selectedPricingPlan as TSelectedPlan,
                subscriptionPaidUntil: userDates.subscriptionPaidUntil,
                subscriptionDueOn: userDates.subscriptionDueOn
              })
            }
          })
        })
        .catch((e) => {
          setError(e.message)
          console.error(e)
        })
    } else {
      const confirmation = encodeURI(
        JSON.stringify({
          customMessage: `New ${paymentMethodType} payment method was successfully added.`,
          type: 'custom',
          params: {
            emoji: '🎉',
            type: 'success',
            closeOnClick: true,
            timeout: 3000
          }
        })
      )

      pushConfirmation(context, confirmation)
      history.replace(buildPath('editPaymentMethods'))
    }
  }
  return (
    <>
      <Navbar backButtonClick={backButtonClick} />
      <div className={styles.container}>
        <Toast ref={toastRef} />
        {selectedPricingPlan ? (
          <SubscriptionHeader
            selectedPricingPlan={selectedPricingPlan as TSelectedPlan}
          />
        ) : (
          <div className={styles.titleContainer}>
            <h1 className="typo-app-title-medium-adaptive">
              Add a payment method
            </h1>
          </div>
        )}

        <NewPaymentMethod
          setView={setView}
          view={view}
          managePaymentMethods={true}
        >
          <TabPage activeTab={activeTab} index={0}>
            <LinkBankAccount
              ctaText="Add bank account via Plaid"
              handleSuccess={() => onSuccess('bank account')}
            />
            <Button
              label="Add bank account manually"
              mode="ghost"
              minWidth={335}
              onClick={() => {
                history.push({
                  pathname: buildPath('addManualBankAccount'),
                  search: queryParams.toString()
                })
              }}
              className={styles.cta}
            />
          </TabPage>
          <TabPage activeTab={activeTab} index={1}>
            <AddStripeCard
              loadingUpdateUserPricingPlan={loadingUpdateUserPricingPlan}
              updateCurrentUser={updateCurrentUser}
              user={currentUser}
              onSuccess={() => onSuccess('credit card')}
            />
          </TabPage>
        </NewPaymentMethod>
      </div>
    </>
  )
}
