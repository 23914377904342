import moment from 'moment'
import { MoneyValueObject } from 'modules/global/value-objects/money.value-object'
import { USStateAbbreviation } from 'constants/us-states'

export const DateFormat = {
  default: (date: Date, useNow?: boolean) => {
    let dt =
      date &&
      moment((typeof date === 'object' && date) || date).format('MMMM D, Y')
    if (useNow && dt === moment().format('MMMM D, Y')) {
      dt = 'Now'
    }
    return dt
  },
  short: (date: Date) => {
    return (
      date &&
      moment((typeof date === 'object' && date) || date).format('MMM D, Y')
    )
  },
  numeric: (date: any) => {
    if (
      typeof date === 'object' ||
      (typeof date === 'string' && date.length > 10)
    ) {
      return moment(date).format('MM/DD/Y')
    } else if (typeof date === 'string' && date.length === 10) {
      return moment(date, 'YYYY-MM-DD', true).format('MM/DD/YYYY')
    }
  },
  ISO8601: (str: string) => {
    const result = str && moment(str, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
    return result === 'Invalid date' ? null : result
  },
  monthAndYear: (str: string) => {
    const result = str && moment(str).format('MMMM YYYY')
    return result === 'Invalid date' ? null : result
  },
  monthAndYearAbbr: (str: string) => {
    const result = str && moment(str).format('MMM YY')
    return result === 'Invalid date' ? null : result
  },
  monthDayAndYearAbbr: (str: string) => {
    const result = str && moment(str).format('MMM D, YYYY')
    return result === 'Invalid date' ? null : result
  }
}

export const StringFormat = {
  address: (addr: {
    streetAddress: string
    zipCode: string
    city: string
    state: USStateAbbreviation
  }) => {
    const parts = [addr.streetAddress, addr.city, addr.state, addr.zipCode]

    return parts.filter(filterString).join(', ')
  },

  firstName(str: string) {
    if (!str) {
      return null
    }

    const name = str.replace(/\s+/g, ' ').trim()
    return this.capitalize(name.split(' ')[0])
  },

  fullName: (
    data: Partial<{ firstName: string; middleName: string; lastName: string }>
  ) => {
    const parts = [data.firstName, data.middleName, data.lastName]
    // @ts-ignore
    return parts.filter(filterString).join(' ')
  },

  ssn: (str: string) => {
    if (typeof str !== 'string') {
      return str
    }
    return `${str.substr(0, 3)}-${str.substr(3, 2)}-${str.substr(-4)}`
  },

  capitalize(str: string) {
    if (typeof str !== 'string') {
      return str
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
  },

  toNormalCase: (str: string) => {
    let output

    const msg =
      typeof str === 'string' &&
      str
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .trim()

    if (msg) {
      output = msg

      // Re-format abbreviations:
      output = output.replace('s s n', 'SSN')
      output = output.replace('ssn', 'SSN')
      output = output.replace('i t i n', 'ITIN')
      output = output.replace('itin', 'ITIN')
      output = output.replace('c v c', 'CVC')
      output = output.replace('cvc', 'CVC')
      output = output.replace('p o  box', 'PO Box')
      output = output.replace(
        'social security number',
        'Social Security Number'
      )
      output = output.replace('tax id', 'Tax ID')
      output = output.replace('tax i d', 'Tax ID')
      output = output.replace('u r l', 'URL')
      output = output.charAt(0).toUpperCase() + output.slice(1)
    }
    return output
  },

  investmentOption: (option: string) => {
    const str =
      typeof option === 'string'
        ? option.replace('fdic', 'FDIC').replace(/-/g, ' ')
        : ''
    return StringFormat.capitalize(str)
  },

  phoneNumber: (phone: string) => {
    const phoneTest = new RegExp(
      /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/
    )

    phone = String(phone).trim()
    const results = phoneTest.exec(phone)
    if (results !== null && results.length > 8) {
      return (
        '(' +
        results[3] +
        ') ' +
        results[4] +
        '-' +
        results[5] +
        (typeof results[8] !== 'undefined' ? ' x' + results[8] : '')
      )
    } else {
      return phone
    }
  }
}

export function pluralize(count: number, word: string) {
  return count === 1 ? `${word}` : `${word}s`
}

export function formatRelationship(
  relationship: FundRelationship
): string | null {
  if (relationship) {
    return StringFormat.capitalize(relationship)
  } else {
    return null
  }
}

export function displaySimplifiedRelationship(
  relationship: FundRelationship
): string | null {
  if (!relationship) return null
  if (relationship === 'friend' || relationship === 'other') {
    return 'Family friend'
  } else {
    return 'Close family'
  }
}

const dollars = (
  value: number,
  cents?: boolean,
  truncateZeroes?: boolean
): string => {
  return new MoneyValueObject({ value }).toString(cents, truncateZeroes)
}

export const NumberFormat = {
  percentage: (number: number) => {
    return `${number * 100}%`
  },

  currencyFromCents: (
    num: number,
    cents?: boolean,
    truncateZeroes?: boolean
  ): string => {
    return `$` + dollars(num, cents, truncateZeroes)
  },

  roundUp: (value: number) => {
    return Math.ceil(value / 100)
  },
  roundCurrency: (num: number): string => {
    return Number(num.toFixed(0)).toLocaleString('en')
  },

  round: (num: number, decimalPlaces: number = 0, to: number = 1): number => {
    return Number((num / to).toFixed(decimalPlaces)) * to
  },

  niceRoundCurrency: (number: number): string => {
    const money =
      number >= 10000
        ? NumberFormat.round(number, 0, 100)
        : NumberFormat.round(number, 0, 10)

    return Number(money).toLocaleString('en')
  },

  leftPadZeroes: (num: number, size: number = 2): string => {
    let zeroes: string = ''
    for (let i = 0; i < size - 1; i++) {
      zeroes += '0'
    }

    const s = zeroes + num
    return s.substr(s.length - size)
  }
}

function filterString(str: string) {
  if (typeof str !== 'string') {
    return false
  }

  if (str.trim().length === 0) {
    return false
  }

  return true
}

export const formatDateDob = (inputDate: string) => {
  const birthDate = moment(inputDate)
  const now = moment()
  const age = moment.duration(now.diff(birthDate)).years()

  // Format the result
  let result = `${birthDate.format('MMMM Do')}, ${age} years old`

  return result
}

export type FundRelationship =
  | 'aunt'
  | 'father'
  | 'friend'
  | 'grandfather'
  | 'grandmother'
  | 'guardian'
  | 'mother'
  | 'parent'
  | 'uncle'
  | 'other'
