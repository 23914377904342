import gql from 'graphql-tag'
import userFragments from 'modules/profile/graphqlFragments'

const recommendedPlan = gql`
  fragment RecommendedPlanFragment on Plan {
    id
    displayName
    enrollmentConfig
    id
    canEnroll
    isSupportedLinked
    loginUrl
    signupUrl
    websiteUrl
    name
    plaidInstitutionId
    planName
    investmentManagers
    overallScore
    feeScore
    taxSavingsScore
    morningstarRating
    morningstarScore
    programDescriptionUrl
    shortDescription
    stateName
    stateCode
    avgExpenseFee
    investmentOptions {
      id
      name
      riskLevel
      label
    }
  }
`
const enrollmentSyncFund = gql`
  fragment EnrollmentSyncFundFragment on Fund {
    id
    ssn
    dob
    programManager
    portfolio
    plan {
      ...RecommendedPlanFragment
    }
    agreedToTermsAt
    lpoaStatus
    enrollmentStatus
    fundOwner {
      id
      identityVerified
      city
      ...SubscriptionFragmentForEnrollment
      currentFundDraft {
        id
        currentFlow
      }
    }
  }
  ${recommendedPlan}
  ${userFragments.subscriptionForEnrollment}
`

const fragments = { recommendedPlan, enrollmentSyncFund }
export default fragments
