import moment from 'moment'
import classNames from 'classnames'
import { LocalStorage } from 'LocalStorage'

import { ORDINAL_NUMBERS } from 'constants/index'
import { historyRef } from '../routes'
import { NumberFormat, DateFormat, sendToMobileApp } from 'utils'
import config from '../config'
import { get } from 'lodash'
import { ContributionLimits, Frequency } from 'modules/contribution/types'
import { Cookie } from '../cookie'
import { getQuery } from 'hooks'

export const NO_FUND_HANDLE = 'in-progress'

export function pushFollowerConfirmation(
  context: React.Context<SessionInfo>,
  confirmationMessage?: string
) {
  const confirmation = encodeURI(
    JSON.stringify({
      customMessage:
        confirmationMessage || "You'll receive updates on this fund.",
      type: 'custom',
      params: {
        emoji: '😎',
        type: 'info'
      }
    })
  )

  pushConfirmation(context, confirmation)
}

export function pushSetPasswordConfirmation(
  context: React.Context<SessionInfo>,
  confirmationMessage?: string
) {
  const confirmation = encodeURI(
    JSON.stringify({
      customMessage: confirmationMessage || 'Your password has been saved.',
      type: 'custom',
      params: {
        emoji: '✅',
        type: 'success',
        closeOnClick: true,
        timeout: 3000
      }
    })
  )

  pushConfirmation(context, confirmation)
}

export function backToDashboard(
  context: React.Context<SessionInfo>,
  confirmationMessage?: any
) {
  const confirmation = confirmationMessage
    ? encodeURI(
        JSON.stringify({
          customMessage: confirmationMessage,
          type: 'custom'
        })
      )
    : ''

  pushConfirmation(context, confirmation)
  // @ts-ignore
  const { user } = context
  const defaultFund = getDefaultFund(user)

  historyRef.current.replace(`/${defaultFund.handle}`)
}

export function pushConfirmation(
  context: React.Context<SessionInfo>,
  confirmation: string,
  showConfirmationInMobileApp = true
) {
  if (showConfirmationInMobileApp && (context as any).mobileAppFromV(2)) {
    const decoded = decodeURIComponent(confirmation)
    const data = JSON.parse(decoded)
    const { alertTitle, alertMessage } = formatConfirmation(data)
    sendToMobileApp('set_confirmation', alertTitle, alertMessage)
  } else {
    LocalStorage.setItem('confirmation', confirmation)
  }
}

export function pushFundName(fundName: string) {
  LocalStorage.setItem('fundName', fundName)
}

export function pushActiveTab(
  activeTab: string,
  setActiveTab?: (tab: string) => void
) {
  LocalStorage.setItem('activeTab', activeTab)
  if (setActiveTab) {
    setActiveTab(activeTab)
  }
}

export function popActiveTab() {
  LocalStorage.removeItem('activeTab')
}

export function formatConfirmation(confirmation: any): {
  alertTitle?: string
  alertMessage?: string
} {
  const { type, amount, frequency, activationDate, creditCard, customMessage } =
    confirmation || {}

  switch (type) {
    case 'custom':
      return alertInfoForCustom(customMessage)
    case 'creation':
      return alertInfoForCreation(amount, frequency, activationDate, creditCard)
    case 'cancellation':
      return alertInfoForCancellation(amount, frequency)
    default:
      return {}
  }
}
function alertInfoForCustom(customMessage: string) {
  return {
    alertMessage: customMessage
  }
}
export const frequencyMapping: any = {
  monthly: 'per month',
  semimonthly: 'twice per month',
  annually: 'per year'
}
function alertInfoForCreation(
  amount: number,
  frequency: string,
  activationDate: Date,
  creditCard = false
) {
  const frequencyText = frequencyMapping[frequency]
  const isCurrentDate = moment(activationDate).isSame(new Date(), 'day')
  const formattedDate = DateFormat.numeric(activationDate)
  const action = creditCard
    ? 'made'
    : `scheduled${isCurrentDate ? '' : ` for ${formattedDate}`}`

  const alertTitle =
    frequency === 'onetime'
      ? 'Contribution scheduled.'
      : 'Automatic contribution set up.'

  const alertMessage =
    frequency === 'onetime'
      ? `Your payment of ${NumberFormat.currencyFromCents(
          amount
        )} has been ${action}.`
      : `Your automatic contribution of ${NumberFormat.currencyFromCents(
          amount
        )} ${frequencyText} has been ${action}.`

  return { alertTitle, alertMessage }
}

function alertInfoForCancellation(amount: number, frequency: string) {
  const alertMessage = `You cancelled your ${frequency} ${NumberFormat.currencyFromCents(
    amount
  )} contribution.`
  return { alertTitle: 'CONTRIBUTION CANCELLED', alertMessage }
}

export function getConfirmation() {
  const confirmation = LocalStorage.getItem('confirmation')

  if (confirmation) {
    const json = decodeURIComponent(confirmation)
    return JSON.parse(json)
  }
  return {}
}

export function pickUniqueClasses(classesString: string) {
  const classesArray = classesString.split(' ')
  const classes = new Set()
  classesArray.forEach((cls) => {
    classes.add(cls)
  })
  return Array.from(classes).join(' ')
}

export function generateClasses(block: string, modifiers?: string) {
  const classes = [block]
  if (modifiers) {
    modifiers.split(' ').map((modifier) => {
      classes.push(`${block}--${modifier}`)
    })
  }
  return classes.join(' ')
}

export function generateSkeletonClasses(
  styles: any,
  className: string,
  skeleton?: boolean,
  dark?: boolean
) {
  return classNames(styles[className], 'skeleton-animation', {
    [styles[`${className}Skeleton`]]: skeleton,
    'skeleton-animation--dark': dark
  })
}

export async function execute(
  fn: () => Promise<any>,
  callback: () => void,
  duration: number
) {
  const t0 = performance.now()
  await fn()
  const t1 = performance.now()
  const elapsed = t1 - t0

  setTimeout(
    () => {
      callback()
    },
    duration > elapsed ? duration - elapsed : 0
  )
}

export function isInView(el: Element) {
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom

  // Only completely visible elements return true:
  return elemTop >= 0 && elemBottom <= window.innerHeight
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

export function isMobile(): boolean {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true
  }
  return false
}

export function maxWidthOfScreen(pixels: number) {
  return window.matchMedia(`(max-width: ${pixels}px)`).matches
}

export function minWidthOfScreen(pixels: number) {
  if (typeof window === 'undefined') return true
  return window.matchMedia(`(min-width: ${pixels}px)`).matches
}

export function isDesktopModal() {
  return minWidthOfScreen(621)
}

const userAgentRegexp = /.* CollegeBackerMobileV?([0-9]*)P?(.*)/
export function getMobileAppInfo(): MobileInfo {
  let match

  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    match = navigator.userAgent.match(userAgentRegexp)
  }
  if (match) {
    const version = Number(match[1])
    const platform = match[2] || 'iOS' // if not set platform is iOS because we didn't have an android app then
    return { mobileApp: true, version, platform }
  } else {
    return { mobileApp: false, version: 0, platform: 'web' }
  }
}

export function isMobileApp(platform: string = ''): boolean {
  const info = getMobileAppInfo()

  if (platform) {
    return info && info.platform === platform && info.mobileApp
  } else {
    return info.mobileApp
  }
}

export function setIsMobileAppInAppBrowserFromQuery() {
  const queryParams = getQuery()
  if (queryParams.get('isMobileApp') == 'true') {
    Cookie.set('isMobileAppInAppBrowser', 'true')
  } else {
    // We need to delete the cookie because on Android the cookies are shared between Chrome and the in-app
    // browser. If the user opens the website in Chrome after using the app we need to make sure
    // that the cookie is deleted, otherwise it would behave like an in-app browser.
    Cookie.delete('isMobileAppInAppBrowser')
  }
}

export function isMobileAppInAppBrowser(): boolean {
  if (Cookie.get('isMobileAppInAppBrowser') == 'true') {
    return true
  }
  return false
}

let _canvas: HTMLCanvasElement | null = null
export function getTextWidth(text: string, font: string): number {
  // re-use canvas object for better performance
  // @ts-ignore
  const canvas = _canvas || (_canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  } else {
    return 0
  }
}

export function getOrdinalNumber(value: number) {
  const item = ORDINAL_NUMBERS.find(
    (item: { label: string; value: number }) => item.value === value
  )
  return item?.label
}

export function updateScreenWidth(setScreenWidth: (arg0: number) => void) {
  const screenWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width
  setScreenWidth(screenWidth)
}

export const getFrequencyLabel = (frequency: Frequency) => {
  switch (frequency) {
    case 'onetime':
      return 'one-time'
    case 'annually':
      return 'per year'
    case 'semimonthly':
      return 'twice a month'
    default:
      return 'per month'
  }
}
export const getFrequencyText = (
  frequency: 'monthly' | 'semimonthly' | 'onetime' | 'annually'
) => {
  return frequency
    ? frequency.replace('onetime', 'one-time').replace('annually', 'yearly')
    : frequency
}

export const getInitials = (displayName: string) => {
  if (!displayName.trim()) return null
  const arr = displayName.split(' ')
  return arr.length > 1
    ? (arr[0][0] + arr[arr.length - 1][0]).toUpperCase()
    : arr[0][0].toUpperCase()
}

export const getDateLabel = (frequency: string) => {
  switch (frequency) {
    case 'onetime':
      return 'Date'
    case 'annually':
      return 'Starts on'
    default:
      return 'Recurs on'
  }
}

export const getAgeLabel = (fund: any) => {
  const { dob, expectedDob, prebirth } = fund
  if (prebirth === 'current') {
    if (expectedDob) {
      return `DUE ${moment(expectedDob).format('MMMM YYYY')}`
    } else {
      return null
    }
  } else if (dob) {
    const age = moment().diff(dob, 'years')
    return `${age} ${age > 1 ? 'YEARS' : 'YEAR'} OLD`
  }

  return null
}

export const uploadPhoto = async ({
  image,
  preset,
  setUrl,
  setError
}: {
  image: string
  preset: string
  setUrl?: (arg0: any) => void
  setError?: (arg0: string) => void
}) => {
  const body = new FormData()
  body.append('file', image)
  body.append('upload_preset', preset)

  const res = await fetch(config.CLOUDINARY_API_URL, {
    method: 'POST',
    body
  })

  const data = await res.json()
  if (setError && data.error) setError(data.error.message)

  if (setUrl) setUrl(data.secure_url)
  return data
}

export function toHoursAndMinutes(totalMinutes: number) {
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)

  return {
    hours,
    minutes
  }
}

export function getDefaultFund(user: {
  funds: any[]
  gifts: any[]
  followedFunds: any[]
}) {
  return get(user, 'funds.0')
}

export function validateAmount(
  amount: number,
  frequency: Frequency,
  contributionLimits: ContributionLimits
) {
  if (contributionLimits.minimumMonthly) {
    if (frequency === 'semimonthly') {
      if (amount < contributionLimits.minimumMonthly / 2) {
        return `The minimum recurring semimonthly contribution is ${NumberFormat.currencyFromCents(
          contributionLimits.minimumMonthly,
          false,
          true
        )} (${NumberFormat.currencyFromCents(
          contributionLimits.minimumMonthly / 2,
          false,
          true
        )} twice per month).`
      }
    } else if (amount < contributionLimits.minimum) {
      return `The minimum contribution is ${NumberFormat.currencyFromCents(
        contributionLimits.minimum,
        false,
        true
      )}.`
    }
  } else {
    if (amount < contributionLimits.minimum) {
      return `The minimum contribution is ${NumberFormat.currencyFromCents(
        contributionLimits.minimum,
        false,
        true
      )}.`
    }
  }

  if (amount > contributionLimits.maximum) {
    return `You can superfund your plan with 5 years of contributions but the total cannot exceed ${NumberFormat.currencyFromCents(
      contributionLimits.maximum,
      false,
      true
    )}.`
  }

  if (
    contributionLimits.maximumMonthly &&
    frequency === 'monthly' &&
    amount > contributionLimits.maximumMonthly
  ) {
    return `The maximum recurring monthly contribution is ${NumberFormat.currencyFromCents(
      contributionLimits.maximumMonthly,
      false,
      true
    )} per month.`
  }

  if (
    contributionLimits.maximumMonthly &&
    frequency === 'semimonthly' &&
    amount > contributionLimits.maximumMonthly / 2
  ) {
    return `The maximum recurring semimonthly contribution is ${NumberFormat.currencyFromCents(
      contributionLimits.maximumMonthly,
      false,
      true
    )} (${NumberFormat.currencyFromCents(
      contributionLimits.maximumMonthly / 2,
      false,
      true
    )} twice per month).`
  }

  if (
    contributionLimits.maximumAnnually &&
    frequency === 'annually' &&
    amount > contributionLimits.maximumAnnually
  ) {
    return `The maximum recurring annual contribution is ${NumberFormat.currencyFromCents(
      contributionLimits.maximumAnnually,
      false,
      true
    )} per year.`
  }
}

// helper to deal with "Object is possibly 'undefined' TS error when using Array.protype.find()"
// https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind#answer-54738437
export function ensure<T>(
  argument: T | undefined | null,
  message: string = 'This value was promised to be there.'
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message)
  }

  return argument
}

export function getReferrer(location: { pathname: string; search: string }) {
  return encodeURIComponent(`${location.pathname}${location.search}`)
}

export interface MobileInfo {
  mobileApp: boolean
  mobileWeb?: boolean
  version: number
  platform: 'iOS' | 'android' | 'web' | string
}

export interface SessionInfo {
  mobileApp: boolean
  mobileWeb: boolean
  version: number
  // platform: 'iOS' | 'android' | 'web'
  platform: string
  isAuthenticated: boolean
  user: any
  fundName: string
  setUser: (arg0: any) => void
  logIn: (arg0: any) => void
  logOut: () => void
  mobileAppFromV: (arg0: number) => boolean
}
