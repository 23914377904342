export const GENDERS = [
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const XO_GENDERS = [
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Male',
    value: 'male'
  }
]

export type Gender = (typeof GENDERS)[number]['value']
