import { capitalize } from 'lodash'
import { Frequency } from 'modules/contribution/types'
import { Link, useHistory } from 'react-router-dom'
import { buildPath } from 'routes'
import { DateFormat, NumberFormat } from 'utils'
import { getFrequencyText } from 'utils/helpers'
import styles from './FundRecentActivity.module.scss'
import { Button } from '@collegebacker/backer-ui/ui'

export const FundRecentActivity = ({
  fund
}: {
  fund: {
    handle: string
    recentFundTransactions: FundTransaction[]
    upcomingFundTransactions: FundTransaction[]
    uuid: string
  }
}) => {
  const history = useHistory()
  const { handle, recentFundTransactions, upcomingFundTransactions } = fund

  const recentActivity =
    upcomingFundTransactions.length > 0 || recentFundTransactions.length > 0

  if (!recentActivity) return null

  return (
    <div className={styles.section}>
      <div>
        <h2 className="typo-app-title-small">Recent activity</h2>
        <p className={styles.transactionLead}>
          View latest payments to the fund
        </p>
      </div>
      <div>
        {upcomingFundTransactions.length > 0 && (
          <div className={styles.transactions}>
            <h3 className={styles.transactionLabel}>Upcoming</h3>
            {upcomingFundTransactions.map((transaction: any, idx: any) => {
              const { id, amount, occurredOn, frequency, fundBacker, status } =
                transaction
              return (
                <div className={styles.transactionLine} key={id}>
                  <div>
                    <p className={styles.transactionText}>
                      From {fundBacker.name}
                    </p>
                    <p className={styles.transactionSubText}>
                      <span>{capitalize(getFrequencyText(frequency))}</span>{' '}
                      payment on {DateFormat.monthDayAndYearAbbr(occurredOn)}
                    </p>
                  </div>
                  <div className={styles.transactionRight}>
                    <p className={styles.transactionAmount}>
                      {NumberFormat.currencyFromCents(amount)}
                    </p>
                    <p className={styles.transactionSubText}>{status}</p>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {(recentFundTransactions || []).length > 0 && (
          <>
            <div className={styles.transactions}>
              <h3 className={styles.transactionLabel}>Recent</h3>
              {(recentFundTransactions || [])
                .slice(0, 6)
                .map((transaction: any, idx: number) => {
                  const { amount, occurredOn, frequency, fundBacker, type } =
                    transaction
                  return (
                    <div
                      className={styles.transactionLine}
                      key={transaction.id}
                    >
                      <div className={styles.transactionLeft}>
                        <p className={styles.transactionText}>
                          From {fundBacker.name}
                        </p>
                        <p className={styles.transactionSubText}>
                          <span>{capitalize(getFrequencyText(frequency))}</span>{' '}
                          payment on{' '}
                          {DateFormat.monthDayAndYearAbbr(occurredOn)}
                        </p>
                      </div>
                      <div className={styles.transactionRight}>
                        <p className={styles.transactionAmount}>
                          {NumberFormat.currencyFromCents(amount)}
                        </p>
                        <p className={styles.transactionSubText}>
                          {transaction.status}
                        </p>
                      </div>
                    </div>
                  )
                })}
              <Button
                label="Payment history"
                onClick={() =>
                  history.push(
                    buildPath('fundTransactionHistory', {
                      uuidOrHandle: handle
                    })
                  )
                }
                mode="outline"
                icon="opened-eye"
                style={{ width: '100%', marginTop: 20 }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
interface FundTransaction {
  amount: number
  description: string
  frequency: Frequency
  fundBacker: {
    name: string
    id: string
  }
  id: string
  occurredOn: Date
  status: string
  type: 'CONTRIBUTION' | 'GIFT'
}
