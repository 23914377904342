import React from 'react'
import { generateClasses } from 'utils/index'
import { NumberFormat } from 'utils/format'
import { MoneyValueObject } from 'modules/global/value-objects/money.value-object'

import './Money.scss'

export const Money: React.FunctionComponent<Props> = ({
  money,
  modifier,
  decimals,
  superscriptStyle,
  cents,
  className
}) => {
  let moneyInCents = cents
  if (cents && !decimals) {
    moneyInCents = Math.round(cents/100) * 100
  }
  // @ts-ignore
  const currencyFromCents = NumberFormat.currencyFromCents(moneyInCents, true)
  const currency = money || currencyFromCents.slice(1, currencyFromCents.length)

  const getDollars = (money: MoneyValueObject | string) => {
    return money.toString().split('.')[0]
  }

  const getCents = (money: MoneyValueObject | string) => {
    return money.toString().split('.')[1]
  }
  
  return (
    <span className={`${generateClasses('money', modifier)} ${className ? className : ''}`}>
      <span
        className={generateClasses('money__dollar-sign', `${superscriptStyle && 'superscript'} ${modifier && modifier}`)}
      >
        $
      </span>
      <span className={generateClasses('money__dollar', modifier)}>
        {getDollars(currency)}
      </span>
      {decimals && (
        <span
          className={generateClasses('money__cents', `${superscriptStyle && 'superscript'} ${modifier}`)}
        >
          .{getCents(currency)}
        </span>
      )}
    </span>
  )
}

interface Props {
  money?: MoneyValueObject
  cents?: number
  className?: string
  modifier?: string
  decimals?: boolean
  superscriptStyle?: boolean
}
