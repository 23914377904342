//@ts-nocheck
export const LocalStorage = {
  setItem(key: LocalStorageKey, value) {
    this.$window.localStorage.setItem(key, value)
  },
  getItem(key: LocalStorageKey) {
    return this.$window.localStorage.getItem(key)
  },
  removeItem(key: LocalStorageKey): void {
    this.$window.localStorage.removeItem(key)
  },

  get $window(): Window {
    let $window
    if (typeof window === 'object') {
      $window = window
    } else {
      // Mock window for the context of `localStorage`
      $window = {
        localStorage: {
          state: {},
          setItem(key: LocalStorageKey, value) {
            this.state[key] = value
          },
          getItem(key: LocalStorageKey) {
            return this.state[key]
          },
          removeItem(key: LocalStorageKey) {
            delete this.state[key]
          },
        },
      }
    }
    return $window
  },
}

export type LocalStorageKey =
  | 'addBackers'
  | 'confirmation'
  | 'fundName'
  | 'activeTab'
  | 'fundMessage'
  | 'hasRequiredContribution'
  | 'contributionYears'
  | 'hasPro'