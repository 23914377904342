import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import {
  Elements,
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js'
import { isEmpty } from 'lodash'
import { vaidateUSStateAbbreviation } from 'constants/us-states'

import { getStripe, graphQLErrorHandler } from 'utils'
import { InputAddressNew, InputStripe, Loading } from 'components'
import {
  Input,
  StateInput,
  Checkbox,
  ZipInput,
  WarnMesssage,
  Stack,
  Button
} from '@collegebacker/backer-ui/ui'
import {
  CreditCardLimitWarning,
  isCreditCardAmountTooHigh
} from '../components/CreditCardLimitWarning'
import {
  amountValue,
  bankAccountValue,
  creditCardValue,
  frequencyValue,
  ccStreet,
  ccStreet2,
  ccCity,
  ccState,
  ccZip
} from '../LocalState'
import fragments from '../graphqlFragments'
import { getPaymentMethod, setPaymentMethod } from '../helpers'
import { isGuest } from 'utils/user'

import styles from './AddStripeCard.module.scss'

const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation CreateStripeSetupIntent($input: CreateStripeSetupIntentInputType!) {
    createStripeSetupIntent(input: $input) {
      clientSecret
    }
  }
`

const CREATE_CREDIT_CARD = gql`
  mutation CreateCreditCard($input: CreateCreditCardInputType!) {
    createCreditCard(input: $input) {
      ...CreditCardFragment
    }
  }
  ${fragments.creditCard}
`

export const AddStripeCard = ({
  onSuccess,
  updateCurrentUser,
  user,
  loadingUpdateUserPricingPlan
}: {
  onSuccess: () => void
  updateCurrentUser: (attributes: object) => Promise<any>
  user: any
  loadingUpdateUserPricingPlan?: boolean
}) => {
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [secret, setSecret] = useState(null)
  const [createStripeSetupIntent] = useMutation(CREATE_STRIPE_SETUP_INTENT)

  async function createIntent() {
    try {
      const result = await createStripeSetupIntent({
        variables: { input: { userUuid: user.uuid } }
      })
      return result.data.createStripeSetupIntent.clientSecret
    } catch (e) {
      setGlobalError('An error occured during Stripe setup.')
      console.error('ERROR>>> createStripeSetupIntent() failed:', e)
    }
  }

  async function setUpStripe() {
    setSecret(await createIntent())
  }

  useEffect(() => {
    setUpStripe()
  }, [])

  const location = (window as any).document.location

  return globalError ? (
    <WarnMesssage text={globalError} type="warning" />
  ) : (
    <Elements
      stripe={getStripe()}
      options={{
        fonts: [
          {
            src: `url(${location.origin}/static/fonts/abc-marfa/abcmarfa-regular-webfont.ttf)`,
            family: 'ABCMarfaRegular',
            weight: 'normal'
          }
        ]
      }}
    >
      <PaymentMethodForm
        setupIntentSecret={secret}
        onSuccess={onSuccess}
        updateCurrentUser={updateCurrentUser}
        user={user}
        loadingUpdateUserPricingPlan={loadingUpdateUserPricingPlan}
      />
    </Elements>
  )
}

export const PaymentMethodForm = ({
  setupIntentSecret,
  onSuccess,
  updateCurrentUser,
  user,
  loadingUpdateUserPricingPlan
}: {
  setupIntentSecret: any
  onSuccess: () => void
  updateCurrentUser: (attributes: object) => Promise<any>
  user: any
  loadingUpdateUserPricingPlan?: boolean
}) => {
  const [error, setError] = useState(null)
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [busy, setBusy] = useState(false)
  const [cardholder, setCardholder] = useState(user.fullName || '')
  const [cardholderError, setCardholderError] = useState('')
  const [street, setStreet] = useState(ccStreet() || user.streetAddress || '')
  const [streetError, setStreetError] = useState('')
  const [street2, setStreet2] = useState(
    ccStreet2() || user.streetAddress2 || ''
  )
  const [city, setCity] = useState(ccCity() || user.city || '')
  const [cityError, setCityError] = useState('')
  const [state, setState] = useState(ccState() || user.state || '')
  const [stateError, setStateError] = useState('')
  const [zipCode, setZipCode] = useState(ccZip() || user.zipCode || '')
  const [zipCodeError, setZipCodeError] = useState('')
  const [saveForReuse, setSaveForReuse] = useState(true)

  const frequency = frequencyValue()
  const paymentMethod = getPaymentMethod(
    user,
    bankAccountValue,
    creditCardValue
  )
  const amount = amountValue()

  const creditCardAmountTooHigh = isCreditCardAmountTooHigh({
    paymentMethod,
    frequency,
    amount
  })

  const ELEMENT_OPTIONS = {
    disabled: creditCardAmountTooHigh
  }
  const [createCreditCard, { loading }] = useMutation(CREATE_CREDIT_CARD)
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    ccStreet(street)
    ccStreet2(street2)
    ccCity(city)
    ccState(state)
    ccZip(zipCode)
  }, [street, street2, city, state, zipCode])

  if (loading || loadingUpdateUserPricingPlan) return <Loading />

  const handleSelect = (addressParts: any) => {
    const {
      street_number,
      route,
      locality,
      administrative_area_level_1,
      postal_code
    } = addressParts
    setStreet(`${street_number ? street_number + ' ' : ''}${route || ''}`)
    setCity(locality || '')
    setState(administrative_area_level_1 || '')
    setZipCode(postal_code || '')
  }

  const handleAddressChange = (event: any) => {
    setStreet(event.currentTarget.value)
    setCity('')
    setState('')
    setZipCode('')
  }
  const handleChange = (event: any) => {
    if (event.error) setError(event.error.message)
    else setError(null)
  }

  async function handleSubmit(event: any) {
    if (!stripe || !elements) return

    event.preventDefault()

    const errors: any = validate({
      cardholder,
      street,
      city,
      state,
      zipCode
    })

    setCardholderError(errors['cardholder'])
    setStreetError(errors['street'])
    setCityError(errors['city'])
    setStateError(errors['state'])
    setZipCodeError(errors['zipCode'])

    try {
      if (!isEmpty(errors)) {
        return
      } else {
        setBusy(true)
        updateCurrentUser({
          variables: {
            input: {
              streetAddress: street,
              streetAddress2: street2,
              city,
              state,
              zipCode
            }
          }
        })
          .then(async () => {
            const result = await stripe.confirmCardSetup(setupIntentSecret, {
              payment_method: {
                // @ts-ignore
                card: elements.getElement(CardElement),
                billing_details: { email: user.email }
              }
            })
            if (result.error) {
              // @ts-ignore
              setError(result.error.message)
            } else {
              const response = await createCreditCard({
                variables: {
                  input: {
                    stripePaymentMethodId: result.setupIntent.payment_method,
                    cardHolder: cardholder,
                    oneTimeUse: !saveForReuse
                  }
                }
              })
              const creditCard = response.data.createCreditCard

              setPaymentMethod({
                creditCard,
                creditCardValue,
                bankAccountValue
              })
              onSuccess()
            }
            setBusy(false)
          })
          .catch(graphQLErrorHandler(setGlobalError, () => setBusy(false)))
      }
    } catch (error: any) {
      setError(
        error.graphQLErrors.map((error: any) => error.message).join(', ')
      )
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      {creditCardAmountTooHigh && (
        <CreditCardLimitWarning frequency={frequency} />
      )}
      {globalError && <WarnMesssage text={globalError} type="warning" />}
      <fieldset disabled={creditCardAmountTooHigh} className={styles.fieldset}>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>Card details</p>
            <p>
              Use any credit or debit card to make your payment. 3% processing
              fee.
            </p>
          </div>
          <Stack spacing={15}>
            <Input
              autoFocus={true}
              label="Cardholder name"
              name="cardholder"
              type="text"
              value={cardholder}
              onChange={(event: any) => setCardholder(event.target.value)}
              errorMessage={cardholderError}
            />
            <InputStripe
              className={styles.stripeInput}
              onChange={handleChange}
              options={ELEMENT_OPTIONS}
              errorMessage={error}
            />
          </Stack>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>Billing information</p>
          </div>
          <Stack spacing={15}>
            <InputAddressNew
              name="street"
              onChange={handleAddressChange}
              label="Street address"
              value={street}
              setAddress={setStreet}
              onSelect={handleSelect}
              errorMessage={streetError}
            />
            <Input
              name="beneficiaryStreet2"
              helperText="Optional"
              label="Apt, Ste, etc."
              value={street2}
              noAutocomplete={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setStreet2(event.currentTarget.value)
              }
            />
            <Input
              name="city"
              label="City"
              value={city}
              noAutocomplete={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCity(event.currentTarget.value)
              }
              errorMessage={cityError}
            />
            <StateInput
              name="state"
              value={state}
              noAutocomplete={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setState(event.currentTarget.value)
              }
              errorMessage={stateError}
            />
            <ZipInput
              name="zipCode"
              value={zipCode}
              noAutocomplete={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setZipCode(event.currentTarget.value)
              }
              errorMessage={zipCodeError}
            />
          </Stack>
        </div>

        <Stack spacing={15} className={styles.btnContainer}>
          {!isGuest(user) && (
            <Checkbox
              name="saveForReuse"
              id="saveForReuse"
              value="true"
              checked={saveForReuse}
              onChange={(checked: boolean) => setSaveForReuse(checked)}
            >
              <p className="typo-app-body-main">
                Save payment details for future contributions.
              </p>
            </Checkbox>
          )}
          <Button
            type="submit"
            label="Continue"
            maxWidth={400}
            disabled={busy}
          />
        </Stack>
      </fieldset>
    </form>
  )
}

function validate({ cardholder, street, city, state, zipCode }: any) {
  const errors: any = {}

  if (isEmpty(cardholder)) errors['cardholder'] = 'Cardholder name is required'
  if (isEmpty(street)) errors['street'] = 'Street address is required'
  if (isEmpty(city)) errors['city'] = 'City is required'
  if (vaidateUSStateAbbreviation(state) === false)
    errors['state'] = 'State is invalid'
  if (isEmpty(zipCode)) errors['zipCode'] = 'Zip code is required'

  return errors
}
