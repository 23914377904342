import gql from 'graphql-tag'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { HeaderNew } from 'components/HeaderNew'
import { isUuid } from '../fundHelpers'

import { Loading } from 'components'
import { FundRecentActivity } from './FundRecentActivity'

import styles from './FundRecentActivityPage.module.scss'

const GET_FUND_BY_UUID_OR_HANDLE = gql`
  query GetFundByUuidOrHandle($input: FundInputType!) {
    fund(input: $input) {
      handle
      id
      recentFundTransactions {
        id
        amount
        description
        occurredOn
        status
        frequency
        type
        fundBacker {
          id
          name
        }
      }
      upcomingFundTransactions {
        id
        amount
        description
        occurredOn
        status
        frequency
        type
        fundBacker {
          id
          name
        }
      }
      uuid
    }
  }
`

const FundRecentActivityPage = () => {
  const { uuidOrHandle }: { uuidOrHandle: string } = useParams()

  const input = isUuid(uuidOrHandle)
    ? { uuid: uuidOrHandle }
    : { handle: uuidOrHandle }

  const { error, data, loading } = useQuery(GET_FUND_BY_UUID_OR_HANDLE, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const fund = get(data, 'fund')

  if (loading) return <Loading />

  if (error) return <>Error! {error.message}</>

  return (
    <>
      <HeaderNew />
      <div className={styles.container}>
        <FundRecentActivity fund={fund} />
      </div>
    </>
  )
}

export default FundRecentActivityPage
