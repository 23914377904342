import { isEmpty } from 'lodash'
import { ApolloError } from '@apollo/react-hooks'
import { EMAIL_REGEX } from 'constants/regex'

export function validateSsn({ ssn, skip }: { ssn: string; skip?: boolean }) {
  const errors: {
    ssn?: string
  } = {}

  const normalized = normalizeSsn(ssn)

  if (!skip && isEmpty(normalized))
    errors['ssn'] = 'Social Security Number is required'
  if (normalized && normalized.length < 9) {
    errors['ssn'] = 'Social Security Number must be 9 digits'
  }

  return errors
}

export function normalizeSsn(ssn: string) {
  return ssn.replace(/[^0-9]/g, '')
}

export function graphQLErrorHandler(
  setter: (arg0: string) => void,
  cb?: () => void
) {
  return (error: ApolloError) => {
    if (error.graphQLErrors) {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors.length > 0) {
        if (graphQLErrors.find((err) => err.message.includes('maintenance'))) {
          const root = window as any
          root.location.assign('/maintenance')
        } else {
          const message = graphQLErrors.map(({ message }) => message).join(', ')
          // TODO: fix the "Guest YES" hack below when possible
          setter(message.replace(/Guest YES - /, ''))
        }
      } else {
        setter(`Unknown GraphQL error: ${error.toString()}`)
      }
    } else {
      setter(error.toString())
    }
    cb && cb()
  }
}

export function graphQLErrorMessage(error: ApolloError | undefined) {
  if (error) {
    if (error.graphQLErrors) {
      return error.graphQLErrors.map(({ message }) => message).join(', ')
    } else {
      return error.message
    }
  }
  return ''
}

export function validateEmail(email: string) {
  return EMAIL_REGEX.test(String(email).toLowerCase())
}

export const trueCharacterLength = (string: string) => {
  return string.match(/./gu)?.length || 0
}

export const isAllEmojis = (string: string) => {
  const emojisRegExp =
    /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/
  return emojisRegExp.test(string.replace(/\s/g, '')) === true
}
