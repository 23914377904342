import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { get, isString } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Navbar, WarnMesssage } from '@collegebacker/backer-ui/ui'

import GetSubscription from './GetSubscription'
import HasSubscription from './HasSubscription'
import { useBackNavigation } from 'hooks/useBackNavigation'
import { buildPath } from 'routes'
import { Loading } from 'components'
import { DateFormat, removeBodyStyleAttributes } from 'utils'
import { isPro, shouldDisplaySubscription } from 'utils/user'
import userFragments from 'modules/profile/graphqlFragments'
import { TSelectedPlan } from './SelectSubscription'
import { PricingPlan, UserSubscriptionDates } from '../userInterfaces'

import styles from './ManageSubscription.module.scss'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      ...SubscriptionFragmentForEnrollment
      hasPaymentMethods
    }
  }
  ${userFragments.subscriptionForEnrollment}
`

export function ManageSubscription() {
  const history = useHistory()

  const backButtonClick = useBackNavigation()
  const { loading, data, error } = useQuery(CURRENT_USER)

  const currentUser = get(data, 'currentUser')

  const {
    pricingPlan,
    pricingCohort,
    hasPaymentMethods,
    subscriptionFrequency,
    subscriptionPaidUntil,
    subscriptionDueOn
  } = currentUser || {}

  const userHasPro = isPro({ pricingPlan })

  const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>('annualPro')

  useEffect(() => {
    if (isString(pricingCohort) && !shouldDisplaySubscription(currentUser)) {
      history.replace(buildPath('dashboard'))
    }
  }, [currentUser, pricingCohort])

  useEffect(() => {
    if (pricingPlan === 'backer-pro' && subscriptionFrequency === 'monthly') {
      setSelectedPlan('monthlyPro')
    }
  }, [pricingPlan, subscriptionFrequency])

  if (loading) return <Loading />
  if (error)
    return (
      <WarnMesssage className="mb-40" text={error.message} type="warning" />
    )

  return (
    <>
      <Navbar backButtonClick={backButtonClick} />
      <div className={styles.container}>
        {userHasPro ? (
          <HasSubscription
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            currentUser={currentUser}
            onSuccess={(userData: UserSubscriptionDates) => {
              const subscriptionPaidUntil = userData.subscriptionPaidUntil
              const subscriptionDueOn = userData.subscriptionDueOn
              history.push({
                pathname: buildPath('dashboard'),
                state: {
                  subscriptionModalText: getSubscriptionModalText({
                    pricingPlan,
                    selectedPlan,
                    subscriptionPaidUntil,
                    subscriptionDueOn
                  })
                }
              })
              removeBodyStyleAttributes()
            }}
            onCancel={() => {
              history.push({
                pathname: buildPath('dashboard')
              })
              removeBodyStyleAttributes()
            }}
          />
        ) : (
          <GetSubscription
            {...(!hasPaymentMethods && {
              override: () => {
                history.push({
                  pathname: buildPath('addPaymentMethod'),
                  search: `?selectedPricingPlan=${selectedPlan}`
                })
              }
            })}
            onSuccess={() => {
              history.push({
                pathname: buildPath('dashboard'),
                state: {
                  subscriptionModalText: getSubscriptionModalText({
                    pricingPlan,
                    selectedPlan,
                    subscriptionPaidUntil,
                    subscriptionDueOn
                  })
                }
              })
            }}
            currentUser={currentUser}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        )}
      </div>
    </>
  )
}

export function getSubscriptionModalText({
  pricingPlan,
  selectedPlan,
  subscriptionPaidUntil,
  subscriptionDueOn
}: {
  pricingPlan: PricingPlan
  selectedPlan: TSelectedPlan
  subscriptionPaidUntil: Date
  subscriptionDueOn: Date
}) {
  const formattedSubscriptionPaidUntil = DateFormat.short(subscriptionPaidUntil)
  const formattedSubscriptionDueOn = DateFormat.short(subscriptionDueOn)

  if (pricingPlan === 'backer-pro' && selectedPlan === 'free') {
    if (!subscriptionPaidUntil) {
      return `Your membership has been cancelled.`
    }
    return `You will retain your Backer Pro benefits through ${formattedSubscriptionPaidUntil}.`
  } else if (
    pricingPlan === 'backer-pro' &&
    (selectedPlan === 'annualPro' || selectedPlan === 'monthlyPro')
  ) {
    return `Your next billing date is ${formattedSubscriptionDueOn}.`
  } else {
    return 'You now have all of the benefits of Backer Pro.'
  }
}
