import classNames from 'classnames'
import moment from 'moment'
import { SwitchSelector } from '@collegebacker/backer-ui/ui'
import { DigitalWalletType, PaymentMethodType } from '../types'

import {
  activationDateValue,
  firstDayValue,
  frequencyValue
} from '../LocalState'
import styles from './NewPaymentMethod.module.scss'

export const NewPaymentMethod = ({
  children,
  view,
  setView,
  digitalWalletType,
  activeIndex,
  managePaymentMethods
}: {
  children: any
  view: PaymentMethodType
  setView: (arg0: typeof view) => void
  digitalWalletType?: DigitalWalletType
  activeIndex?: number,
  managePaymentMethods?: boolean
}) => {

  const activationDate = activationDateValue()
  const firstDay = firstDayValue()
  const frequency = frequencyValue()

  const isToday =
    frequency === 'onetime'
      ? moment(activationDate).isSame(new Date(), 'day')
      : moment().date() === firstDay

  return (
    <div
      className={classNames(styles.container, {
        [styles.managePaymentMethods]: managePaymentMethods
      })}
    >
      <SwitchSelector
        className={styles.switch}
        activeIndex={activeIndex}
        options={[
          {
            label: 'Bank',
            value: 'bank-account'
          },
          {
            label: 'Card',
            value: 'credit-card'
          },
          ...(digitalWalletType && isToday
            ? [
                {
                  label: '',
                  value: 'digital-wallet',
                  paymentIcon:
                    digitalWalletType === 'applePay'
                      ? 'apple-pay'
                      : ('g-pay' as PaymentLogoTypes)
                }
              ]
            : [])
        ]}
        onChange={(option: SwitchSelectorOptionType) => {
          setView(option.value as PaymentMethodType)
        }}
      />
      <div className={styles.body}>{children}</div>
    </div>
  )
}
