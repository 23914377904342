import ReactCanvasConfetti from "react-canvas-confetti"

export const Confetti = ({ fireConfetti }: { fireConfetti: boolean }) => {
  return (
    <ReactCanvasConfetti
      fire={fireConfetti}
      spread={100}
      ticks={300}
      startVelocity={70}
      particleCount={150}
      scalar={0.8}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        zIndex: -1,
      }}
    />
  )
}