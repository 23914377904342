import moment from 'moment'
import { NumberFormat } from 'utils/format'
import styles from './SummaryHeader.module.scss'
import frame from 'static/img/frame.webp'
import { getOrdinalNumber, getFrequencyLabel } from 'utils/helpers'
import { activationDateValue, amountValue, beneficiaryNameValue, firstDayValue, frequencyValue, secondDayValue } from "../LocalState"
import { Frequency } from '../types'

export const SummaryHeader = ({
  fund,
  isStarterGiftFlow
}: {
  fund?: {
    fundName: string
    giftingPage: {
      photoUrl: string
    }
  },
  isStarterGiftFlow?: boolean
}) => {
  const {
    fundName,
    giftingPage: {
      photoUrl
    }
  } = fund || { giftingPage: {} }
  const amount = amountValue()
  const frequency = frequencyValue()
  const activationDate = activationDateValue()
  const firstDay = firstDayValue()
  const secondDay = secondDayValue()
  const beneficiaryName = beneficiaryNameValue()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>
          {isStarterGiftFlow ? "Gift summary" : "Contribution summary"}
        </p>
      </div>
      <div className={styles.body}>
        <div>
          <p className={styles.title}>
            {NumberFormat.currencyFromCents(amount, false, true)}{' '}
            {getFrequencyLabel(frequency)}
          </p>
          <p 
            className={styles.text}
          >
            for {fundName || beneficiaryName}
          </p>
          <p className={styles.dateLabel}>
            {getDateLabel({frequency, activationDate, firstDay, secondDay})}
          </p>
        </div>
        {photoUrl && (
          <div className={styles.polaroidContainer}>
            <img
              src={frame}
              alt=""
              className={styles.frame}
            />
            <img
              src={photoUrl}
              alt=""
              className={styles.polaroidImage}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const getDateLabel = ({
  frequency,
  activationDate,
  firstDay,
  secondDay
}:{
  frequency: Frequency,
  activationDate: Date,
  firstDay: number,
  secondDay: number
}) => {
  const date = moment(activationDate)
  if (frequency === 'monthly') {
    return `on the ${getOrdinalNumber(firstDay)} of each month`
  } else if (frequency === 'semimonthly') {
    return `on the ${getOrdinalNumber(firstDay)} and ${getOrdinalNumber(secondDay)} of each month`
  } else if (frequency === 'annually') {
    return `on ${date.format('MMM D')} of each year`
  }
  return `on ${date.format('MMM D, YYYY')}`
}