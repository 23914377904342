import gql from 'graphql-tag'

import globalFragments from '../global/graphqlFragments'
import userFragments from 'modules/profile/graphqlFragments'

const fund = gql`
  fragment FundFragment on Fund {
    ...EnrollmentSyncFundFragment
    id
    uuid
    handle
    fundName
    pendingBalance
    settledBalance
    upgradeStatus
  }
  ${globalFragments.enrollmentSyncFund}
`

const fundDraft = gql`
  fragment FundDraftFragment on FundDraft {
    id
    currentOnboardingStep
    currentFlow
    beneficiaryName
    beneficiaryFirstName
    beneficiaryMiddleName
    beneficiaryLastName
    beneficiaryBornOn
    prebirth
    expectedBirthOn
    beneficiarySsn
    beneficiaryGender
    beneficiaryPhysicalAddress
    beneficiaryPhysicalAddressCity
    beneficiaryPhysicalAddressState
    beneficiaryPhysicalAddressStreet
    beneficiaryPhysicalAddressStreet2
    beneficiaryPhysicalAddressZip
    ownerName
    ownerFirstName
    ownerMiddleName
    ownerLastName
    ownerBornOn
    ownerRelationship
    ownerSsn
    ownerGender
    useSamePhysicalAddress
    ownerPhysicalAddress
    ownerPhysicalAddressCity
    ownerPhysicalAddressState
    ownerPhysicalAddressStreet
    ownerPhysicalAddressStreet2
    ownerPhysicalAddressZip
    useSeparateMailingAddress
    ownerMailingAddress
    ownerMailingAddressCity
    ownerMailingAddressState
    ownerMailingAddressStreet
    ownerMailingAddressStreet2
    ownerMailingAddressZip
    ownerPhone
    householdIncome
    riskLevel
    integratedPlanId
    investmentOptionId
    userAgreedToTerms
    voucherCode
    fundName
    fund {
      ...FundFragment
    }
  }
  ${fund}
`

const currentUser = gql`
  fragment CurrentUserFragment on User {
    id
    uuid
    identityVerified
    countFutureBabyFunds
    stateTaxStatus
    funds {
      id
      fundName
      uuid
    }
    ...SubscriptionFragmentForEnrollment
    currentFundDraft {
      ...FundDraftFragment
    }
  }
  ${fundDraft}
  ${userFragments.subscriptionForEnrollment}
`

// Fragment needed for desktop enrollment modal on dashboard and fund page
const enrollmentModal = gql`
  fragment EnrollmentModalFundFragment on Fund {
    enrollmentStatus
    establishmentUrl
    fundOwner {
      id
      currentFundDraft {
        id
        currentFlow
      }
      ...SubscriptionFragmentForEnrollment
    }
    handle
    pendingBalance
    plan {
      id
    }
    portfolio
    programManager
    settledBalance
    uuid
  }
  ${userFragments.subscriptionForEnrollment}
`

const fragments = { currentUser, fundDraft, fund, enrollmentModal }
export default fragments
