import { WarnMesssage } from '@collegebacker/backer-ui/ui'
import { NumberFormat } from 'utils/index'
import { BankAccount, CreditCard, Frequency } from '../types'

export const creditCardLimits: any = {
  semimonthly: 250_00,
  monthly: 500_00,
  onetime: 500_00,
  annually: 500_00,
}

export const creditCardWarningText = (frequency: Frequency) => {
  return `${NumberFormat.currencyFromCents(creditCardLimits[frequency], false)}
    ${frequency} contribution limit for
    credit and debit cards`
}

export const CreditCardLimitWarning = ({
  frequency
} : {
  frequency: Frequency
}) => {
  return (
    <WarnMesssage type="warning" text={creditCardWarningText(frequency)} />
  )
}

export const isCreditCardAmountTooHigh = ({
  paymentMethod,
  amount,
  frequency,
  doNotCheckForBA
}: {
  paymentMethod: {
    bankAccount?: BankAccount | null
    creditCard?: CreditCard | null
  } | null
  amount: number
  frequency: Frequency
  doNotCheckForBA?: boolean
}) => {
  const { bankAccount } = paymentMethod || {}
  const amountTooHigh = amount > creditCardLimits[frequency]

  if (doNotCheckForBA && amountTooHigh) {
    return true
  }
  if (!bankAccount && amountTooHigh) return true
  return false
}
