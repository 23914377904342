import { Icon, ListItem } from '@collegebacker/backer-ui/ui'

export const SelectPaymentMethodListItem = ({
  disabled,
  checked,
  onClick,
  description,
  title,
  chevron = false,
  leftContent = <Icon name={checked ? 'tickmark-in-circle' : 'empty-circle'} />,
  icon,
  topLabel,
  divider,
  middleContentClassName,
  middleContentPaddingRight
}: {
  disabled?: boolean
  checked?: boolean
  onClick: () => void
  description: string
  title: string
  chevron?: boolean
  leftContent?: React.ReactNode
  icon: 'bank-account' | 'credit-card'
  topLabel?: React.ReactNode
  divider?: boolean
  middleContentClassName?: string
  middleContentPaddingRight?: number
}) => {

  return (
    <ListItem
      chevron={chevron}
      topLabel={topLabel}
      disabled={disabled}
      title={title}
      description={description}
      rightContent={<Icon name={icon} />}
      leftContent={leftContent}
      onClick={onClick}
      divider={divider}
      middleContentClassName={middleContentClassName}
      middleContentPaddingRight={middleContentPaddingRight}
    />
  )
}
