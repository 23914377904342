import { Stripe, loadStripe } from '@stripe/stripe-js'
import config from '../config'
import gql from 'graphql-tag'
import { getClient } from 'utils/apollo-client'
import { get } from 'lodash'

const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation CreateStripePaymentIntent(
    $input: CreateStripePaymentIntentInputType!
  ) {
    createStripePaymentIntent(input: $input) {
      clientSecret
    }
  }
`

let stripePromise: Promise<Stripe | null>

export function getStripe() {
  if (!stripePromise) {
    stripePromise = loadStripe(config.STRIPE_KEY)
  }
  return stripePromise
}

export async function digitalWalletAvailable(
  setter: (arg0: 'googlePay' | 'applePay' | '') => void
): Promise<void> {
  const stripe = await getStripe()
  const paymentRequest = stripe?.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: { label: 'Some Payment Label', amount: 2000 },
    requestPayerName: true,
    requestPayerEmail: true,
  })

  paymentRequest
    ?.canMakePayment()
    .then((result: any) => {
      if (!result) setter('')
      else if (result.applePay) setter('applePay')
      else setter('googlePay')
    })
    .catch(console.error)
}

export async function chargeCreditCard({
  userUuid,
  amount,
  contributionDraft,
}: {
  userUuid: string
  amount: number
  contributionDraft: any
}) {
  const creditCard = contributionDraft.creditCard
  const paymentMethod = creditCard.stripePaymentMethod
  const response = await createPaymentIntent(
    userUuid,
    amount,
    contributionDraft.id,
    creditCard.id
  )
  const secret = get(response, 'data.createStripePaymentIntent.clientSecret')
  const stripe = await getStripe()
  return stripe?.confirmCardPayment(secret, { payment_method: paymentMethod })
}

async function createPaymentIntent(
  userUuid: string,
  amount: number,
  contributionDraftId: number,
  creditCardId: number
) {
  return getClient().mutate({
    mutation: CREATE_STRIPE_PAYMENT_INTENT,
    variables: {
      input: { userUuid, amount, contributionDraftId, creditCardId },
    },
  })
}
