import { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize'

import { generateClasses } from 'utils/helpers'
import classNames from 'classnames'
import { WarnMesssage } from '@collegebacker/backer-ui/ui';
import './InputNew.scss'
import './InputTextarea.scss'

export const InputTextarea = ({
  className,
  modifier,
  disabled,
  autoFocus,
  errorMessage,
  label,
  placeholder = '',
  largePlaceholder,
  onChange,
  value,
  minRows = 1,
  maxLength,
  maxRows = 14,
  onReset,
  tabIndexTextArea,
  tabIndexResetButton,
  onKeyDown
}: Props) => {
  const textareaEl = useRef<HTMLTextAreaElement>()
  const onContainerClick = () => {
    textareaEl && textareaEl.current && textareaEl.current.focus()
  }
  return (
    <div
      className={classNames('input-new__container', {

      })}
    >
      <div
        onClick={onContainerClick}
        className={classNames(`${generateClasses('input-textarea', modifier)}`, 'input-new', className, {
          disabled
        })}
      >
        <div className="input-new__label-container">
          <label className="typo-app-body-caption input-new__label">{label}</label>
          <p className="input-new__placeholder">{placeholder}</p>
        </div>
        <TextareaAutosize
          placeholder={largePlaceholder}
          className={`${generateClasses('input-textarea__input', modifier)} input-new__input`}
          disabled={disabled}
          maxLength={maxLength}
          maxRows={maxRows}
          minRows={minRows}
          onChange={onChange}
          //@ts-ignore
          ref={textareaEl}
          value={value}
          autoFocus={autoFocus}
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          tabIndex={tabIndexTextArea}
          onKeyDown={onKeyDown}
        />
        {onReset && (
          <button
            tabIndex={tabIndexResetButton}
            className="input-textarea__reset-btn"
            onClick={() => onReset()}
            type="button"
          >
            Reset message
          </button>
        )}
      </div>
      <WarnMesssage type='error' text={errorMessage} />
    </div>
  )
}

interface Props {
  className?: string
  modifier?: string
  disabled?: boolean
  autoFocus?: boolean
  errorMessage?: string
  label?: string
  onChange?: (e: any) => void
  onKeyDown?: (e: any) => void
  placeholder?: string
  largePlaceholder?: string
  value: string
  minRows?: number
  maxLength?: number
  maxRows?: number
  onReset?: () => void
  tabIndexTextArea?: number
  tabIndexResetButton?: number
}