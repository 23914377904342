import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'
import classNames from 'classnames'

import styles from './InputStripe.module.scss'

export const InputStripe: React.FunctionComponent<Props> = ({
  className,
  onChange,
  options,
  errorMessage
}) => {
  const [isFocused, setIsFocused] = React.useState(false)

  return (
    <div className={styles.wrap}>
      <CardElement
        className={classNames(styles.stripeInput, className, {
          [styles.error]: errorMessage,
          [styles.focused]: isFocused && !errorMessage,
          [styles.focusedError]: isFocused && errorMessage
        })}
        id="card-number-element"
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        options={{
          style: {
            base: {
              color: 'var(--color-main-500)',
              fontFamily:
                'ABCMarfaRegular, Helvetica Neue, Helvetica, sans-serif',
              letterSpacing: '0.025em',
              fontSize: '16px'
            },
            invalid: {
              color: 'var(--color-service-error-main)',
              iconColor: 'var(--color-service-error-main)'
            }
          },
          ...options
        }}
      />
      {errorMessage && (
        <span
          className={`typo-app-body-caption ${styles.errorText}`}
          role="alert"
        >
          {errorMessage}
        </span>
      )}
    </div>
  )
}

interface Props {
  className?: string
  onChange: (event: any) => void
  options: StripeCardElementOptions
  errorMessage: string | null
}
