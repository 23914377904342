import { Modal, Button, Stack } from '@collegebacker/backer-ui/ui'

import config from '../../../config'

import styles from './PaymentFailureModal.module.scss'

const PaymentFailureModal = ({ errorMessage, isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onCloseClick={onClose}>
      <PaymentFailureContent
        errorMessage={errorMessage}
        close={() => {
          onClose()
        }}
      />
    </Modal>
  )
}

interface Props {
  errorMessage?: string
  isOpen: boolean
  onClose: () => void
}

export default PaymentFailureModal

export const PaymentFailureContent = ({
  errorMessage,
  close
}: {
  errorMessage?: string
  close: () => void
}) => {
  return (
    <div className={styles.container}>
      <div>
        <h2 className="typo-app-title-medium-adaptive">
          We had trouble processing your payment
        </h2>
        <p className="typo-app-body-large">😞</p>
        {errorMessage && <p className={styles.text}>{errorMessage}</p>}
        <p className={styles.textBottom}>
          Please try again or reach out to support. We'll do our best to help!
        </p>
      </div>
      <Stack spacing={10}>
        <Button
          label="Back to payment"
          onClick={close}
          mode="outline"
          minWidth={335}
        />
        <Button
          tag="a"
          rel="noopener noreferrer"
          href={config.HELP_URL}
          target="_blank"
          label="Need help? Contact support"
          mode="ghost"
          minWidth={335}
        />
      </Stack>
    </div>
  )
}
