import Cookies from 'js-cookie'
import gql from 'graphql-tag'

import { Cookie, CookieNames } from '../cookie'
import { isMobileApp } from 'utils/index'
import segment from 'utils/segment'
import config from '../config'
import { graphQLErrorMessage } from './validation'

const PARAM_NAMES: CookieNames[] = [
  'inviteUUID',
  'referralCode',
  'referralUUID',
  'origin',
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'tracking',
]

const GUEST_COOKIE_NAME = 'guest'
const INVITE_COOKIE_NAME = 'inviteUUID'

//
// GraphQL fragment used to normalize how we fetch
// authenticated user data (signins/signups).
//
export const authenticatedUserFragment = gql`
  fragment userFragment on User {
    id
    uuid
    email
    firstName
    middleName
    lastName
    variant
    photoUrl
    countFunds
    countContributions
    followedFunds {
      id
      handle
      uuid
    }
    funds {
      id
      handle
      uuid
    }
    gifts {
      id
      fund {
        id
        handle
        uuid
      }
    }
  }
`

function persistToken(token: string) {
  Cookie.cdSet('authorization', token, {
    path: '/',
    expires: 90,
    domain: config.COOKIE_DOMAIN,
  })
}

export function clearOutRegistrationCookies() {
  for (const name of PARAM_NAMES) {
    Cookie.delete(name)
  }
}

export function isAuthenticated() {
  return !!Cookies.get('authorization') && Cookies.get(GUEST_COOKIE_NAME) !== 'yes'
}

export function setRegistrationAuthenticated({
  token,
  isGuest,
  done
}: {
  token: string,
  isGuest: boolean,
  done: () => void
}) {
  sessionStorage.justSignedUp = true
  persistToken(token)
  clearOutRegistrationCookies()
  handleAuthorization().then(() => {
    if (isGuest) {
      Cookie.cdSet(GUEST_COOKIE_NAME, 'yes', { domain: config.COOKIE_DOMAIN })
    } else {
      Cookie.delete(GUEST_COOKIE_NAME)
    }
    done()
  })
}

export function setSignInAuthenticated(
  token: string,
  acceptManagerInvite: Function | null,
  done: () => void
) {
  sessionStorage.justSignedUp = false
  persistToken(token)
  handleAuthorization().then(async () => {
    if (acceptManagerInvite) {
      await acceptManagerIfInvited(acceptManagerInvite)
    }
    Cookie.delete(GUEST_COOKIE_NAME)
    done()
  })
}

export async function acceptManagerIfInvited(mutation: Function) {
  const inviteUuid = Cookie.get(INVITE_COOKIE_NAME)
  
  if (inviteUuid) {
    try {
      const result = await mutation({ variables: { input: { inviteUuid } } })
      Cookie.delete(INVITE_COOKIE_NAME)
      return result
    } catch (error: any) {
      window.alert(graphQLErrorMessage(error))
    }
  }
}

async function handleAuthorization(): Promise<void> {
  const userJson = await Cookie.getVisitor()

  // @ts-ignore
  const { firstName, lastName, email, uuid } = userJson
  const displayName = [firstName, lastName].filter(v => v).join(' ')

  // Identify user in Segment
  if (userJson) {
    // segment?.identify({
    //   uuid: userJson.uuid,
    //   email: userJson.email,
    //   firstName: userJson.firstName,
    //   lastName: userJson.lastName,
    //   createdAt: new Date(),
    // })
  }

  // Identify user in FullStory
  if (config.env === 'production' && userJson) {
    const fullStory = (window as any).FS
    if (fullStory) {
      fullStory.identify(uuid, {
        displayName,
        appType: isMobileApp() ? 'mobile' : 'web',
        email,
      })
    }
  }

  // Identify user in ZenDesk
  const zE = (window as any).zE
  if (zE) {
    zE(function () {
      zE.identify({
        name: displayName,
        email,
      })
    })
  }
}

const authError = 'Authentication is required'
export function hasAuthenticationError(error: any) {
  if (error.graphQLErrors) {
    return error.graphQLErrors.some((e: any) => e.message === authError)
  } else {
    return false
  }
}
