import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import * as AbsintheSocket from '@absinthe/socket'
import { createLink as createUploadLink } from 'apollo-absinthe-upload-link'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { hasSubscription } from '@jumpn/utils-graphql'
import fetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'

import config from '../config'
import { Socket as PhoenixSocket } from 'phoenix'

const httpLink = createUploadLink({
  fetch,
  uri: config.GRAPHQL_API_URL
})

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('authorization')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const absintheSocketLink: any = createAbsintheSocketLink(
  AbsintheSocket.create(
    new PhoenixSocket(config.GRAPHQL_WS_URL, {
      params: () => {
        const token = Cookies.get('authorization')
        if (token) return { token }
        else return {}
      },
    }),
  ),
)

const link = split(
  operation => hasSubscription(operation.query),
  absintheSocketLink,
  authLink.concat(httpLink),
)

let apolloClient: any = null

export const getClient = () => apolloClient
    
const client = (initialState: any) => {
  apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache().restore(initialState || {}),
  })
  return apolloClient
}

export const newClient = client({})
