import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

// Hook for back navigation
export const useBackNavigation = () => {
  const history = useHistory()

  // Memoized back navigation function
  const navigateBack = useCallback(() => {
    history.goBack()
  }, [history])

  return navigateBack
}
