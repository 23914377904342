import { LocalStorage } from 'LocalStorage'
import { EnrollmentStatus, Prebirth } from 'modules/fund/fundInterfaces'
import { MY529_PLAN_ID } from 'constants/plans'
import { isPro, shouldDisplaySubscription } from 'utils/user'
import { UserSubscriptionForEnrollment } from 'modules/profile/userInterfaces'

export function alreadyUpgradedToProBeforeEnrollment() {
  const hasProKey = LocalStorage.getItem('hasPro')
  return hasProKey === 'true'
}

export function addHasProKey(currentUser: UserSubscriptionForEnrollment) {
  const { pricingPlan } = currentUser || {}

  if (!shouldDisplaySubscription(currentUser)) return
  if (isPro({ pricingPlan })) {
    LocalStorage.setItem('hasPro', 'true')
  }
}

export function removeHasProKey() {
  LocalStorage.removeItem('hasPro')
}

export function hasInitialBalanceRequirement(fundUuid: string): boolean {
  const hasRequiredContributionKey = LocalStorage.getItem(
    'hasRequiredContribution'
  )

  if (!hasRequiredContributionKey) return false

  if (JSON.parse(hasRequiredContributionKey).includes(fundUuid)) {
    return true
  }
  return false
}

export function removeUuidFromHasRequiredContributionKey(uuid: string): void {
  const hasRequiredContributionKey = LocalStorage.getItem(
    'hasRequiredContribution'
  )
  if (!hasRequiredContributionKey) return

  const listOfUuids = JSON.parse(hasRequiredContributionKey)
  if (!listOfUuids.includes(uuid)) return

  const length = listOfUuids.length
  if (length > 1) {
    const index = listOfUuids.indexOf(uuid)
    if (index > -1) {
      listOfUuids.splice(index, 1)
      LocalStorage.setItem(
        'hasRequiredContribution',
        JSON.stringify(listOfUuids)
      )
    }
  } else {
    LocalStorage.removeItem('hasRequiredContribution')
  }
}

export function addUuidToHasRequiredContributionKey(fund: {
  pendingBalance?: number
  settledBalance?: number
  uuid: string
}): void {
  const { pendingBalance, settledBalance, uuid } = fund
  let hasRequiredContribution = false
  if (
    typeof pendingBalance === 'number' &&
    typeof settledBalance === 'number'
  ) {
    if (pendingBalance + settledBalance >= 2500) {
      hasRequiredContribution = true
    }
  }

  if (hasRequiredContribution) {
    const hasRequiredContributionKey = LocalStorage.getItem(
      'hasRequiredContribution'
    )
    if (hasRequiredContributionKey) {
      const listOfUuids = JSON.parse(hasRequiredContributionKey)
      if (!listOfUuids.includes(uuid)) {
        listOfUuids.push(uuid)
        LocalStorage.setItem(
          'hasRequiredContribution',
          JSON.stringify(listOfUuids)
        )
      }
    } else {
      LocalStorage.setItem('hasRequiredContribution', JSON.stringify([uuid]))
    }
  }
}

export function showSubscriptionStep(
  currentUser: UserSubscriptionForEnrollment
): boolean {
  if (!shouldDisplaySubscription(currentUser)) {
    return false
  }

  // Skip subscription if already upgraded, here we use local storage to check
  // if the user has already upgraded to pro before starting enrollment instead of pricingPlan so the
  // number of flow steps doesn't change after selecting pricingPlan on the subscription step
  if (alreadyUpgradedToProBeforeEnrollment()) {
    return false
  }

  return true
}

export function getTotalSteps(
  currentUser: UserSubscriptionForEnrollment,
  prebirth: Prebirth
) {
  let totalSteps = prebirth === 'current' ? 5 : 6
  if (!showSubscriptionStep(currentUser)) {
    totalSteps = totalSteps - 1
  }
  return totalSteps
}

export function isMy529(plan: { id: string }) {
  return plan.id === MY529_PLAN_ID
}

export function isApplicationSubmitted(enrollmentStatus: EnrollmentStatus) {
  return (
    enrollmentStatus === 'enrolled' ||
    enrollmentStatus === 'error' ||
    enrollmentStatus === 'requires_account_number' ||
    enrollmentStatus === 'requires_activation' ||
    enrollmentStatus === 'unknown'
  )
}
