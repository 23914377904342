// @ts-nocheck

import { validate } from 'class-validator'

export interface ValidatorOptions {
  skipMissingProperties: false
  forbidNonWhitelisted: true
  forbidUnknownValues: true
}

export abstract class BaseValueObject {
  private _errors = []

  private readonly options: ValidatorOptions = {
    skipMissingProperties: false,
    forbidNonWhitelisted: true,
    forbidUnknownValues: true,
  }

  get errors() {
    return this._errors
  }

  equals(instance: BaseValueObject): boolean {
    return JSON.stringify(this) === JSON.stringify(instance)
  }

  async isValid(options?: ValidatorOptions) {
    await this.validate(options)
    return !this.errors.length
  }

  private async validate(options?: ValidatorOptions) {
    this._errors = await validate(this, {
      ...this.options,
      ...options,
    })
    if (this._errors.length > 0) {
      console.log('validation failed. errors: ', this._errors)
    } else {
      console.log('validation succeed')
    }
    return this._errors
  }
}
