import gql from 'graphql-tag'

// Fragment needed for subscriptions UI, whether it should be displayed or not
const subscriptionDisplayStatus = gql`
  fragment SubscriptionDisplayStatusFragment on User {
    id
    partnerName
    pricingCohort
  }
`

// Fragment needed for subscriptions UI in enrollment flow
const subscriptionForEnrollment = gql`
  fragment SubscriptionFragmentForEnrollment on User {
    pricingPlan
    countEstablishedFunds
    subscriptionDueOn
    subscriptionPaidUntil
    subscriptionFrequency
    ...SubscriptionDisplayStatusFragment
  }
  ${subscriptionDisplayStatus}
`
const fragments = { subscriptionForEnrollment, subscriptionDisplayStatus }
export default fragments
