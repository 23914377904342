import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks'
import { get, defaults } from 'lodash'

/**
 * Listen to live updates to fund props.
 * The query provided is the inner GraphQL query.
 * No default value provided, so the props will we undefined until a change happens
 *
 * Example:
 *   const { fundName } = useFundSubscriptionWithQueryString('123', '{fundName}')
 */
export function useFundSubscriptionWithQueryString(uuidParam: any, query: any) {
  const uuid = uuidParam || -1

  const FUND_SUBSCRIPTION = gql`
  subscription fund($input: FundInputType!) {
    fund(input: $input) ${query}
  }`

  const response = useSubscription(FUND_SUBSCRIPTION, {
    variables: { input: { uuid } },
  })

  const { loading, error, data } = response

  return get(data, 'fund', {})
}

/**
 * Listen to live updates to fund props.
 * The query is a map of the parameters required and their default value.
 * If a default value is provided it will be returned until a new updated happens.
 * Then the udpated value will replace the default one.
 *
 * Example:
 *   const { fundName } = useFundSubscription('123', {fundName: 'defaultFundName'})
 */
export function useFundSubscription(uuid: any, queryWithDefaults: any) {
  const queryParams = Object.keys(queryWithDefaults)
  const queryString = `{${queryParams.join(' ')}}`

  return defaults(
    useFundSubscriptionWithQueryString(uuid, queryString),
    queryWithDefaults,
  )
}
