export type ProgramManager = 'cyp' | 'my529' | 'safe' | 'nc529'

export type FundTodo = {
  id: number
  type: 'fundSetup' | 'fundSocial' | 'fundInvest'
  completed: boolean
  manualTodoCompleted: boolean
}

export type Prebirth = 'current' | 'never' | 'previous'

export type UpgradeStatus = 'completed' | 'pending' | 'processing'

export type RiskLevel =
  | 'maximizing_gains_and_minimizing_losses'
  | 'minimizing_losses'
  | 'maximizing_gains'

export type LpoaStatus =
  | 'qualified'
  | 'upload-error'
  | 'pending'
  | 'completed'
  | 'inactive'

  export type Portfolio = 
  | '20-80-conservative'
  | '40-60-moderate'
  | '60-40-balanced'
  | '70-equity-30-fixed-income'
  | '80-20-aggressive'
  | 'age-based-aggressive-global'
  | 'age-based-conservative'
  | 'age-based-moderate'
  | 'customized-age-based'
  | 'customized-static'
  | 'custom-100-vitpx'
  | 'fdic-insured'
  | 'fixed-income'
  | 'enrolled'
  | 'equity-30-international'
  | 'equity-100-domestic'
  | 'global-equity-70-30-us-international'
  | 'global-equity-90-10-us-international'
  | 'stable-value'
  | 'target-enrollment-2026-2027'
  | 'target-enrollment-2028-2029'
  | 'target-enrollment-2032-2033'
  | 'target-enrollment-2024-2025'
  | 'target-enrollment-2036-2037'
  | 'target-enrollment-2040-2041'
  | 'target-enrollment-2020-2021'
  | 'target-enrollment-2030-2031'
  | 'target-enrollment-2022-2023'
  | 'target-enrollment-2038-2039'
  | 'target-enrollment-2034-2035'
  | 'total-us-stock-market'
  | 'us-total-stock-market'
  
export const listOfEnrollmentStatusValues = [
  'not_connected',
  'resume_setup',
  'requires_activation',
  'requires_account_number',
  'error',
  'resume_ssn',
  'enrolled',
  'unknown'
] as const

export type EnrollmentStatus = (typeof listOfEnrollmentStatusValues)[number]
