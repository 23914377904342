import React, { useRef, useState } from "react"

import { generateClasses } from 'utils/helpers'
import classNames from "classnames"
import config from "../../config"

import "./InputNew.scss"
import "./InputShareableUrl.scss"

export const InputShareableUrl = ({
  modifier,
  label,
  disabled,
  onChange,
  onKeyDown,
  value,
  helper,
}: Props) => {
  const [editingHandle, setEditingHandle] = useState(false)
  const { helperMessage, error } = helper
  const inputEl = useRef<HTMLInputElement>(null)
  const onContainerClick = () => {
    setEditingHandle(true)
    inputEl.current && inputEl.current.focus()
  }
  return (
    <div
      className={classNames(
        "input-shareable-url__container",
        `${generateClasses("input-new__container", modifier)}`,
        {
          error,
        }
      )}
    >
      <div
        onClick={onContainerClick}
        className={classNames(
          "input-new",
          `${generateClasses("input-shareable-url", modifier)}`,
          {
            disabled,
          }
        )}
      >
        <div className="input-new__label-container">
          <label className="input-new__label">{label}</label>
        </div>
        <div className="fake-input-text">
          <span className="input-shareable-url__handle">
            {config.BACKEND_ROOT_URL.replace(/https?:\/\//, "")}/
          </span>
          <input
            autoComplete="off"
            autoCapitalize="off"
            name="handle"
            value={value}
            onBlur={() => setEditingHandle(false)}
            onChange={onChange}
            onKeyDown={onKeyDown}
            className={classNames("input-shareable-url__input", {
              editing: editingHandle,
            })}
            ref={inputEl}
          />
        </div>
      </div>
      <p
        className={classNames("input-shareable-url__helper", {
          error,
        })}
      >
        {helper ? helperMessage : "&nbsp;	&nbsp;	&nbsp;"	}
      </p>
    </div>
  )
}

interface Props {
  modifier?: string
  label: string
  disabled?: boolean
  value?: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  helper: {
    helperMessage: string
    error: boolean
  }
}
