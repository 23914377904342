import moment from 'moment'
import { LabelTag } from '@collegebacker/backer-ui/ui'
import { TSelectedPlan } from './SelectSubscription'
import styles from './SubscriptionHeader.module.scss'

export const SubscriptionHeader = ({
  selectedPricingPlan
}: {
  selectedPricingPlan: TSelectedPlan
}) => {
  const annualPlan = selectedPricingPlan === 'annualPro'
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Add a payment method</p>
      </div>
      <div className={styles.body}>
        <div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>
              {annualPlan ? '$48 per year' : '$6 per month'}
            </p>
            {annualPlan && (
              <LabelTag
                label="32% off"
                color="success"
                size="small"
                className={styles.labelTag}
              />
            )}
          </div>
          <p className={styles.text}>Backer Pro subscription</p>
          <p className={styles.dateLabel}>
            {getDateLabel({ selectedPricingPlan })}
          </p>
        </div>
        <div className={styles.gradientCircle}>
          <p>Backer Pro</p>
        </div>
      </div>
    </div>
  )
}

const getDateLabel = ({
  selectedPricingPlan
}: {
  selectedPricingPlan: TSelectedPlan
}) => {
  if (selectedPricingPlan === 'monthlyPro') {
    return `on the ${moment().add(7, 'days').format('Do')} of each month`
  } else if (selectedPricingPlan === 'annualPro') {
    return `on ${moment().format('MMM D')} of each year`
  }
}
