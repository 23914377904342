import { useEffect } from 'react'

export function cacheSafeSkeleton(queryResult: any, skeletonData: any) {
  if (!queryResult?.data && (queryResult?.loading || testingSkeleton())) {
    queryResult.data = skeletonData
  }
  return queryResult
}

export function applySkeleton(queryResult: any, skeletonData: any, skipSkeleton = false) {
  if (!skipSkeleton && (queryResult.loading || testingSkeleton())) {
    queryResult.data = skeletonData
  }
  return queryResult
}

export function testingSkeleton() {
  if (typeof window === 'undefined') return null
  if (typeof window.localStorage === 'undefined') return null
  return window.localStorage.testSkeleton
}

// To use with `useLazyQuery` whenever the query should only be performed
// when another skeleton data has alreday been loaded
// This is useful for example when we need to load data based on a fund id
// and we need the fund id to be present, and not empty data
export function useLazySkeleton(condition: any, lazyQueryResult: any, skeletonData: any) {
  const [performQuery, result] = lazyQueryResult
  useEffect(() => {
    if (!condition) performQuery()
  }, [condition, performQuery])

  if (!result.data) result.data = skeletonData

  return applySkeleton(result, skeletonData)
}
